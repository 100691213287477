<template>
  <div>
    <div class="grid grid-cols-7 gap-3 p-3 lg:p-6">
      <div class="col-span-7 md:col-span-3 text-sm">
        {{ productDescription.longDescription }}
      </div>
      <div
        class="
            col-span-7 
            lg:col-span-4 
            grid 
            grid-cols-7 
            text-xs 
            font-semibold 
            uppercase 
            leading-6"
      >
        <div class="col-span-2">{{ $t('producer') }}:</div>
        <div class="col-span-5">{{ currentProduct.producer }}</div>

        <div v-if="currentProduct.type === 'movie'" class="col-span-2">{{ $t('length') }}:</div>
        <div v-if="currentProduct.type === 'movie'" class="col-span-5">
          {{ currentProduct.length }}
        </div>

        <div v-if="currentProduct.type === 'fotoset'" class="col-span-2">
          {{ $t('imageCount') }}:
        </div>
        <div v-if="currentProduct.type === 'fotoset'" class="col-span-5">
          {{ currentProduct.imageCount }}
        </div>

        <div class="col-span-2">{{ $t('resolution') }}:</div>
        <div class="col-span-5">{{ currentProduct.resolution }}</div>

        <div class="col-span-2">{{ $t('released') }}:</div>
        <div class="col-span-5">
          {{ $moment(currentProduct.createdAt).format('DD.MM.YYYY') }}
        </div>

        <div class="col-span-2 mt-5">{{ $t('models') }}:</div>
        <div class="col-span-5 mt-5">
          <router-link 
            v-for="(model, i) in currentProduct.models"
            :key="`description-model-${model.id}`" 
            :to="`/models/${model.id}`"
            class="hover:underline"
          >
            {{ model.name }}{{ i !== currentProduct.models.length - 1 ? ', ' : '' }}
          </router-link>
        </div>

        <div class="col-span-2 mt-5">{{ $t('keywords') }}:</div>
        <div class="col-span-5 mt-5">
          <router-link 
            v-for="(keyword, i) in keywords"
            :key="`keyword-${i}`" 
            :to="`/products/search/${keyword}?filter=Tags`"
            class="hover:underline"
          >
            {{ keyword }}{{ i !== keywords.length - 1 ? ', ' : '' }}
          </router-link>
        </div>
           
        <div 
          v-if="currentProduct.accessStatus < 2" 
          class="col-span-2 mt-5"
        >
          {{ $t('availableAs') }}:
        </div>
        <div 
          v-else
          class="col-span-2 mt-5"
        >
          {{ $t('download') }}:
        </div>


        <div
          v-if="currentProduct.type === 'movie'"
          class="col-span-5 mt-5"
        >
          <div v-if="downloadableVideoFiles.length">
            <a
              v-for="videoFile in downloadableVideoFiles"
              :key="videoFile.id"
              :class="buttonStyles"
              :href="videoFile.url"
              @click="trackDownload"
            >
              {{ videoFile.mimeType | formatMimeType }}
            </a>
          </div>
          <div v-else>
            <span 
              v-for="(fileType, i) in videoFileTypes" 
              :key="fileType"
            >
              {{ fileType }}{{ i !== videoFileTypes.length - 1 ? ', ' : '' }}
            </span>
          </div>
        </div>
        <div 
          v-else 
          class="col-span-5 mt-5"
        >
          <a
            v-if="downloadableZipFile && !zipDownloadLoading" 
            :class="buttonStyles"
            @click="downloadZipFile"
          >ZIP</a>
          <LoadingCircle v-if="zipDownloadLoading" :custom-text="true">
            {{ $t('zipFileDownloading') }}
          </LoadingCircle>
          <span v-if="!downloadableZipFile">ZIP</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  filters: {
    formatMimeType(val) {
      switch (val) {
        case 'video/x-ms-wmv':
          return 'WMV';
        case 'video/mp4':
          return 'MP4';
        case 'video/quicktime':
          return 'MOV';
        default:
          return '';
      }
    },
  },

  props: {
    isMobile: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      videoFileTypes: ['WMV', 'MP4', 'MOV'],
      buttonStyles: `
        uppercase tracking-wide text-xs bg-akzent text-background 
        inline-block px-1 mr-1 mb-1 rounded cursor-pointer
      `,
      zipDownloadLoading: false,
    };
  },

  computed: {
    ...mapGetters(['currentProduct', 'currentUser']),
    productDescription() {
      return this.currentProduct.translations.find(
        (translation) => translation.language === this.currentUser.language,
      );
    },
    downloadableVideoFiles() {
      return this.currentProduct.files.filter(
        (file) => file.types.some((type) => type.name === 'movie') && file.url,
      );
    },
    downloadableZipFile() {
      return this.currentProduct.files.find(
        (file) => file.types.some((type) => type.name === 'zip') && file.url,
      );
    },
    keywords() {
      return this.currentProduct.keywords
        ? this.currentProduct.keywords.split(',').map((keyword) => keyword.trim())
        : [];
    },
  },

  methods: {
    trackDownload() {
      // eslint-disable-next-line no-undef
      plausible('Products Downloads', {
        props: {
          ID: this.currentProduct.id,
          Title: this.currentProduct.title,
        },
      });
    },
    downloadZipFile() {
      if (this.downloadableZipFile && !this.zipDownloadLoading) {
        this.zipDownloadLoading = true;
        window.URL = window.URL || window.webkitURL;

        const xhr = new XMLHttpRequest();
        const a = document.createElement('a');
        let file;

        xhr.open('GET', this.downloadableZipFile.url, true);
        xhr.responseType = 'blob';
        xhr.onload = () => {
          file = new Blob([xhr.response], { type: 'application/octet-stream' });
          a.href = window.URL.createObjectURL(file);
          a.download = `${this.currentProduct.title}.zip`;
          a.click();
          setTimeout(() => {
            this.zipDownloadLoading = false;
          }, 500);

          this.trackDownload();
        };
        xhr.send();
      }
    },
  },
};
</script>
