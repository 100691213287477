<template>
  <div class="col-span-12 md:col-span-10 bg-interface cms-content">
    <!-- eslint-disable vue/no-v-html -->
    <div v-html="translation" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      data: null,
      loaded: false,
    };
  },

  computed: {
    ...mapGetters(['currentUser']),
    translation() {
      if (this.data) {
        let translation = this.data.translations.find((translation) =>
          translation.languages_code.startsWith(this.currentUser.language),
        ).content;
        if (!translation) {
          translation = this.data.translations[0].content;
        }
        return translation;
      }

      return this.data;
    },
  },

  async created() {
    const resp = await this.$axios(
      `${process.env.VUE_APP_DIRECTUS_URL}/items/gold_cards?fields=*,translations.*`,
    );
    this.data = resp.data.data;
  },
};
</script>
