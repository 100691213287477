/* eslint-disable @typescript-eslint/no-empty-function */
import type { Module } from 'vuex';
import type { TRootStore } from '@/store';

function getKeyAsUrl(key: string) {
  return new URL(key.split('?')[0]).toString();
}

export interface ICacheStoreState {
  ready: boolean;
  cache: Cache | undefined;
  cacheState: Record<string, number>;
}

type TCacheStore = Module<ICacheStoreState, TRootStore>;

const cacheStore: TCacheStore = {
  state: () => ({
    ready: false,
    cache: undefined,
    cacheState: {},
  }),

  getters: {},

  mutations: {
    setCacheState(state, { key, expiration }: { key: string; expiration: number }) {
      const url = getKeyAsUrl(key);
      const now = new Date().getTime();
      const exp = now + expiration * 60 * 1000;
      state.cacheState[url] = exp;
    },
  },

  actions: {
    async revalidatePersistent({ state }) {
      const storedStrinified = localStorage.getItem('pontus-image-cache');
      if (storedStrinified) {
        const stored = JSON.parse(storedStrinified) as Record<string, number> | undefined;
        const cached = (await state.cache?.keys()) || [];
        const now = new Date().getTime();
        const deleteKeys = cached
          .filter((req) => {
            const expires = stored?.[req.url] || 0;
            return expires < now;
          })
          .map((req) => req.url);
        await Promise.all(
          deleteKeys.map(async (k) => {
            await state.cache?.delete(k);
            delete stored?.[k];
            return k;
          }),
        );
        if (stored) {
          state.cacheState = stored;
        }
      }
    },
    async persist({ state }) {
      localStorage.setItem('pontus-image-cache', JSON.stringify(state.cacheState));
    },
    async put(
      { commit, state, dispatch },
      params: { key: string; response: Response; expiration: number },
    ) {
      const { key, response, expiration } = params;
      const url = getKeyAsUrl(key);
      if (!state.cache) {
        return undefined;
      }

      commit('setCacheState', { key, expiration });
      dispatch('persist');
      await state.cache.put(url, response.clone());

      return url;
    },
    async match({ state }, params: { key: string }) {
      const url = getKeyAsUrl(params.key);
      if (!state.cache) {
        return undefined;
      }
      const response = await state.cache.match(url);
      if (!response || response.status >= 300) return undefined;
      return response;
    },
    async load({ state, dispatch }, params: { url: string; expiration: number }) {
      const { url } = params;
      let { expiration } = params;

      if (!expiration) expiration = 7200;

      const validateKey = (key: string) => {
        const url = getKeyAsUrl(key);
        const stored = state.cacheState[url];
        if (!stored) return false;
        const now = new Date().getTime();
        const isValid = stored >= now;
        if (isValid) return true;

        state.cache?.delete(url).catch(() => {});
        return false;
      };

      const fromCache = await dispatch('match', { key: url });

      if (fromCache && validateKey(url)) {
        return fromCache;
      }

      const response = await fetch(url, {
        cache: 'no-cache',
        method: 'get',
      });

      await dispatch('put', { key: url, response, expiration });
      return response;
    },
    async initCache({ state, dispatch }) {
      state.ready = false;
      state.cache = await caches.open('pontus-image-cache');
      await dispatch('revalidatePersistent');
      state.ready = true;
    },
  },
};

export default cacheStore;
