import type { Params } from '@feathersjs/feathers';
import type { TFindResponse, TGetResponse } from '@/types/Api';
import type { IModel } from '@/types/Model';
import { client } from '../client';

const service = client.service('models');

const findModels = (params?: Params): TFindResponse<IModel> => {
  return service.find({
    ...(params || {}),
    query: {
      $limit: 30,
      ...(params?.query || {}),
    },
  });
};

const getModel = (id: number, params?: Params): TGetResponse<IModel> => {
  return service.get(id, params);
};

const patchModel = (id: number, data: any, params?: Params): TGetResponse<IModel> => {
  return service.patch(id, data, params);
};

const createModel = (data: IModel, params?: Params): TGetResponse<IModel> => {
  return service.create(data, params);
};

const removeModel = (id: number, params?: Params): TGetResponse<IModel> => {
  return service.remove(id, params);
};

export const modelsApi = {
  findModels,
  getModel,
  patchModel,
  createModel,
  removeModel,
};
