<template>
  <div>
    <input
      ref="imageUploader"
      class="d-none"
      type="file"
      accept="image/jpeg, image/png, image/gif"
      multiple
      @input="setImages"
    >
    <v-toolbar elevation="0">
      <v-toolbar-title>Bilder</v-toolbar-title>
      <v-spacer />
      <v-btn @click="$refs.imageUploader.click()">Bilder hinzufügen</v-btn>
    </v-toolbar>
    <v-container fluid>
      <v-row class="mb-4">
        <v-col
          v-for="(imageFile, i) in imageFiles"
          :key="`imageFile-${i}`"
          cols="12"
          sm="6"
          md="4"
          lg="2"
          xl="2"
        >
          <v-card>
            <div>
              <v-img :src="imageFile.fakeUrl" height="250" contain />
            </div>
            <v-divider />
            <v-container>
              <v-checkbox
                v-if="currentProductCreation.type === 'fotoset'"
                v-model="imageFile.isPreviewImage"
                dense
                label="Vorschaubild"
                readonly
                @click="invertPreviewImageState(imageFile)"
              />
              <v-checkbox
                v-model="imageFile.isThumbnail"
                dense
                label="Thumbnail"
                readonly
                @click="invertThumbnailState(imageFile)"
              />
              <v-btn @click="deleteImage(imageFile.internalId)">
                <v-icon>delete</v-icon>
                Löschen
              </v-btn>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <div v-if="currentProductCreation.type === 'movie'">
      <input
        ref="movieUploader"
        class="d-none"
        type="file"
        accept="video/x-ms-wmv, video/mp4"
        @input="setMovieFile"
      >
      <v-toolbar elevation="0">
        <v-toolbar-title>Filmdatei</v-toolbar-title>
        <v-spacer />
        <v-btn v-if="!movieFile" @click="$refs.movieUploader.click()"> Film hinzufügen </v-btn>
      </v-toolbar>
      <v-container v-if="movieFile" fluid>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-card>
              <v-list two-line>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Typ:</v-list-item-title>
                    <v-list-item-subtitle> Film </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Format:</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ movieFile.mimeType }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Größe:</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ movieFile.fileSize | prettyBytes }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-card-text>
                  <v-text-field
                    v-model.number="movieBitrate"
                    label="Bitrate (Bit/s)"
                    number
                    type="number"
                    :rules="rules.bitrateRules"
                    required
                  />
                  <v-text-field
                    v-model="movieTrailerTimecodeStart"
                    label="Trailer Start-Timecode (HH:MM:SS:FF)"
                    :rules="rules.timecodeRules"
                    required
                  />
                  <v-text-field
                    v-model="movieTrailerTimecodeEnd"
                    label="Trailer End-Timecode (HH:MM:SS:FF)"
                    :rules="rules.timecodeRules"
                    required
                  />
                </v-card-text>
              </v-list>

              <v-card-actions>
                <v-btn @click="$refs.movieUploader.click()">
                  <v-icon>edit</v-icon>
                  Ersetzten
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="mt-3">
      <v-btn text @click="$emit('back')"> Zurück </v-btn>
      <v-tooltip right :disabled="!missingThumbnail && !missingMovieFile">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <v-btn
              color="primary"
              :disabled="missingThumbnail || missingMovieFile"
              @click="$emit('submit')"
            >
              Erstellen
            </v-btn>
          </span>
        </template>
        <span v-if="currentProductCreation.type === 'movie'"> 
          Min. ein Thumbnail & eine Filmdatei sind erforderlich.
        </span>
        <span v-else> Min. ein Thumbnail ist erforderlich. </span>
      </v-tooltip>
    </div>

    <v-snackbar v-model="fileError" multi-line>
      {{ fileErrorMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="fileError = false"
        >
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import {
  ADD_PRODUCT_CREATION_FILES,
  REMOVE_PRODUCT_CREATION_FILE,
  ADD_PRODUCT_CREATION_FILE_TYPE,
  REMOVE_PRODUCT_CREATION_FILE_TYPE,
  UPDATE_PRODUCT_CREATION_FILE,
} from '../../../../store/storeTypes/mutationTypes';

export default {
  data() {
    return {
      fileError: false,
      fileErrorMsg: '',
      hasSelectedThumbnail: false,
      idCounter: 0,
      rules: {
        bitrateRules: [
          (v) => v !== '' || 'Bitrate ist erforderlich',
          (v) => v > 0 || 'Bitrate ist erforderlich',
          (v) => v < 9223372036854775807 || 'Bitrate zu hoch',
        ],
        timecodeRules: [
          (v) =>
            /(^(?:(?:[0-1][0-9]|[0-2][0-3]):)(?:[0-5][0-9]:){2}(?:[0-2][0-9])$)/.test(v) ||
            'Geben Sie einen validen Timecode an (HH:MM:SS:FF)',
        ],
      },
    };
  },

  computed: {
    ...mapGetters(['currentProductCreation']),
    imageFiles() {
      const filesFiltered = this.currentProductCreation.files.filter((file) =>
        file.types.some((type) => type.name === 'previewImage' || type.name === 'fotosetImage'),
      );
      filesFiltered.forEach((file) => {
        file.isThumbnail = file.types.some((type) => type.name === 'thumbnail');
        file.isPreviewImage = file.types.some((type) => type.name === 'previewImage');
      });
      return filesFiltered;
    },
    missingThumbnail() {
      const hasThumbnail = this.currentProductCreation.files.some((file) =>
        file.types.some((type) => type.name === 'thumbnail'),
      );
      if (!hasThumbnail) return true;
      return false;
    },
    movieFile() {
      return this.currentProductCreation.files.find((file) =>
        file.types.some((type) => type.name === 'movie'),
      );
    },
    missingMovieFile() {
      return this.currentProductCreation.type === 'movie' && !this.movieFile;
    },
    movieBitrate: {
      get() {
        return this.movieFile.bitrate;
      },
      set(value) {
        this[UPDATE_PRODUCT_CREATION_FILE]({
          internalId: this.movieFile.internalId,
          updatedFile: {
            bitrate: value,
          },
        });
      },
    },
    movieTrailerTimecodeStart: {
      get() {
        return this.movieFile.trailerTimecodeStart;
      },
      set(value) {
        this[UPDATE_PRODUCT_CREATION_FILE]({
          internalId: this.movieFile.internalId,
          updatedFile: {
            trailerTimecodeStart: value,
          },
        });
      },
    },
    movieTrailerTimecodeEnd: {
      get() {
        return this.movieFile.trailerTimecodeEnd;
      },
      set(value) {
        this[UPDATE_PRODUCT_CREATION_FILE]({
          internalId: this.movieFile.internalId,
          updatedFile: {
            trailerTimecodeEnd: value,
          },
        });
      },
    },
  },

  methods: {
    ...mapMutations([
      ADD_PRODUCT_CREATION_FILES,
      REMOVE_PRODUCT_CREATION_FILE,
      ADD_PRODUCT_CREATION_FILE_TYPE,
      REMOVE_PRODUCT_CREATION_FILE_TYPE,
      UPDATE_PRODUCT_CREATION_FILE,
    ]),
    setImages(e) {
      const files = Array.from(e.target.files);

      if (files.length) {
        const invalidFileType = files.some(
          (file) =>
            file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif',
        );

        if (invalidFileType) {
          this.fileError = false;
          this.fileErrorMsg = '';
          this.$nextTick(() => {
            this.fileError = true;
            this.fileErrorMsg =
              'Ungültig Dateityp verwendet! Es sind nur .jpg, .png & .gif Dateien erlaubt.';
          });

          return;
        }

        const prodcutFiles = files.map((file) => {
          const productFile = {
            types: [
              {
                name:
                  this.currentProductCreation.type === 'movie' ? 'previewImage' : 'fotosetImage',
              },
            ],
            mimeType: file.type,
            fileSize: file.size,
            name: file.name,
            realFile: file,
            fakeUrl: URL.createObjectURL(file),
            internalId: this.idCounter,
          };
          this.idCounter += 1;
          return productFile;
        });

        this[ADD_PRODUCT_CREATION_FILES](prodcutFiles);
      }

      this.$refs.imageUploader.value = '';
    },
    deleteImage(internalId) {
      this[REMOVE_PRODUCT_CREATION_FILE](internalId);
    },
    invertThumbnailState(file) {
      if (file.isThumbnail) {
        this[REMOVE_PRODUCT_CREATION_FILE_TYPE]({
          internalFileId: file.internalId,
          typeName: 'thumbnail',
        });
      } else {
        const oldThumbnail = this.imageFiles.find((file) => file.isThumbnail);

        if (oldThumbnail) {
          this[REMOVE_PRODUCT_CREATION_FILE_TYPE]({
            internalFileId: oldThumbnail.internalId,
            typeName: 'thumbnail',
          });
        }

        if (!file.isPreviewImage) {
          this[ADD_PRODUCT_CREATION_FILE_TYPE]({
            internalFileId: file.internalId,
            typeName: 'previewImage',
          });
        }

        this[ADD_PRODUCT_CREATION_FILE_TYPE]({
          internalFileId: file.internalId,
          typeName: 'thumbnail',
        });
      }
    },
    invertPreviewImageState(file) {
      if (file.isPreviewImage) {
        this[REMOVE_PRODUCT_CREATION_FILE_TYPE]({
          internalFileId: file.internalId,
          typeName: 'previewImage',
        });
        if (file.isThumbnail) {
          this[REMOVE_PRODUCT_CREATION_FILE_TYPE]({
            internalFileId: file.internalId,
            typeName: 'thumbnail',
          });
        }
      } else {
        this[ADD_PRODUCT_CREATION_FILE_TYPE]({
          internalFileId: file.internalId,
          typeName: 'previewImage',
        });
      }
    },
    setMovieFile(e) {
      const file = e.target.files[0];

      if (file) {
        this.fileError = false;
        this.fileErrorMsg = '';

        if (file.type !== 'video/x-ms-wmv' && file.type !== 'video/mp4') {
          this.$nextTick(() => {
            this.fileError = true;
            this.fileErrorMsg =
              'Ungültig Dateityp verwendet! Es ist sind nur .wmv & .mp4 Dateien erlaubt.';
          });
          return;
        }
        if (file.size > 268435456000) {
          this.$nextTick(() => {
            this.fileError = true;
            this.fileErrorMsg = 'Datei ist zu groß. Max. 250GB.';
          });
          return;
        }

        if (this.movieFile) {
          this[REMOVE_PRODUCT_CREATION_FILE](this.movieFile.internalId);
        }

        this.$nextTick(() => {
          const movieFile = {
            types: [{ name: 'movie' }],
            mimeType: file.type,
            fileSize: file.size,
            trailerTimecodeStart: '00:00:00:00',
            trailerTimecodeEnd: '00:00:30:00',
            bitrate: 10000000,
            name: file.name,
            realFile: file,
            internalId: this.idCounter,
          };
          this.idCounter += 1;

          this[ADD_PRODUCT_CREATION_FILES]([movieFile]);
        });
      }
    },
  },
};
</script>
