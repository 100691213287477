<template>
  <div class="flex flex-wrap overflow-hidden">
    <div class="w-1/2 overflow-hidden">
      <CustomImage :url="model.picture" aspect-ratio="3/4" />
    </div>

    <div class="w-1/2 overflow-hidden p-3">
      <div class="pb-3">
        <div class="text-xs font-bold">{{ $t('modelDetail.preferredWeapon') }}:</div>
        <div class="text-xs font-normal">{{ model.preferredWeapon }}</div>
      </div>
      

      <div class="pb-3">
        <div class="text-xs font-bold">{{ $t('modelDetail.activity') }}:</div>
        <div class="text-xs font-normal">{{ model.activityStatus }}</div>
      </div>

      <div class="pb-3">
        <div class="text-xs font-bold">{{ $t('modelDetail.toplessActing') }}:</div>
        <div class="text-xs font-normal">
          {{ $t('modelDetail.status')[model.toplessActing] }}
        </div>
      </div>

      <div class="pb-3">
        <div class="text-xs font-bold">{{ $t('modelDetail.availableForCustoms') }}:</div>
        <div class="text-xs font-normal">
          {{ $t('modelDetail.status')[model.availableForCustoms] }}
        </div>
      </div>

      <div class="pb-3">
        <div class="text-xs font-bold">{{ $t('modelDetail.active') }}:</div>
        <div class="text-xs font-normal">
          {{ model.active ? $t('yes') : $t('no') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['currentModel']),
    model() {
      return this.currentModel || {};
    },
  },
};
</script>
