<template>
  <div class="col-span-12 md:col-span-4 bg-interface p-6">
    <div class="flex justify-center items-center h-full">
      <div v-if="!uploading">
        <input
          ref="imageUploader"
          class="hidden"
          type="file"
          accept="image/jpeg, image/png, image/gif"
          @change="onFileChanged"
        >
        <div class="text-center">
          <div
            class="mt-3 uppercase tracking-wide  bg-akzent 
            text-background inline-block px-2 py-1 rounded
            cursor-pointer hover:opacity-75"
            :class="{'text-xxs': selectedFile, 'text-sm': !selectedFile }"
            @click="$refs.imageUploader.click()"
          >
            <span v-if="!selectedFile" class="flex items-center">
              {{ $t('uploadGoldCard') }}
              <Icon
                name="upload"
                :size="20"
                :stroke-width="'2'"
                class="ml-2 inline-block text-interface"
              />
            </span>
            <span v-else class="flex items-center">
              {{ $t('changeGoldCard') }}
              <Icon
                name="edit"
                :size="16"
                :stroke-width="'2'"
                class="ml-2 inline-block text-interface"
              />
            </span>
          </div>
          <transition-group name="fade">
            <div v-if="invalidFileType" key="invalid-file-type-error" class="text-red-600 mt-3">
              {{ $t('invalidFileType') }}
            </div>
            <div v-if="fileToBig" key="file-to-big-error" class="text-red-600 mt-3">
              {{ $t('fileToBig') }}
            </div>
            <div v-if="selectedFile" key="selected-file" class="mt-3">
              {{ $t('choosenFile') }}: {{ selectedFile.name }}
            </div>
          </transition-group>
          <div
            v-if="selectedFile && !invalidFileType"
            class="mt-3 uppercase tracking-wide text-sm bg-akzent 
            text-background inline-block px-2 py-1 rounded
            cursor-pointer hover:opacity-75"
            @click="redeemGoldCard"
          >
            {{ $t('redeemGoldCard') }}
          </div>
        </div>
      </div>
      <div v-if="uploading" class="mt-6">
        <LoadingCircle :custom-text="true">
          {{ $t('fileUploading') }}
        </LoadingCircle>
      </div>
    </div>
    
    <Overlay v-model="successOverlay">
      <div class="text-center max-w-xs">
        <div>{{ $t('redeemedGoldCard', { amount: redeemAmount }) }}</div>
        <Button class="mt-2" @click="successOverlay = false">
          {{ $t('ok') }}
        </Button>
      </div>
    </Overlay>
    <Overlay v-model="goldenCardAlreadyUsedOverlay">
      <div class="text-center max-w-xs">
        <div>
          {{ $t('goldCardAlreadyUsed') }}
        </div>
        <Button class="mt-2" @click="goldenCardAlreadyUsedOverlay = false">
          {{ $t('ok') }}
        </Button>
      </div>
    </Overlay>
    <Overlay v-model="invalidGoldCardOverlay">
      <div class="text-center max-w-xs">
        <div>
          {{ $t('invalidGoldCard') }}
        </div>
        <Button class="mt-2" @click="invalidGoldCardOverlay = false">
          {{ $t('ok') }}
        </Button>
      </div>
    </Overlay>
    <Overlay v-model="unkownError">
      <div class="text-center max-w-xs">
        <div>
          {{ $t('goldCardUnkownError') }}
        </div>
        <Button class="mt-2" @click="unkownError = false">
          {{ $t('ok') }}
        </Button>
      </div>
    </Overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      invalidFileType: false,
      fileToBig: false,
      selectedFile: null,
      uploading: false,
      successOverlay: false,
      redeemAmount: null,
      goldenCardAlreadyUsedOverlay: false,
      invalidGoldCardOverlay: false,
      unkownError: false,
    };
  },
  computed: {
    ...mapGetters(['accessToken']),
  },
  methods: {
    ...mapActions(['getTotalAndSpentCredits']),
    onFileChanged(e) {
      const file = e.target.files[0];

      if (file) {
        this.selectedFile = null;
        this.invalidFileType = false;
        this.fileToBig = false;
        if (file.type !== 'image/jpeg' && file.type !== 'image/jpg') {
          this.$nextTick(() => {
            this.invalidFileType = true;
          });
          return;
        }

        if (file.size > 2 * 1024 * 1024) {
          this.$nextTick(() => {
            this.fileToBig = true;
          });
          return;
        }
        this.selectedFile = file;
      }
    },
    async redeemGoldCard() {
      if (this.selectedFile && !this.invalidFileType && !this.uploading) {
        this.uploading = true;

        const formData = new FormData();
        formData.append('file', this.selectedFile);

        try {
          const resp = await this.$axios.post(
            `${process.env.VUE_APP_API_URL}/gold-cards`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${this.accessToken}`,
              },
            },
          );

          await this.getTotalAndSpentCredits();
          this.redeemAmount = resp.data.credits;
          this.successOverlay = true;
          this.uploading = false;
        } catch (err) {
          this.uploading = false;
          if (err?.response?.data?.name === 'GoldCardAlreadyUsed') {
            this.goldenCardAlreadyUsedOverlay = true;
          } else if (err?.response?.data?.name === 'GoldCardInvalid') {
            this.invalidGoldCardOverlay = true;
          } else {
            this.unkownError = true;
          }
        }
      }
    },
  },
};
</script>
