<template>
  <div class="relative w-full h-full" :style="`padding-bottom: ${paddingBottom}`">
    <div 
      v-for="(image, i) in images" 
      :key="`background-images-${i}`" 
      class="absolute w-full h-full bg-cover bg-center transition-opacity duration-1000" 
      :class="{ 
        'transition-opacity duration-1000': activeImage === i,
        'opacity-0': activeImage + 1 === images.length ? i === 0 : activeImage + 1 === i,
        'z-20': activeImage + 1 === images.length ? i === 0 : activeImage + 1 === i,
        'opacity-0': images.length > 1 && activeImage + 1 === images.length ?
          i === 0 : activeImage + 1 === i,
        'z-20': images.length > 1 && activeImage + 1 === images.length ?
          i === 0 : activeImage + 1 === i,
        'z-10': activeImage === i
      }"
      :style="`background-image: url(${image})`"
    />
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
    paddingBottom: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      activeImage: 0,
    };
  },

  created() {
    setInterval(() => {
      this.activeImage + 1 < this.images.length ? (this.activeImage += 1) : (this.activeImage = 0);
    }, 6500);
  },
};
</script>
