<template>
  <div>
    <form @submit.prevent="onLogin">
      <div :class="fieldStyles">
        <label for="email-input" :class="labelStyles">{{ $t('email') }}</label>
        <input
          id="email-input"
          v-model="loginForm.email"
          type="email"
          :class="inputStyles"
          :placeholder="$t('email')"
          required
        >
      </div>
      <div :class="fieldStyles">
        <label for="password-input" :class="labelStyles">{{ $t('password') }}</label>
        <input
          id="password-input"
          v-model="loginForm.password"
          type="password"
          :class="inputStyles"
          :placeholder="$t('password')"
          required
        >
      </div>
      <transition name="fade">
        <div v-if="invalidLogin" class="text-red-600 text-xs">{{ $t('invalidLogin') }}</div>
      </transition>
          
      <div class="flex items-center justify-between mt-2">
        <div
          class="text-xs inline-block cursor-pointer hover:opacity-75"
          @click="$emit('changeMode', 'resetPassword')"
        >
          {{ $t('resetPassword') }}
        </div>
        <Button type="submit">
          Login
        </Button>
      </div>
    </form>
    <Button 
      class="mt-4 py-3 font-bold w-full text-center" 
      theme="alert"
      @click="$emit('changeMode', 'register')"
    >
      {{ $t('createAccount') }}
    </Button>
    <Button 
      class="mt-4 py-3 font-bold w-full text-center" 
      theme="orange" 
      @click="$emit('changeMode', 'sitePreview')"
    >
      {{ $t('contentPreview') }}
    </Button>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { SET_SHOW_LOGIN_MESSAGE } from '@/store/storeTypes/mutationTypes';

export default {
  data: () => ({
    loginForm: {
      email: '',
      password: '',
    },
    invalidLogin: false,
    fieldStyles: 'pb-3',
    labelStyles: 'pl-2 block uppercase text-xxs tracking-wide',
    inputStyles: 'w-full bg-text py-1 pl-2 text-black text-base lg:text-xs',
  }),

  computed: {
    ...mapGetters(['routeAfterLogin', 'hasLoginError', 'currentUser']),
  },

  watch: {
    loginForm: {
      deep: true,
      handler() {
        this.invalidLogin = false;
      },
    },
  },

  methods: {
    ...mapActions(['login']),
    ...mapMutations([SET_SHOW_LOGIN_MESSAGE]),
    async onLogin() {
      if (this.loginForm.email && this.loginForm.password) {
        await this.login({
          email: this.loginForm.email,
          password: this.loginForm.password,
        });
        if (!this.hasLoginError) {
          if (this.currentUser && this.currentUser.role !== 'admin') {
            // eslint-disable-next-line no-undef
            plausible('Login', {
              props: {
                ID: this.currentUser.id,
                Title: this.currentUser.email,
              },
            });
          }
          this[SET_SHOW_LOGIN_MESSAGE](true);
          this.$router.push(this.routeAfterLogin);
        } else {
          this.invalidLogin = true;
        }
      }
    },
  },
};
</script>
