<template>
  <div class="overflow-hidden">
    <div
      class="w-full h-full bg-interface lazy"
      :class="{}"
      :style="{
        backgroundImage: loaded ? `url(${imgLoaded})` : '',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        'padding-bottom': paddingButtom,
      }"
    />
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
    },
    aspectRatio: {
      type: String,
      default: '16/9',
    },
  },

  data() {
    return {
      loaded: false,
      imgLoaded: null,
    };
  },

  computed: {
    paddingButtom() {
      if (this.aspectRatio === '16/9') return '56.25%';
      if (this.aspectRatio === '3/4') return '150%';

      return '';
    },
  },

  async mounted() {
    const imageObserver = new IntersectionObserver((entries) => {
      entries.forEach(async (entry) => {
        if (entry.isIntersecting) {
          const image = entry.target.firstChild;
          image.classList.remove('lazy');
          imageObserver.unobserve(image);
          const response = await this.$store.dispatch('load', { url: this.url });
          const blob = await response.blob();
          this.imgLoaded = URL.createObjectURL(blob);
          this.loaded = true;
        }
      });
    });

    imageObserver.observe(this.$el);
  },
};
</script>
