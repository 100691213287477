<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          Bezahlungen-Übersicht
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="allPayments"
          :loading="paymentsLoading"
          :options.sync="options"
          :server-items-length="paymentsTotal"
          :footer-props="{
            'items-per-page-options': [5, 10, 15, 30, 50],
            'items-per-page-text': 'Zeilen pro Seite',
          }"
          :multi-sort="true"
        > 
          <template v-slot:[`item.credits`]="{ item }">
            <span>{{ item.credits }}</span>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <span>{{ item.amount | formatMoney }}</span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <span>{{ item.createdAt | formatDate }}</span>
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            <span>{{ item.updatedAt | formatDate }}</span>
          </template>
          <template v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }">
            {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
      },
      headers: [
        {
          text: 'Bestell-Nr.',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Credits',
          sortable: true,
          value: 'credits',
        },
        {
          text: 'Bezahlt',
          sortable: true,
          value: 'amount',
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status',
        },
        {
          text: 'Zuletzt geändert am',
          sortable: true,
          value: 'updatedAt',
        },
        {
          text: 'Erstellt am',
          sortable: true,
          value: 'createdAt',
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['selectedUser', 'allPayments', 'paymentsLoading', 'paymentsTotal']),
  },

  watch: {
    options: {
      handler() {
        this.findPaymentsForDataTable();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions(['findPayments']),

    findPaymentsForDataTable() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.options;

      const sortQuery = {};
      if (sortBy.length) {
        sortBy.forEach((key, i) => {
          if (sortDesc[i]) {
            sortQuery[key] = -1;
          } else {
            sortQuery[key] = +1;
          }
        });
      }

      return this.findPayments({
        query: {
          $limit: itemsPerPage,
          $skip: itemsPerPage * (page - 1),
          $sort: sortQuery,
          userId: this.selectedUser.id,
        },
        refresh: true,
      });
    },
  },
};
</script>
