import type { Module } from 'vuex';
import type { TRootStore } from '@/store';
import { ILogin, ISignup } from '@/types/Auth';
import { authenticationApi, accountsApi } from '@/api';
import {
  SET_TOKEN,
  SET_NEXT_ROUTE,
  SET_LOADING,
  SET_LOGIN_LOADING,
  SET_LOGIN_ERROR,
  SET_READY,
  SET_CURRENT_USER,
  SET_REGISTER_ERROR,
  SET_REGISTER_LOADING,
  SET_PASSWORD_RESET_ERROR,
  SET_RESET_PASSWORD_LOADING,
  SET_ACTIVE_SUBSCRIPTION,
  SET_EXPIRED_SUBSCRIPTION,
} from './storeTypes/mutationTypes';

export interface IAuthStoreState {
  ready: boolean;
  loading: boolean;
  loginLoading: boolean;
  loginError: boolean;
  token: string | null;
  nextRoute: string;
  registerError: boolean;
  registerLoading: boolean;
  passwortResetError: boolean;
  passwortResetLoading: boolean;
}

type TAuthStore = Module<IAuthStoreState, TRootStore>;

const tokenKey = 'pm-access-token';

const authStore: TAuthStore = {
  state: () => ({
    ready: false,
    loading: false,
    loginLoading: false,
    loginError: false,
    token: null,
    nextRoute: '/home',
    registerError: false,
    registerLoading: false,
    passwortResetError: false,
    passwortResetLoading: false,
  }),

  getters: {
    isLoading(state) {
      return state.loading;
    },
    isLoginLoading(state) {
      return state.loginLoading;
    },
    hasLoginError(state) {
      return state.loginError;
    },
    authIsReady(state) {
      return state.ready;
    },
    routeAfterLogin(state) {
      return state.nextRoute;
    },
    accessToken(state) {
      return state.token;
    },
    hasRegisterError(state) {
      return state.registerError;
    },
    isRegisterLoading(state) {
      return state.registerLoading;
    },
    hasPasswordResetError(state) {
      return state.passwortResetError;
    },
    isPasswordResetLoading(state) {
      return state.passwortResetLoading;
    },
  },

  mutations: {
    [SET_TOKEN](state, token: string) {
      state.token = token;
    },
    [SET_NEXT_ROUTE](state, route: string) {
      state.nextRoute = route;
    },
    [SET_LOADING](state, loading: boolean) {
      state.loading = loading;
    },
    [SET_LOGIN_LOADING](state, loading: boolean) {
      state.loginLoading = loading;
    },
    [SET_LOGIN_ERROR](state, hasError: boolean) {
      state.loginError = hasError;
    },
    [SET_READY](state, ready: boolean) {
      state.ready = ready;
    },
    [SET_REGISTER_ERROR](state, hasError: boolean) {
      state.registerError = hasError;
    },
    [SET_REGISTER_LOADING](state, loading: boolean) {
      state.registerLoading = loading;
    },
    [SET_PASSWORD_RESET_ERROR](state, hasError: boolean) {
      state.passwortResetError = hasError;
    },
    [SET_RESET_PASSWORD_LOADING](state, loading: boolean) {
      state.passwortResetLoading = loading;
    },
  },

  actions: {
    async login({ commit, dispatch }, { email, password }: ILogin) {
      commit(SET_LOGIN_ERROR, false);
      commit(SET_LOGIN_LOADING, true);

      try {
        const { accessToken, user } = await authenticationApi.login({ email, password });
        if (
          (process.env.VUE_APP_MODE === 'admin' && user.role !== 'admin') ||
          (process.env.VUE_APP_MODE !== 'admin' && user.role === 'admin')
        ) {
          commit(SET_LOGIN_ERROR, true);
          return;
        }
        commit(SET_LOADING, true);
        commit(SET_READY, false);

        commit(SET_TOKEN, accessToken);
        commit(SET_CURRENT_USER, user);

        if (process.env.VUE_APP_MODE !== 'admin') {
          await dispatch('initUser');
          await dispatch('initUserInterface');
        } else {
          await dispatch('initAdminUserInterface');
        }

        commit(SET_LOADING, false);
        commit(SET_READY, true);
      } catch (e) {
        commit(SET_LOGIN_ERROR, true);
      }
      commit(SET_LOGIN_LOADING, false);
    },
    async relogin({ commit, dispatch }) {
      commit(SET_LOGIN_ERROR, false);
      commit(SET_LOGIN_LOADING, true);
      try {
        const { accessToken, user } = await authenticationApi.relogin();

        if (
          (process.env.VUE_APP_MODE === 'admin' && user.role !== 'admin') ||
          (process.env.VUE_APP_MODE !== 'admin' && user.role === 'admin')
        ) {
          await dispatch('logout');
          throw new Error('Invalid user type');
        }

        commit(SET_TOKEN, accessToken);
        commit(SET_CURRENT_USER, user);

        if (process.env.VUE_APP_MODE !== 'admin') {
          await dispatch('initUserInterface');
          await dispatch('initUser');
        } else {
          await dispatch('initAdminUserInterface');
        }
      } catch (e) {
        commit(SET_CURRENT_USER, null);
        commit(SET_LOGIN_ERROR, true);
        commit(SET_TOKEN, null);
      }
      commit(SET_LOGIN_LOADING, false);
    },
    async signup({ commit }, signupData: ISignup) {
      commit(SET_REGISTER_ERROR, false);
      commit(SET_REGISTER_LOADING, true);

      try {
        await accountsApi.createAccount(signupData);
      } catch (err) {
        commit(SET_REGISTER_ERROR, true);
      }

      commit(SET_REGISTER_LOADING, false);
    },
    async logout({ commit }) {
      commit(SET_LOADING, true);
      try {
        await authenticationApi.logout();

        commit(SET_CURRENT_USER, null);
        commit(SET_TOKEN, null);
        commit(SET_ACTIVE_SUBSCRIPTION, null);
        commit(SET_EXPIRED_SUBSCRIPTION, null);
      } catch (e) {
        commit(SET_CURRENT_USER, null);
        commit(SET_TOKEN, null);
      }
      commit(SET_LOADING, false);
    },
    async initAuth({ commit, getters, dispatch }) {
      commit(SET_LOADING, true);
      commit(SET_READY, false);
      commit(SET_TOKEN, localStorage.getItem(tokenKey));
      if (getters.accessToken) {
        await dispatch('relogin');
      }
      commit(SET_LOADING, false);
      commit(SET_READY, true);
    },
    async requestPasswordReset({ commit }, email: string) {
      commit(SET_PASSWORD_RESET_ERROR, false);
      commit(SET_RESET_PASSWORD_LOADING, true);

      try {
        accountsApi.requestPasswordReset(email);
      } catch (err) {
        commit(SET_PASSWORD_RESET_ERROR, true);
      }

      commit(SET_RESET_PASSWORD_LOADING, false);
    },
  },
};

export default authStore;
