<template>
  <div v-if="specialOffer">
    <NavigationSidebarItem akzent remove-border>
      {{ $t('specialOffer') }}
    </NavigationSidebarItem>
    <ProductListItem 
      :to="`/product/${specialOffer.id}`"
      :title="specialOffer.title"
      :type="specialOffer.type"
      :price="specialOffer.credits"
      :thumbnail="getThumbnail(specialOffer)"
      :availability="specialOffer.accessStatus"
      :pinned="specialOffer.pinned"
      hide-indicators
      :active-subscription="activeSubscription"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProductListItem from '../ProductListItem/index.vue';
import NavigationSidebarItem from '../shared/Navigation/SidebarItem/index.vue';

export default {
  components: {
    NavigationSidebarItem,
    ProductListItem,
  },

  computed: {
    ...mapGetters(['specialOffer', 'activeSubscription']),
  },

  watch: {
    $route: {
      handler() {
        this.loadSpecialOffer();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions(['loadSpecialOffer']),
    getThumbnail(product) {
      let { files } = product;
      if (product.type === 'bundle') {
        files = product.bundleProducts[0].files;
      }

      const thumbnailFile = files.find(
        (file) => file.types && file.types.some((type) => type.name === 'thumbnail'),
      );
      return thumbnailFile ? thumbnailFile.url : '';
    },
  },
};
</script>
