import Container from './Container/index.vue';
import Icon from './Icon/index.vue';
import IconButton from './IconButton/index.vue';
import NavigationHeader from './Navigation/Header/index.vue';
import NavigationBarItem from './Navigation/BarItem/index.vue';
import NavigationSidebar from './Navigation/Sidebar/index.vue';
import NavigationSidebarItem from './Navigation/SidebarItem/index.vue';
import NavigationFooter from './Navigation/Footer/index.vue';
import NavigationDrawer from './Navigation/Drawer/index.vue';
import SearchField from './SearchField/index.vue';
import LoadingCircle from './LoadingCircle/index.vue';
import VideoPlayer from './VideoPlayer/index.vue';
import Overlay from './Overlay/index.vue';
import FullScreenImageSlider from './FullScreenImageSilder/index.vue';
import CustomImage from './CustomImage/index.vue';
import CreditsOutput from './CreditsOuput/index.vue';
import ImageSlider from './ImageSlider/index.vue';
import Button from './Button/index.vue';
import Stepper from './Stepper/index.vue';

export default {
  install: (app: any) => {
    app.component('Container', Container);
    app.component('NavigationHeader', NavigationHeader);
    app.component('NavigationBarItem', NavigationBarItem);
    app.component('NavigationSidebar', NavigationSidebar);
    app.component('NavigationSidebarItem', NavigationSidebarItem);
    app.component('NavigationFooter', NavigationFooter);
    app.component('NavigationDrawer', NavigationDrawer);
    app.component('SearchField', SearchField);
    app.component('Icon', Icon);
    app.component('IconButton', IconButton);
    app.component('LoadingCircle', LoadingCircle);
    app.component('VideoPlayer', VideoPlayer);
    app.component('Overlay', Overlay);
    app.component('FullScreenImageSlider', FullScreenImageSlider);
    app.component('CustomImage', CustomImage);
    app.component('CreditsOutput', CreditsOutput);
    app.component('ImageSlider', ImageSlider);
    app.component('Button', Button);
    app.component('Stepper', Stepper);
  },
};
