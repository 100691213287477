<template>
  <v-dialog
    v-model="show"
    max-width="350"
  >
    <v-card :loading="loading">
      <v-card-title>
        <span class="headline">
          {{ action === 'create' ? 'Kategorie erstellen' : 'Kategorie bearbeiten' }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="fromIsValid" lazy-validation>
          <v-text-field
            v-model="categorie.name"
            :counter="15"
            :rules="[v => !!v || 'Name ist erforderlich']"
            label="Name"
            required
          />

                  
          <v-slider
            v-model="categorie.priority"
            max="100"
            min="0"
            label="Priorität"
            class="pt-6"
          >
            <template v-slot:append>
              <div class="w-8 text-center">{{ categorie.priority }}</div>
            </template>
          </v-slider>
                  
          <v-checkbox
            v-model="categorie.active"
            label="Aktiv"
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          text
          color="primary"
          @click="save"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
    },
    action: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,
      categorie: {},
      fromIsValid: true,
    };
  },

  computed: {
    ...mapGetters(['currentCategory']),

    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value: {
      handler() {
        if (this.value) {
          this.$nextTick(() => {
            this.$refs.form.reset();

            this.$nextTick(() => {
              if (this.action === 'create') {
                this.categorie = {
                  name: '',
                  active: true,
                  priority: 0,
                };
              } else {
                this.categorie = {
                  name: this.currentCategory.name,
                  active: this.currentCategory.active,
                  priority: this.currentCategory.priority,
                };
              }
            });
          });
        }
      },
    },
  },

  methods: {
    ...mapActions(['createCategorie', 'patchCategorie']),
    async save() {
      const valid = this.$refs.form.validate();

      if (valid) {
        this.loading = true;
        if (this.action === 'create') {
          await this.createCategorie({ data: this.categorie });
        } else {
          await this.patchCategorie({
            id: this.currentCategory.id,
            data: this.categorie,
          });
        }

        this.loading = false;
        this.$emit('save');
        this.$emit('input', false);
      }
    },
  },
};
</script>
