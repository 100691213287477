









































































import { defineComponent, PropType } from '@vue/composition-api';
import ProductAvailabilityIndicator from '../ProductAvailabilityIndicator/index.vue';

export default defineComponent({
  components: {
    ProductAvailabilityIndicator,
  },

  props: {
    to: {
      type: String,
      required: true,
    },
    thumbnail: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: false,
    },
    availability: {
      type: Number as PropType<2 | 1 | 0>,
      required: false,
    },
    pinned: {
      type: Boolean,
      required: false,
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideIndicators: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableHoverEffect: {
      type: Boolean,
      required: false,
      default: false,
    },
    activeSubscription: {
      type: Object,
      required: false,
    },
  },
});
