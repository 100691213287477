<template>
  <div class="lg:hidden">
    <div
      class="
        fixed top-0 left-0 h-screen w-full 
        overflow-y-auto max-w-64 z-31 bg-background 
        pt-12 transform transition-all duration-300 ease-in-out
      "
      :class="{
        '-translate-x-full': !show,
      }"
    >
      <PortalTarget name="drawer-content" />
    </div>
    <div
      v-show="show"
      class="bg-background opacity-95 fixed top-0 left-0 w-full h-screen z-30
      transition-all duration-300 ease-in-out"
      @click="$emit('close')"
    />
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
