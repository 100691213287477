<template>
  <div class="col-span-2 lg:col-span-1 bg-interface p-3">
    <div class="pb-3 inline-flex text-xs uppercase font-semibold tracking-widest">
      {{ $t('loginData') }}
    </div>

    <form @submit.prevent="">
      <div class="pb-3">
        <label for="email-input" class="pl-2 block uppercase text-xxs tracking-wide">
          {{ $t('email') }} 
        </label>
        <input
          id="email-input"
          v-model="email"
          type="email"
          class="
                w-full bg-text py-1 pl-2 text-black 
                lg:text-sm placeholder-black placeholder-opacity-80
              "
          :placeholder="$t('yourCurrentEmailAddress')"
          required
        >
      </div>
      <transition name="fade">
        <div v-if="invalidEmail" class="text-red-600 text-xs mb-3">
          {{ $t('invalidEmail') }}
        </div>
      </transition>
      <div class="pb-3">
        <Button type="submit" @click="changeCredentials('password')">
          {{ $t('changePassword') }}
        </Button>
      </div>
      <Button type="submit" @click="changeCredentials('email')">
        {{ $t('changeEmail') }}
      </Button>
    </form>
    <Overlay v-model="showCredentialsOverlay">
      <div class="text-center max-w-xs">
        <div v-if="change === 'password'">
          {{ $t('sentChangeEmailWithLinkToChangePassword') }}
        </div>
        <div v-if="change === 'email'">
          {{ $t('sentChangeEmailWithLinkToChangeEmail') }}
        </div>
        <Button class="mt-2" @click="closeCredentialsOverlay">
          {{ $t('ok') }}
        </Button>
      </div>
    </Overlay>
  </div>
</template>

<script>
import { accountsApi } from '@/api';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      email: '',
      showCredentialsOverlay: false,
      change: '',
      invalidEmail: false,
    };
  },

  computed: {
    ...mapGetters(['currentUser']),
  },

  watch: {
    email() {
      this.invalidEmail = false;
    },
  },

  methods: {
    async changeCredentials(change) {
      if (this.email) {
        if (this.email === this.currentUser.email) {
          this.change = change;

          if (change === 'password') {
            await accountsApi.requestPasswordReset(this.email);
          } else {
            await accountsApi.requestEmailChange();
          }
          this.showCredentialsOverlay = true;
        } else {
          this.invalidEmail = true;
        }
      }
    },

    closeCredentialsOverlay() {
      this.email = '';
      this.change = '';
      this.showCredentialsOverlay = false;
    },
  },
};
</script>
