import type { Params } from '@feathersjs/feathers';
import type { TFindResponse, TGetResponse } from '@/types/Api';
import type {
  IProduct,
  IProductTranslation,
  IProductCategorie,
  IProductModel,
} from '@/types/Products';
import { client, isWsClient } from '../client';

const service = client.service('products');
const EVENTS = ['patched'];

const createProduct = (data: IProduct, params?: Params): TGetResponse<IProduct> => {
  return service.create(data, params || {});
};

const findProducts = (params?: Params): TFindResponse<IProduct> => {
  return service.find(params || {});
};

const getProduct = (id: number, params?: Params): TGetResponse<IProduct> => {
  return service.get(id, params);
};

const patchProduct = (id: number, data?: any, params?: Params): TGetResponse<IProduct> => {
  return service.patch(id, data, params);
};

const patchProductTranslation = (
  id: number,
  productId: number,
  data?: any,
  params?: Params,
): TGetResponse<IProductTranslation> => {
  return client.service(`products/${productId}/translations`).patch(id, data, params);
};

const addCategorieToProduct = (
  categorieId: number,
  productId: number,
  params?: Params,
): TGetResponse<IProductCategorie> => {
  return client.service(`products/${productId}/categories`).create(
    {
      categorieId,
    },
    params,
  );
};

const removeCategorieFromProduct = (
  categorieId: number,
  productId: number,
  params?: Params,
): TGetResponse<IProductCategorie> => {
  return client.service(`products/${productId}/categories`).remove(categorieId, params);
};

const addModelToProduct = (
  modelId: number,
  productId: number,
  params?: Params,
): TGetResponse<IProductModel> => {
  return client.service(`products/${productId}/models`).create(
    {
      modelId,
    },
    params,
  );
};

const removeModelFromProduct = (
  modelId: number,
  productId: number,
  params?: Params,
): TGetResponse<IProductModel> => {
  return client.service(`products/${productId}/models`).remove(modelId, params);
};

const removeEventListener = () => {
  EVENTS.forEach((event) => {
    service.removeAllListeners(event);
  });
};

// eslint-disable-next-line no-unused-vars
const listenToEvents = (emit: (event: string, payload: any) => void) => {
  if (isWsClient) {
    EVENTS.forEach((event) => {
      service.on(event, (payload: any) => emit(event, payload));
    });
  }
};

export const productsApi = {
  createProduct,
  findProducts,
  getProduct,
  patchProduct,
  patchProductTranslation,
  addCategorieToProduct,
  removeCategorieFromProduct,
  addModelToProduct,
  removeModelFromProduct,
  removeEventListener,
  listenToEvents,
};
