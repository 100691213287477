















































import { defineComponent, ref } from '@vue/composition-api';
import { objectid } from '@/utils';

export default defineComponent({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },

  props: {
    id: {
      type: String,
      default: () => objectid('search'),
    },
    modelValue: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'toSearch',
    },
  },

  setup() {
    const inputRef = ref<HTMLInputElement>();

    const focus = () => {
      inputRef.value?.focus();
    };

    const blur = () => {
      inputRef.value?.blur();
    };

    return {
      inputRef,
      focus,
      blur,
    };
  },

  data: () => ({
    value: '',
  }),

  watch: {
    modelValue: {
      immediate: true,
      handler(newVal) {
        this.value = newVal;
      },
    },
  },

  methods: {
    onInput() {
      this.value = this.inputRef?.value || '';
      this.$emit('update:modelValue', this.value);
      this.$emit('input', this.value);
    },
    onFocus(e: FocusEvent) {
      this.$emit('focus', e);
    },
    onBlur(e: FocusEvent) {
      this.$emit('blur', e);
    },
    onSubmit() {
      this.$emit('submit', this.value || '');
    },
  },
});
