<template>
  <Overlay v-model="newAgbReminderOverlay">
    <div class="max-w-xs text-sm text-center">
      <div>
        {{ $t('agbChanged') }}
      </div>
      <div class="my-6 hover:underline cursor-pointer" @click="showAgbOverlay = true">
        {{ $t('showNewAgbs') }}
      </div>
      <Button @click="acceptNewAgb">
        {{ $t('acceptNewAgb') }}
      </Button>
    </div>
    <Overlay v-model="showAgbOverlay" close-icon>
      <div class="max-w-4xl cms-content h-screen overflow-y-auto">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="agbContent" />
      </div>
    </Overlay>
  </Overlay>
</template>

<script>
import { SET_SHOW_LOGIN_MESSAGE } from '@/store/storeTypes/mutationTypes';
import { mapActions, mapMutations } from 'vuex';

export default {
  props: {
    currentAgb: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      newAgbReminderOverlay: true,
      showAgbOverlay: false,
    };
  },

  computed: {
    agbContent() {
      return this.currentAgb.translations.find((translation) =>
        translation.languages_code.startsWith(this.$i18n.locale),
      ).content;
    },
  },

  methods: {
    ...mapMutations([SET_SHOW_LOGIN_MESSAGE]),
    ...mapActions(['acceptNewAgbs']),
    async acceptNewAgb() {
      await this.acceptNewAgbs(this.currentAgb.version);
      this[SET_SHOW_LOGIN_MESSAGE](false);
    },
  },
};
</script>
