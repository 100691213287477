








































import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  props: {
    to: {
      type: String,
      default: undefined,
    },
    icon: {
      type: String,
      required: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number as PropType<12 | 16 | 20 | 24 | 32 | 36 | 40>,
      default: 24,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    customSvg: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],
});
