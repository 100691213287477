<template>
  <v-dialog
    v-model="show"
    max-width="300"
    scrollable
  >
    <v-card>
      <v-card-title class="py-0">
        <v-text-field
          v-model="search"
          label="Suche"
          append-icon="search"
          @input="e => $emit('search', e)"
        />
      </v-card-title>
      <v-divider />
      <v-card-text style="height: 400px; max-height: 400px">
        <v-list v-if="filteredItems.length">
          <v-list-item
            v-for="(item, i) in filteredItems"
            :key="`item-${i}`"
            :value="item"
            @click="selections.push(item)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item[itemText]" />
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon>
                <v-icon>add</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <div v-if="!loading && !allItemsLoaded" ref="loadMoreTarget" />
          <v-progress-circular
            v-show="loading"
            indeterminate
            class="mx-auto"
            color="primary"
          />
        </v-list>
        <div v-if="!loading && !filteredItems.length" class="mt-4">
          <slot name="no-options" />
        </div>
      </v-card-text>
      <v-divider />
      <v-container v-if="selections.length">
        <v-chip-group
          column
        >
          <v-chip
            v-for="(selection, i) in selections"
            :key="`selection-${i}`"
            close
            @click:close="selections.splice(i, 1)"
          > 
            {{ selection[itemText] }} 
          </v-chip>
        </v-chip-group>
      </v-container>
      <v-divider />
      
      
      <v-card-actions>
        <v-spacer />
        <v-btn @click="emitSelectedItems">
          <slot name="submit-button" />
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api';
import { useIntersectionObserver } from '@vueuse/core';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    filterItems: {
      type: Array,
      required: false,
    },
    itemText: {
      type: String,
    },
    itemValue: {
      type: String,
    },
    returnObject: {
      type: Boolean,
      defaul: false,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    allItemsLoaded: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const loadMoreTarget = ref(null);
    const targetIsVisible = ref(false);

    useIntersectionObserver(loadMoreTarget, ([{ isIntersecting }]) => {
      targetIsVisible.value = isIntersecting;
      if (targetIsVisible.value) {
        ctx.emit('load-more');
      }
    });

    return {
      loadMoreTarget,
    };
  },

  data() {
    return {
      selections: [],
      search: '',
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    filteredItems() {
      let { items } = this;

      if (this.filterItems && this.filterItems.length) {
        items = items.filter(
          (item) =>
            !this.filterItems.some(
              (filterItem) => filterItem[this.itemValue] === item[this.itemValue],
            ),
        );
      }

      if (this.selections.length) {
        items = items.filter(
          (item) =>
            !this.selections.some(
              (selectionItem) => selectionItem[this.itemValue] === item[this.itemValue],
            ),
        );
      }

      return items;
    },
  },

  watch: {
    value: {
      handler() {
        if (!this.value) {
          this.selections = [];
          this.search = '';
        }
      },
    },
  },

  methods: {
    emitSelectedItems() {
      let emitData = this.selections;
      if (!this.returnObject) {
        emitData = this.selections.map((selection) => selection[this.itemValue]);
      }

      this.$emit('selectionCompleted', emitData);
      this.$emit('input', false);
      this.selections = [];
    },
  },
};
</script>
