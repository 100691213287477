<template>
  <v-dialog v-model="show" width="350"> 
    <v-card>
      <v-card-title>Wirklich?</v-card-title> 
 
      <v-card-text>
        Soll das Model wirklich gelöschen werden? <br> 
        Alle Produktverknüpftungen dieses Models werden ebenfalls gelöscht.
      </v-card-text>

      <v-card-actions>
        <v-btn
          text
          color="error"
          @click="handleModelDeletion"
        >
          Löschen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
    },
  },

  computed: {
    ...mapGetters(['currentModel']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    ...mapActions(['removeModel']),
    async handleModelDeletion() {
      try {
        await this.removeModel({ id: this.currentModel.id });
        this.$emit('deleted');
        this.$emit('input', false);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
