<template>
  <v-card>
    <v-card-title>Bundles</v-card-title>
    <v-card-text>
      <v-chip-group
        v-if="currentProduct.bundles.length"
        column
      >
        <v-chip
          v-for="bundle in currentProduct.bundles"
          :key="bundle.id"
        > 
          {{ `${bundle.title} (${bundle.discount}%): ` }}
          <span v-for="(bundleProduct, i) in bundle.bundleProducts" :key="bundleProduct.id">
            {{ bundleProduct.title }}{{ i !== bundle.bundleProducts.length - 1 ? ', ' : '' }}  
          </span>
        </v-chip>
      </v-chip-group>
      <div v-else>
        Produkt ist in keinem Bundle enthalten.
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['currentProduct']),
  },
};
</script>
