<template>
  <div class="inline-flex">
    <div
      v-for="availabilityType in availabilityTypes"
      :key="availabilityType.type"
      class="inline-flex items-center ml-3 md:ml-4"
    >
      <ProductAvailabilityIndicator :type="availabilityType.type" />
      <span class="text-xs ml-1 leading-6">= {{ availabilityType.label }}</span>
    </div>
  </div>
</template>

<script>
import ProductAvailabilityIndicator from '../ProductAvailabilityIndicator/index.vue';

export default {
  components: {
    ProductAvailabilityIndicator,
  },

  computed: {
    availabilityTypes() {
      return [
        { type: 2, label: this.$t('productAccessStatus.bought') },
        { type: 1, label: this.$t('productAccessStatus.streamable') },
        { type: 0, label: this.$t('productAccessStatus.preview') },
      ];
    },
  },
};
</script>
