<template>
  <v-dialog
    v-model="show"
    width="500"
    scrollable
  >
    <v-card :loading="loading">
      <v-card-title>Aboplan erstellen</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="subscriptionPlan.name"
                :counter="25"
                :rules="[v => !!v || 'Name ist erforderlich']"
                label="Name*"
                required
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.number="subscriptionPlan.minimumTerm"
                :disabled="subscriptionPlan.oneTime"
                label="Mindestlaufzeit*"
                type="number"
                suffix="Monate (30 Tage)"
                min="1"
                :rules="[
                  v => !!v || 'Mindestlaufzeit ist erforderlich',
                  v => v < 1 ? 'Mindestlaufzeit kann nicht negativ sein' : true
                ]"
                required
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.number="subscriptionPlan.term"
                label="Laufzeit*"
                type="number"
                suffix="Monate (30 Tage)"
                min="1"
                :rules="[
                  v => !!v || 'Laufzeit ist erforderlich',
                  v => v < 1 ? 'Laufzeit kann nicht negativ sein' : true,
                ]"
                required
              />
            </v-col>
            <v-col cols="6">
              <MoneyInput 
                v-model="subscriptionPlan.monthlyPrice"
                :options="{ currency: null, allowNegative: false, valueAsInteger: true }"
                label="Monatlicher Preis*"
                suffix="€"
                :rules="[v => !!v || 'Monatlicher Preis ist erforderlich']"
                required
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.number="subscriptionPlan.productDiscount"
                label="Produktrabatt"
                type="number"
                suffix="%"
                min="1"
                max="100"
                :rules="[
                  v => (v || v === 0) && (v > 100 || v <= 0)
                    ? 'Produktrabatt kann nur 1-100 sein' : true,
                ]"
                required
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.number="subscriptionPlan.bonus"
                label="Abschlussbonus"
                type="number"
                suffix="Credits"
                min="1"
                :rules="[
                  v => (v || v === 0) && v <= 0
                    ? 'Abschlussbonus kann nicht negativ sein' : true
                ]"
                required
              />
            </v-col>
            <v-col cols="6">
              <v-checkbox 
                v-model="subscriptionPlan.recurring"
                :disabled="subscriptionPlan.oneTime"
                label="Wiederkehrend"
              />
            </v-col>
            <v-col cols="6">
              <v-checkbox 
                v-model="subscriptionPlan.oneTime"
                label="Einmalige Bezahlung"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          color="primary"
          :disabled="!valid"
          @click="validate"
        >
          Speichern
        </v-btn>
      </v-card-actions>
      <template slot="progress">
        <v-progress-linear
          absolute
          indeterminate 
          bottom
        />
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import MoneyInput from '../MoneyInput/index.vue';

export default {
  components: {
    MoneyInput,
  },

  props: {
    value: {
      type: Boolean,
    },
  },

  data() {
    return {
      loading: false,
      subscriptionPlan: {
        name: '',
        minimumTerm: 1,
        term: 1,
        monthlyPrice: null,
        productDiscount: null,
        bonus: null,
        recurring: false,
        oneTime: false,
      },
      valid: false,
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value: {
      handler() {
        if (!this.value) {
          this.$refs.form.reset();
          this.$nextTick(() => {
            this.subscriptionPlan = {
              name: '',
              minimumTerm: 1,
              term: 1,
              monthlyPrice: null,
              productDiscount: null,
              bonus: null,
              recurring: false,
              oneTime: false,
            };
          });
        }
      },
    },
    subscriptionPlan: {
      handler() {
        this.$nextTick(() => {
          if (this.subscriptionPlan.productDiscount)
            this.subscriptionPlan.productDiscount = parseInt(
              this.subscriptionPlan.productDiscount,
              10,
            );
          if (this.subscriptionPlan.bonus)
            this.subscriptionPlan.bonus = parseInt(this.subscriptionPlan.bonus, 10);
          if (this.subscriptionPlan.minimumTerm)
            this.subscriptionPlan.minimumTerm = parseInt(this.subscriptionPlan.minimumTerm, 10);
          if (this.subscriptionPlan.term) {
            this.subscriptionPlan.term = parseInt(this.subscriptionPlan.term, 10);
            if (this.subscriptionPlan.minimumTerm > this.subscriptionPlan.term) {
              this.subscriptionPlan.term = this.subscriptionPlan.minimumTerm;
            }
            if (this.subscriptionPlan.oneTime) {
              this.subscriptionPlan.minimumTerm = this.subscriptionPlan.term;
              this.subscriptionPlan.recurring = false;
            }
          }
        });
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions(['createSubscriptionPlan']),
    async validate() {
      try {
        const from = this.$refs.form;
        const valid = from.validate();
        if (valid) {
          this.loading = true;

          await this.createSubscriptionPlan({
            data: this.subscriptionPlan,
          });

          this.loading = false;
          this.$emit('input', false);
          from.reset();
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
