import type { Params } from '@feathersjs/feathers';
import type { TFindResponse, TGetResponse } from '@/types/Api';
import type { ISubscription } from '@/types/Subscription';
import {
  ICreateMaxpaySubscription,
  IMaxpaySubscription,
  IPatchMaxpaySubscription,
} from '@/types/MaxpaySubscription';
import { client } from '../client';

const service = client.service('maxpay-subscriptions');

const findSubscriptions = (params?: Params): TFindResponse<IMaxpaySubscription> => {
  return service.find({
    ...(params || {}),
    query: {
      $limit: 30,
      ...(params?.query || {}),
    },
  });
};

const createSubscription = (
  data: ICreateMaxpaySubscription,
  params?: Params,
): TGetResponse<ISubscription> => {
  return service.create(data, params || {});
};

const patchSubscription = (
  id: number,
  data: IPatchMaxpaySubscription,
  params?: Params,
): TGetResponse<ISubscription> => {
  return service.patch(id, data, params);
};

export const maxpaySubscriptionsApi = {
  findSubscriptions,
  createSubscription,
  patchSubscription,
};
