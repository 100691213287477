<template>
  <section>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
      <ModelListItem 
        v-for="model in models"
        :key="model.id"
        :to="`/models/${model.id}`"
        :title="model.name"
        :thumbnail-src="model.picture"
      />
    </div>
    
    <div v-if="!loading" ref="loadMoreTarget" />
    <div class="flex w-full justify-center py-4">
      <r-loading-circle v-show="loading" class="w-8 h-8" />
    </div>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api';
import { useIntersectionObserver } from '@vueuse/core';
import ModelListItem from '../ModelListItem/index.vue';

export default {
  components: {
    ModelListItem,
  },

  props: {
    models: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const loadMoreTarget = ref(null);
    const targetIsVisible = ref(false);

    useIntersectionObserver(loadMoreTarget, ([{ isIntersecting }]) => {
      targetIsVisible.value = isIntersecting;
      if (targetIsVisible.value) {
        ctx.emit('load-more');
      }
    });

    return {
      loadMoreTarget,
    };
  },
};
</script>
