var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.to ? 'router-link' : 'div',_vm._b({tag:"component",staticClass:"px-3 py-2 flex items-center justify-between\n  transition duration-150 ease-in-out",class:{
    'bg-akzent text-background': _vm.akzent && !_vm.to,
    'bg-interface text-text': !_vm.akzent || _vm.to,
    'leading-5': !_vm.custom,
    'hover:opacity-50 focus:outline-none focus:text-akzent active:text-akzent': _vm.to,
    'border-b border-background': !_vm.removeBorder,
  },on:{"click":_vm.closeDrawer}},'component',_vm.to ? {
    to: _vm.to,
    exactActiveClass: 'text-akzent',
    custom: true,
  } : {},false),[(!_vm.custom)?_c('div',{staticClass:"text-xs uppercase font-bold tracking-wide mr-2 truncate"},[_vm._t("default")],2):_c('div',{staticClass:"flex-grow"},[_vm._t("default")],2),_c('div',{staticClass:"flex flex-shrink-0 items-center"},[_vm._t("right")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }