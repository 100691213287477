































import { TOGGLE_DRAWER } from '@/store/storeTypes/mutationTypes';
import { defineComponent } from '@vue/composition-api';
import { mapGetters, mapMutations } from 'vuex';

export default defineComponent({
  props: {
    to: {
      type: String,
      default: undefined,
    },
    akzent: {
      type: Boolean,
      default: false,
    },
    custom: {
      type: Boolean,
      default: false,
    },
    removePadding: {
      type: Boolean,
      default: false,
    },
    removeBorder: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters(['currentBreakpoint', 'isDrawerOpen']),
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        this.closeDrawer();
      },
    },
  },

  methods: {
    ...mapMutations([TOGGLE_DRAWER]),
    closeDrawer() {
      if (this.to && ['xs', 'sm', 'md'].includes(this.currentBreakpoint) && this.isDrawerOpen) {
        this[TOGGLE_DRAWER]();
      }
    },
  },
});
