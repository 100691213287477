<template>
  <div>
    <VideoPlayer v-if="canViewMovies" :options="videoOptions" />
    <div v-else>
      <div 
        :style="{
          backgroundImage: `url(&quot;${thumbnail}&quot;)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          'padding-bottom': '56.25%',
        }"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VideoPlayer from '../shared/VideoPlayer/index.vue';

export default {
  components: {
    VideoPlayer,
  },

  computed: {
    ...mapGetters(['currentProduct', 'currentUser']),
    canViewMovies() {
      return this.currentUser.role === 'adultConfirmed' || this.currentUser.role === 'subscriber';
    },
    thumbnail() {
      return this.currentProduct.files.find((file) =>
        file.types.some((type) => type.name === 'thumbnail'),
      ).url;
    },
    videoOptions() {
      const options = {
        autoplay: false,
        controls: true,
        disablePictureInPicture: true,
        sources: [],
        controlBar: {
          liveDisplay: true,
          pictureInPictureToggle: false,
          children: [
            'playToggle',
            'progressControl',
            'volumePanel',
            'qualitySelector',
            'fullscreenToggle',
          ],
        },
      };

      if (this.currentProduct) {
        options.poster = this.thumbnail;

        let files;

        if (this.currentProduct.accessStatus === 0) {
          files = this.currentProduct.files.filter(
            (file) =>
              file.mimeType === 'video/mp4' && file.types.some((type) => type.name === 'trailer'),
          );
        } else if (this.currentProduct.accessStatus > 0) {
          files = this.currentProduct.files.filter(
            (file) =>
              file.mimeType === 'video/mp4' && file.types.some((type) => type.name === 'stream'),
          );
        }

        files.forEach((file) => {
          options.sources.push({
            src: file.url,
            type: file.mimeType,
            label: file.quality,
          });
        });
      }

      return options;
    },
  },
};
</script>
