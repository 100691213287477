import type { Params } from '@feathersjs/feathers';
import { IProductCount } from '@/types/ProductCount';
import { client } from '../client';

const service = client.service('product-counts');

const getProductsCount = (type: string, params?: Params): IProductCount => {
  return service.get(type, params || {});
};

export const productCountsApi = {
  getProductsCount,
};
