<template>
  <div>
    <SeperationBox class="mt-3" :mobile-style="2">
      <template v-if="!isMobile">
        {{ currentProduct.title }}
      </template>
      <template v-slot:custom>
        <div class="flex items-center justify-between w-full lg:w-auto"> 
          <div 
            v-if="currentUser.role === 'adultConfirmed' || currentUser.role === 'subscriber'" 
            class="text-xs cursor-pointer hover:opacity-75"
          >
            <div v-if="!currentProduct.favorites.length" @click="favorProduct(currentProduct.id)">
              <span class="hidden md:inline-block">{{ $t('addToFavourites') }}</span>
              <Icon
                name="heart"
                outline
                :size="24"
                class="ml-2 inline-block text-akzent"
              />
            </div>
            <div v-else @click="unfavorProduct(currentProduct.id)">
              <span class="hidden md:inline-block">{{ $t('removeFromFavorites') }}</span>
              <Icon
                name="heart"
                :size="24"
                custom-view-box="0 0 24 24"
                class="ml-2 inline-block text-akzent"
              />
            </div>
          </div>
          <div 
            v-if="currentProduct.accessStatus !== 2"
            class="text-xs lg:ml-8"
          >
            {{ $t('price') }}:
            <Credits-Output 
              :credits="currentProduct.credits" 
              :active-subscription="activeSubscription" 
            />
          </div>
          <Button 
            v-if="currentProduct.accessStatus !== 2 && !currentProduct.exclusiveForSubscribers"
            class="lg:ml-8" 
            @click="$emit('purchaseProdcut')"
          > 
            {{ $t('buyNow') }}
          </Button>
          <div 
            v-if="currentProduct.accessStatus !== 2 && currentProduct.exclusiveForSubscribers" 
            class="text-xs lg:ml-8"
          >
            {{ $t('exclusiveForSubscribers') }}
          </div>
        </div>
      </template>
    </SeperationBox>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SeperationBox from '../SeperationBox/index.vue';

export default {
  components: {
    SeperationBox,
  },

  props: {
    isMobile: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['currentProduct', 'activeSubscription', 'currentUser']),
  },

  methods: {
    ...mapActions(['favorProduct', 'unfavorProduct']),
  },
};
</script>
