<template>
  <div>
    <v-form ref="propertiesForm" v-model="propertiesFormValid">
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="type"
            :items="productTypes"
            item-text="name"
            item-value="value"
            label="Produkttyp*"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="orderId"
            :counter="256"
            label="Bestellnummber"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="title"
            :counter="256"
            :rules="[v => !!v || 'Titel ist erforderlich']"
            label="Titel*"
            required
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="keywords" 
            label="Keywords"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="credits" 
            label="Credits*"
            type="number"
            :rules="[
              v => v !== '' || 'Credits ist erforderlich',
              v => v >= 0 || 'Credits können nicht negativ sein',
            ]"
            min="0"
            required
          />
        </v-col>
        <v-col v-if="type === 'movie'" cols="6">
          <v-text-field
            v-model="length" 
            :counter="256"
            label="Länge"
          />
        </v-col>
        <v-col v-if="type === 'fotoset'" cols="6">
          <v-text-field
            v-model="imageCount" 
            :counter="256"
            label="Anzahl Bilder"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="producer" 
            :counter="256"
            label="Produzent"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="resolution" 
            :counter="256"
            label="Auflösung"
          />
        </v-col>
        <v-col cols="6" md="2">
          <v-checkbox 
            v-model="visable" 
            label="Sichtbar"
          />
        </v-col>
        <v-col cols="6" md="2">
          <v-checkbox 
            v-model="pinned" 
            label="Pinned"
          />
        </v-col>
      </v-row>
      <div>Subscriber-Optionen</div>
      <v-row>
        <v-col cols="6" md="2">
          <v-checkbox
            v-model="directlyStreamableForSubscribers" 
            label="Direkt sichtbar"
            :disabled="exclusiveForSubscribers"
          />
        </v-col>
        <v-col cols="6" md="2">
          <v-checkbox
            v-model="exclusiveForSubscribers" 
            label="Exklusiv"
            @change="forceDirectlyStreamable"
          />
        </v-col>
        <v-col cols="6" md="2">
          <v-checkbox
            v-model="downloadableForSubscibers" 
            label="Herunterladbar"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-btn
      text
      @click="$emit('closeDialog')"
    >
      Abbrechen
    </v-btn>
    <v-btn
      :disabled="!propertiesFormValid"
      color="primary"
      @click="$emit('next')"
    >
      Weiter
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import {
  SET_PRODUCT_CREATION_TYPE,
  SET_PRODUCT_CREATION_ORDER_ID,
  SET_PRODUCT_CREATION_TITLE,
  SET_PRODUCT_CREATION_KEYWORDS,
  SET_PRODUCT_CREATION_CREDITS,
  SET_PRODUCT_CREATION_LENGTH,
  SET_PRODUCT_CREATION_VISABLE,
  SET_PRODUCT_CREATION_PINNED,
  SET_PRODUCT_CREATION_IMAGE_COUNT,
  SET_PRODUCT_CREATION_PRODUCER,
  SET_PRODUCT_CREATION_RESOLUTION,
  SET_PRODUCT_CREATION_DIRECTLY_STREAMABLE_FOR_SUBSCRIBERS,
  SET_PRODUCT_CREATION_EXCLUSIVE_FOR_SUBSCRIBERS,
  SET_PRODUCT_CREATION_DOWNLOADABLE_FOR_SUBSCRIBERS,
} from '../../../../store/storeTypes/mutationTypes';

export default {
  data() {
    return {
      propertiesFormValid: false,
      productTypes: [
        { name: 'Film', value: 'movie' },
        { name: 'Fotoset', value: 'fotoset' },
      ],
    };
  },

  computed: {
    ...mapGetters(['currentProductCreation']),

    type: {
      get() {
        return this.currentProductCreation.type;
      },
      set(value) {
        this[SET_PRODUCT_CREATION_TYPE](value);
      },
    },
    orderId: {
      get() {
        return this.currentProductCreation.orderId;
      },
      set(value) {
        this[SET_PRODUCT_CREATION_ORDER_ID](value);
      },
    },
    title: {
      get() {
        return this.currentProductCreation.title;
      },
      set(value) {
        this[SET_PRODUCT_CREATION_TITLE](value);
      },
    },
    keywords: {
      get() {
        return this.currentProductCreation.keywords;
      },
      set(value) {
        this[SET_PRODUCT_CREATION_KEYWORDS](value);
      },
    },
    credits: {
      get() {
        return this.currentProductCreation.credits;
      },
      set(value) {
        this[SET_PRODUCT_CREATION_CREDITS](value);
      },
    },
    length: {
      get() {
        return this.currentProductCreation.length;
      },
      set(value) {
        this[SET_PRODUCT_CREATION_LENGTH](value);
      },
    },
    imageCount: {
      get() {
        return this.currentProductCreation.imageCount;
      },
      set(value) {
        this[SET_PRODUCT_CREATION_IMAGE_COUNT](value);
      },
    },
    producer: {
      get() {
        return this.currentProductCreation.producer;
      },
      set(value) {
        this[SET_PRODUCT_CREATION_PRODUCER](value);
      },
    },
    resolution: {
      get() {
        return this.currentProductCreation.resolution;
      },
      set(value) {
        this[SET_PRODUCT_CREATION_RESOLUTION](value);
      },
    },
    visable: {
      get() {
        return this.currentProductCreation.visable;
      },
      set(value) {
        this[SET_PRODUCT_CREATION_VISABLE](value);
      },
    },
    pinned: {
      get() {
        return this.currentProductCreation.pinned;
      },
      set(value) {
        this[SET_PRODUCT_CREATION_PINNED](value);
      },
    },
    directlyStreamableForSubscribers: {
      get() {
        return this.currentProductCreation.directlyStreamableForSubscribers;
      },
      set(value) {
        this[SET_PRODUCT_CREATION_DIRECTLY_STREAMABLE_FOR_SUBSCRIBERS](value);
      },
    },
    exclusiveForSubscribers: {
      get() {
        return this.currentProductCreation.exclusiveForSubscribers;
      },
      set(value) {
        this[SET_PRODUCT_CREATION_EXCLUSIVE_FOR_SUBSCRIBERS](value);
      },
    },
    downloadableForSubscibers: {
      get() {
        return this.currentProductCreation.downloadableForSubscibers;
      },
      set(value) {
        this[SET_PRODUCT_CREATION_DOWNLOADABLE_FOR_SUBSCRIBERS](value);
      },
    },
  },

  watch: {
    credits: {
      handler() {
        this.$nextTick(() => {
          if (this.currentProductCreation.credits && this.currentProductCreation.credits) {
            this[SET_PRODUCT_CREATION_CREDITS](parseInt(this.currentProductCreation.credits, 10));
          }
        });
      },
      deep: true,
    },
  },

  methods: {
    ...mapMutations([
      SET_PRODUCT_CREATION_TYPE,
      SET_PRODUCT_CREATION_ORDER_ID,
      SET_PRODUCT_CREATION_TITLE,
      SET_PRODUCT_CREATION_KEYWORDS,
      SET_PRODUCT_CREATION_CREDITS,
      SET_PRODUCT_CREATION_LENGTH,
      SET_PRODUCT_CREATION_VISABLE,
      SET_PRODUCT_CREATION_PINNED,
      SET_PRODUCT_CREATION_IMAGE_COUNT,
      SET_PRODUCT_CREATION_PRODUCER,
      SET_PRODUCT_CREATION_RESOLUTION,
      SET_PRODUCT_CREATION_DIRECTLY_STREAMABLE_FOR_SUBSCRIBERS,
      SET_PRODUCT_CREATION_EXCLUSIVE_FOR_SUBSCRIBERS,
      SET_PRODUCT_CREATION_DOWNLOADABLE_FOR_SUBSCRIBERS,
    ]),
    forceDirectlyStreamable(value) {
      if (value) this[SET_PRODUCT_CREATION_DIRECTLY_STREAMABLE_FOR_SUBSCRIBERS](true);
    },
    resetForm() {
      this.$refs.propertiesForm.reset();
    },
  },
};
</script>
