
















































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  data() {
    return {
      items: [
        { title: 'Users', icon: 'people', to: '/users' },
        {
          title: 'Altersverifikationen',
          icon: 'mdi-card-account-details',
          to: '/age-verifications',
        },
        {
          title: 'Models ',
          icon: 'mdi-human-male',
          to: '/models',
        },
        {
          title: 'Kategorien ',
          icon: 'category',
          to: '/categories',
        },
        {
          title: 'Produkte ',
          icon: 'movie_creation',
          to: '/products',
        },
        {
          title: 'Bundles ',
          icon: 'style',
          to: '/bundles',
        },
        {
          title: 'Abopläne',
          icon: 'card_membership',
          to: '/subscription-plans',
        },
      ],
    };
  },
  methods: {
    onLogout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login');
      });
    },
  },
});
