import type { Params } from '@feathersjs/feathers';
import type { TFindResponse, TGetResponse } from '@/types/Api';
import type { IPayment, ICreatePayment } from '@/types/Payments';
import { client } from '../client';

const service = client.service('payments');

const findPayments = (params?: Params): TFindResponse<IPayment> => {
  return service.find({
    ...(params || {}),
    query: {
      $limit: 30,
      ...(params?.query || {}),
    },
  });
};

const createPayment = (data: ICreatePayment, params?: Params): TGetResponse<IPayment> => {
  return service.create(data, params);
};

export const paymentsApi = {
  findPayments,
  createPayment,
};
