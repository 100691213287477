import type { Params } from '@feathersjs/feathers';
import type { TFindResponse, TGetResponse } from '@/types/Api';
import type { IFavorites, ICreateFavorite } from '@/types/Favorites';
import { client } from '../client';

const service = client.service('favorites');

const findFavorites = (params?: Params): TFindResponse<IFavorites> => {
  return service.find({
    ...(params || {}),
    query: {
      $limit: 30,
      ...(params?.query || {}),
    },
  });
};

const getFavorite = (id: string, params?: Params): TGetResponse<IFavorites> => {
  return service.get(id, params);
};

const patchFavorite = (id: string, data: any, params?: Params): TGetResponse<IFavorites> => {
  return service.patch(id, data, params);
};

const createFavorite = (data: ICreateFavorite, params?: Params): TGetResponse<IFavorites> => {
  return service.create(data, params);
};

const removeFavorite = (id: string, params?: Params): TGetResponse<IFavorites> => {
  return service.remove(id, params);
};

export const favoritesApi = {
  findFavorites,
  getFavorite,
  patchFavorite,
  createFavorite,
  removeFavorite,
};
