import Vue from 'vue';
import Vuex from 'vuex';
import AuthStore from './auth';
import UserStore from './user';
import UiStore from './ui';
import CategoriesStore from './categories';
import ProductsStore from './products';
import AgeVerificationsStore from './age-verifications';
import ModelsStore from './models';
import UsersStore from './users';
import UserCreditsStore from './user-credits';
import PaymentsStore from './payments';
import ProdcutFilesStore from './product-files';
import SubscriptionPlanStore from './subscription-plans';
import CreateProductStore from './create-product';
import ModelProductsStore from './model-products';
import RecentModelsStore from './recent-models';
import SpecialOffer from './special-offer';
import BundleProductsStore from './bundle-products';
import AdminUiStore from './admin-ui.store';
import CacheStore from './cache';

Vue.use(Vuex);

export type TRootStore = Record<string, any>;

export default new Vuex.Store({
  // state: {
  // },
  // mutations: {
  // },
  // actions: {
  // },
  modules: {
    cache: CacheStore,
    ui: UiStore,
    auth: AuthStore,
    user: UserStore,
    categories: CategoriesStore,
    products: ProductsStore,
    ageVerifications: AgeVerificationsStore,
    models: ModelsStore,
    users: UsersStore,
    userCredits: UserCreditsStore,
    payments: PaymentsStore,
    productFiles: ProdcutFilesStore,
    subscriptionPlanStore: SubscriptionPlanStore,
    createProductStore: CreateProductStore,
    modelProducts: ModelProductsStore,
    recentModelsStore: RecentModelsStore,
    specialOffer: SpecialOffer,
    bundlesProductsStore: BundleProductsStore,
    adminUiStore: AdminUiStore,
  },
});
