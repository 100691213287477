<template>
  <div>
    <NavigationSidebarItem akzent remove-border>
      {{ $t('recentModels') }}
    </NavigationSidebarItem>
    <div class="relative">
      <carousel
        ref="recentModelsSlider"
        v-model="recentModelsImageSlider.position"
        :per-page="recentModelsImageSlider.itemsPagePage"
        :pagination-enabled="false"
        :mouse-drag="false"
        :touch-drag="false"
        :autoplay="true"
        :autoplay-timeout="6000"
        :loop="true"
        @page-change="loadMoreRecentModels"
      >
        <slide v-for="recentModel in allRecentModels" :key="recentModel.id">
          <ModelListItem 
            class="w-full"
            :to="`/models/${recentModel.id}`"
            :title="recentModel.name"
            :thumbnail-src="recentModel.picture"
          />
        </slide>
      </carousel>
      <div class="absolute bottom-0 right-0 pb-3 pr-1  flex items-center z-50">
        <div @click="prevSliderImage">
          <Icon
            name="arrowLeft"
            outline
            class="w-3 h-5 mr-3  transition-opacity duration-200"
            :class="{'opacity-0': !hasPrevSlide, 'cursor-pointer hover:opacity-75': hasPrevSlide }"
            :custom-view-box="'0 0 21 35'"
            stroke-width="2"
          />
        </div>
        <div @click="nextSliderImage">
          <Icon
            name="arrowRight"
            outline
            class="w-3 h-5 transition-opacity duration-200"
            :class="{ 'opacity-0': !hasNextSlide, 'cursor-pointer hover:opacity-75': hasNextSlide }"
            :custom-view-box="'0 0 21 35'"
            stroke-width="2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import NavigationSidebarItem from '../shared/Navigation/SidebarItem/index.vue';
import ModelListItem from '../ModelListItem/index.vue';

export default {
  components: { NavigationSidebarItem, ModelListItem },

  data() {
    return {
      recentModelsImageSlider: {
        position: 0,
        itemsPagePage: 1,
      },
    };
  },

  computed: {
    ...mapGetters([
      'allRecentModels',
      'allRecentModelsLoaded',
      'recentModelsTotal',
      'recentModelLimit',
    ]),
    hasPrevSlide() {
      return this.recentModelsImageSlider.position !== 0;
    },
    hasNextSlide() {
      if (
        this.allRecentModels.length >
        (this.recentModelsImageSlider.position + 1) * this.recentModelsImageSlider.itemsPagePage
      ) {
        return true;
      }
      return false;
    },
  },

  methods: {
    ...mapActions(['findRecentModels']),
    loadMoreRecentModels(currentPageNumber) {
      if (currentPageNumber > this.allRecentModels.length - 3 && !this.allRecentModelsLoaded) {
        this.findRecentModels({
          query: {
            active: 1,
          },
          refresh: false,
        });
      }
    },
    nextSliderImage() {
      if (this.hasNextSlide) this.$refs.recentModelsSlider.advancePage('forward');
    },
    prevSliderImage() {
      if (this.hasPrevSlide) this.$refs.recentModelsSlider.advancePage('backward');
    },
  },
};
</script>
