import type { Params } from '@feathersjs/feathers';
import type { TFindResponse, TGetResponse } from '@/types/Api';
import type { IAgeVerification } from '@/types/AgeVerifications';
import { client } from '../client';

const service = client.service('age-verifications');

const findAgeVerifications = (params?: Params): TFindResponse<IAgeVerification> => {
  return service.find({
    ...(params || {}),
    query: {
      $limit: 30,
      ...(params?.query || {}),
    },
  });
};

const getAgeVerification = (id: number, params?: Params): TGetResponse<IAgeVerification> => {
  return service.get(id, params);
};

const patchAgeVerification = (
  id: number,
  data: any,
  params?: Params,
): TGetResponse<IAgeVerification> => {
  return service.patch(id, data, params);
};

const createAgeVerification = (
  imageMimeType: string,
  params?: Params,
): TGetResponse<IAgeVerification> => {
  return service.create(
    {
      imageMimeType,
    },
    params,
  );
};

export const ageVerificationApi = {
  findAgeVerifications,
  getAgeVerification,
  patchAgeVerification,
  createAgeVerification,
};
