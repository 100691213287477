





















import { defineComponent } from '@vue/composition-api';
import { mapActions, mapGetters } from 'vuex';
import german from '../../../assets/german.svg';
import english from '../../../assets/english.svg';

const localStorageKey = 'pm-locale';

export default defineComponent({
  computed: {
    ...mapGetters(['currentUser']),
    locale() {
      return this.$i18n.locale;
    },
    items() {
      return [
        {
          icon: german,
          langString: 'de',
          title: this.$t('german'),
        },
        {
          icon: english,
          langString: 'en',
          title: this.$t('english'),
        },
      ];
    },
  },

  created() {
    const storedLocale = this.currentUser
      ? this.currentUser.language
      : localStorage.getItem(localStorageKey);
    if (storedLocale) this.change(storedLocale);
  },

  methods: {
    ...mapActions(['patchLanguage']),
    change(newLocale: string) {
      this.$i18n.locale = newLocale;
      localStorage.setItem(localStorageKey, newLocale);
      this.patchLanguage(newLocale);
    },
  },
});
