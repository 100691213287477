import type { Params } from '@feathersjs/feathers';
import type { ISignup } from '@/types/Auth';
import { client } from '../client';

const service = client.service('accounts');

const createAccount = (data: ISignup, params?: Params) => {
  return service.create(data, params);
};

const requestPasswordReset = (email: string, params?: Params) => {
  return service.update(
    null,
    {
      action: 'sendPasswordReset',
      email,
    },
    params,
  );
};

const resetPassword = (token: string, newPassword: string, params?: Params) => {
  return service.update(
    null,
    {
      action: 'resetPassword',
      token,
      newPassword,
    },
    params,
  );
};

const verifyEmail = (token: string, params?: Params) => {
  return service.update(
    null,
    {
      action: 'verifyEmail',
      token,
    },
    params,
  );
};

const resendVerificationMail = (email: string, params?: Params) => {
  return service.update(
    null,
    {
      action: 'resendVerificationEmail',
      email,
    },
    params,
  );
};

const requestEmailChange = (email: string, params?: Params) => {
  return service.update(
    null,
    {
      action: 'sendEmailChange',
    },
    params,
  );
};

const changeEmail = (token: string, newEmail: string, params?: Params) => {
  return service.update(
    null,
    {
      action: 'changeEmail',
      token,
      newEmail,
    },
    params,
  );
};

export const accountsApi = {
  createAccount,
  requestPasswordReset,
  resetPassword,
  verifyEmail,
  resendVerificationMail,
  requestEmailChange,
  changeEmail,
};
