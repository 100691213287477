import AuthLayout from './Auth/index.vue';
import AppLayout from './App/index.vue';
import AdminAuth from './Admin/Auth/index.vue';
import AdminLayout from './Admin/index.vue';

export { AuthLayout, AppLayout, AdminLayout };

export default {
  install: (app: any) => {
    app.component('AppLayout', AppLayout);
    app.component('AuthLayout', AuthLayout);
    app.component('AdminLayout', AdminLayout);
    app.component('AdminAuthLayout', AdminAuth);
  },
};
