<template>
  <div v-if="currentUser.role !== 'subscriber'" class="col-span-2 lg:col-span-1 bg-interface p-3">
    <div class="pb-3 inline-flex text-xs uppercase font-semibold tracking-widest">
      {{ $t('removeAccount') }}
    </div>
    <div class="py-2 text-sm">
      <div>{{ $t('clickToDeleteAccount') }}</div>
      <Button class="mt-2" theme="alert" @click="requestAccountDeletion">
        {{ $t('removeAccount') }}
      </Button>
    </div>
    <Overlay v-model="showRequestAccountDeletionOverlay">
      <div class="text-center max-w-xs">
        <div v-if="!hasOpenSubscriptionFees">
          {{ $t('sentAccountDeletionConfirmationEmail') }}
        </div>

        <div v-else>
          {{ $t('accountDeletionOpenSubscriptionFee') }}
        </div>
        <Button class="mt-2" @click="showRequestAccountDeletionOverlay = false">
          {{ $t('ok') }}
        </Button>
      </div>
    </Overlay>
    <Overlay v-model="showAccountDeletionOverlay">
      <div class="text-center max-w-xs">
        <div v-if="!showCreditsLeftoverMessage">
          <div>
            {{ $t('confirmAccountDeletion') }}
          </div>
          <Button class="mt-2" theme="alert" @click="deleteAccount">
            {{ $t('yes') }}
          </Button>
          <Button class="mt-2 ml-3" @click="showAccountDeletionOverlay = false">
            {{ $t('no') }}
          </Button>
        </div>
        <div v-else>
          <div>{{ $t('accountDeletionCreditsLeftover') }}</div>
          <Button class="mt-2" @click="showAccountDeletionOverlay = false">
            {{ $t('ok') }}
          </Button>
        </div>
      </div>
    </Overlay>
  </div>
</template>

<script>
import { usersApi, subscriptionFeesApi } from '@/api';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      showRequestAccountDeletionOverlay: false,
      showAccountDeletionOverlay: false,
      showCreditsLeftoverMessage: false,
      hasOpenSubscriptionFees: false,
    };
  },

  computed: {
    ...mapGetters(['currentUser']),
  },

  watch: {
    $route: {
      immediate: true,
      async handler() {
        if (this.$route.query && this.$route.query.deletionToken) {
          this.showAccountDeletionOverlay = true;
        }
      },
      deep: true,
    },
  },

  methods: {
    async requestAccountDeletion() {
      this.hasOpenSubscriptionFees = false;
      if (this.currentUser.role === 'adultConfirmed' || this.currentUser.role === 'subscriber') {
        const openSubscriptionFees = await subscriptionFeesApi.findSubscriptionFees({
          query: {
            status: {
              $null: true,
            },
            $limit: 1,
          },
        });
        if (openSubscriptionFees.total) {
          this.hasOpenSubscriptionFees = true;
        }
      }

      if (!this.hasOpenSubscriptionFees) {
        try {
          await usersApi.removeUser(this.currentUser.id);
        } catch (err) {
          console.log(err);
        }
      }

      this.showRequestAccountDeletionOverlay = true;
    },

    async deleteAccount() {
      const resp = await usersApi.removeUser(this.currentUser.id, {
        query: {
          token: this.$route.query.deletionToken,
        },
      });

      if (resp && resp.status && resp.status === 'hasLeftoverCredits') {
        this.showCreditsLeftoverMessage = true;
      } else {
        await this.$store.dispatch('logout');
        this.$router.push('/login');
      }
    },
  },
};
</script>
