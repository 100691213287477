
















































































import { defineComponent } from '@vue/composition-api';
import { onClickOutside } from '@vueuse/core';
import { LanguageSwitch } from '@/ui/components';
import { mapGetters, mapMutations } from 'vuex';
import {
  CLEAR_SEARCH,
  TOGGLE_DRAWER,
  TOGGLE_MOBILE_SEARCH,
} from '@/store/storeTypes/mutationTypes';

export default defineComponent({
  components: {
    LanguageSwitch,
  },

  props: {
    drawerOpen: {
      type: Boolean,
      default: false,
    },
    noLangSwitch: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters(['isMobileSearchOpen', 'isDrawerOpen']),
  },

  data() {
    return {
      searchFilers: [{ name: 'Title', default: true }, { name: 'Tags' }],
    };
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$refs.mobileSearchFieldContainerRef) {
        // @ts-ignore
        onClickOutside(this.$refs.mobileSearchFieldContainerRef, () => {
          if (this.isMobileSearchOpen) {
            this.toggleMobileSearch();
          }
        });
      }
    });
  },

  methods: {
    ...mapMutations([TOGGLE_DRAWER, CLEAR_SEARCH]),
    toggleMobileSearch() {
      this.$store.commit(TOGGLE_MOBILE_SEARCH);
      this.$nextTick(() => {
        if (this.isMobileSearchOpen) {
          // @ts-ignore
          this.$refs.mobileTagsSearchFieldRef?.focus();
          // @ts-ignore
          this.$refs.mobileSearchFieldRef?.focus();
        } else {
          // @ts-ignore
          this.$refs.mobileTagsSearchFieldRef?.blur();
          // @ts-ignore
          this.$refs.mobileSearchFieldRef?.blur();
        }
      });
    },
    onSearch(type: string | null) {
      if (type === 'tags') {
        this.$router
          .push(`/products/search/${this.$store.state.ui.searchValueTags || ''}?filter=Tags`)
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          .catch(() => {});
      } else {
        this.$router
          .push(`/products/search/${this.$store.state.ui.searchValue || ''}`)
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          .catch(() => {});
      }

      this.toggleMobileSearch();
      // @ts-ignore
      this.$refs.desktopTagsSearchFieldRef?.blur();
      // @ts-ignore
      this.$refs.desktopSearchFieldRef?.blur();

      setTimeout(() => {
        this.clearSearch();
      }, 300);
    },
  },
});
