var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n  lg:text-text \n  lg:bg-interface\n  flex \n  justify-between \n  items-center \n  px-3\n  py-6\n  lg:py-2 \n  lg:mb-4 \n  h-10",class:{
    'bg-akzent text-background ': _vm.mobileStyle === 1,
    'border-t border-b lg:py-4 lg:border-0': 
      _vm.mobileStyle === 2,
  },style:({
    'border-color': 'rgba(223, 223, 225, 0.5)'
  })},[_c('div',{staticClass:"inline-flex flex-shrink-0 text-xs uppercase font-semibold tracking-widest"},[_vm._t("default")],2),_c('div',{staticClass:"inline-flex items-center",class:{
      'invisible lg:visible': !_vm.showContext,
    }},[_vm._t("right")],2),_vm._t("custom")],2)}
var staticRenderFns = []

export { render, staticRenderFns }