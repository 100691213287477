<template>
  <transition name="fade">
    <div 
      v-if="show"
      class="fixed w-full h-full inset-0 bg-background 
        bg-opacity-75 z-50 flex items-center justify-center"
    >
      <div 
        class="absolute top-0 right-0 p-4 md:p-8 z-10 cursor-pointer" 
        @click="$emit('input', false)"
      >
        <Icon name="close" :size="24" />
      </div>

      <carousel
        ref="full-screen-slider"
        v-model="position"
        class="w-full h-full"
        :per-page="itemsPerPage"
        :loop="true"
        :pagination-enabled="false"
        :navigation-enabled="!isMobile"
      >
        <slide 
          v-for="(image, i) in images" 
          :key="`image-slider-image-${i}`"
          class="w-full"
        >
          <div class="mx-2 h-screen md:p-8 lg:p-16 xl:p-24">
            <div 
              v-if="(position + 3) > i"
              class="w-full h-full"
              :style="{
                backgroundImage: `url(&quot;${image.url}&quot;)`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
              }"
            />
          </div>
        </slide>
      </carousel>
      <div class="absolute bottom-0 p-4">
        {{ $t('imageSlider', { currentImage: position + 1, imageCount: images.length }) }}
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    images: {
      type: Array,
    },
    value: {
      type: Boolean,
    },
    itemsPerPage: {
      type: Number,
      default: 3,
    },
  },

  data() {
    return {
      position: 0,
    };
  },

  computed: {
    ...mapGetters(['currentBreakpoint']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isMobile() {
      return ['xs', 'sm', 'md'].some((bp) => bp === this.currentBreakpoint);
    },
  },
};
</script>

<style>
.VueCarousel-navigation-button {
  color: #fff !important;
  padding: 2rem !important;
}

.VueCarousel-navigation-button:focus {
  outline: none !important;
}

.VueCarousel-navigation-prev,
.VueCarousel-navigation-next {
  transform: none !important;
}
</style>
