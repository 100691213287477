<template>
  <div 
    class="
          col-span-4 
          xl:col-span-1 
          grid 
          grid-cols-2 
          sm:grid-cols-3 
          md:grid-cols-7
          xl:grid-cols-3
          lg:gap-3
          gap-x-2
          -mt-3
          lg:m-0
        "
  >
    <SeperationBox class="xl:hidden col-span-2 sm:col-span-3 md:col-span-7">
      <template>
        {{ $t('modelsInThisVideo') }}
      </template>
    </SeperationBox>
    <ModelListItem 
      v-for="model in currentProduct.models"
      :key="model.id"
      :to="`/models/${model.id}`"
      :title="model.name"
      :thumbnail-src="model.picture"
      class="lg:-mt-4 xl:m-0"
      :small="currentBreakpoint === 'xl' || currentBreakpoint === '2xl'"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SeperationBox from '../SeperationBox/index.vue';
import ModelListItem from '../ModelListItem/index.vue';

export default {
  components: {
    SeperationBox,
    ModelListItem,
  },

  computed: {
    ...mapGetters(['currentProduct', 'currentBreakpoint']),
  },
};
</script>
