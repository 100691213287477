<template>
  <form @submit.prevent="onRequestPasswordReset">
    <div v-if="!requestedPasswordReset" :class="fieldStyles">
      <label for="email-input" :class="labelStyles">{{ $t('email') }}</label>
      <input
        id="email-input"
        v-model="email"
        type="email"
        :class="inputStyles"
        :placeholder="$t('email')"
        required
      >
    </div>

    <transition name="fade">
      <div v-if="requestedPasswordReset" class="text-green-600 text-xs">
        {{ $t('requestedPasswordReset') }}
      </div>
    </transition>
          
          
    <div class="mt-1">
      <Button v-if="!requestedPasswordReset" type="submit">
        {{ $t('resetPassword') }}
      </Button>
    </div>
    <div
      class="text-xs inline-block cursor-pointer line hover:opacity-75"
      :class="{ 'mt-4': !requestedPasswordReset }"
      @click="$emit('changeMode', 'login')"
    >
      {{ $t('back') }}
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    email: '',
    requestedPasswordReset: false,
    fieldStyles: 'pb-3',
    labelStyles: 'pl-2 block uppercase text-xxs tracking-wide',
    inputStyles: 'w-full bg-text py-1 pl-2 text-black text-base lg:text-xs',
  }),

  computed: {
    ...mapGetters(['hasPasswordResetError']),
  },

  watch: {
    email() {
      this.error = false;
    },
  },

  methods: {
    ...mapActions(['requestPasswordReset']),

    async onRequestPasswordReset() {
      if (this.email) {
        await this.requestPasswordReset(this.email);
        this.requestedPasswordReset = true;
      }
    },
  },
};
</script>
