var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500","scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v("Aboplan erstellen")]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"counter":25,"rules":[function (v) { return !!v || 'Name ist erforderlich'; }],"label":"Name*","required":""},model:{value:(_vm.subscriptionPlan.name),callback:function ($$v) {_vm.$set(_vm.subscriptionPlan, "name", $$v)},expression:"subscriptionPlan.name"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"disabled":_vm.subscriptionPlan.oneTime,"label":"Mindestlaufzeit*","type":"number","suffix":"Monate (30 Tage)","min":"1","rules":[
                function (v) { return !!v || 'Mindestlaufzeit ist erforderlich'; },
                function (v) { return v < 1 ? 'Mindestlaufzeit kann nicht negativ sein' : true; }
              ],"required":""},model:{value:(_vm.subscriptionPlan.minimumTerm),callback:function ($$v) {_vm.$set(_vm.subscriptionPlan, "minimumTerm", _vm._n($$v))},expression:"subscriptionPlan.minimumTerm"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Laufzeit*","type":"number","suffix":"Monate (30 Tage)","min":"1","rules":[
                function (v) { return !!v || 'Laufzeit ist erforderlich'; },
                function (v) { return v < 1 ? 'Laufzeit kann nicht negativ sein' : true; } ],"required":""},model:{value:(_vm.subscriptionPlan.term),callback:function ($$v) {_vm.$set(_vm.subscriptionPlan, "term", _vm._n($$v))},expression:"subscriptionPlan.term"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('MoneyInput',{attrs:{"options":{ currency: null, allowNegative: false, valueAsInteger: true },"label":"Monatlicher Preis*","suffix":"€","rules":[function (v) { return !!v || 'Monatlicher Preis ist erforderlich'; }],"required":""},model:{value:(_vm.subscriptionPlan.monthlyPrice),callback:function ($$v) {_vm.$set(_vm.subscriptionPlan, "monthlyPrice", $$v)},expression:"subscriptionPlan.monthlyPrice"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Produktrabatt","type":"number","suffix":"%","min":"1","max":"100","rules":[
                function (v) { return (v || v === 0) && (v > 100 || v <= 0)
                  ? 'Produktrabatt kann nur 1-100 sein' : true; } ],"required":""},model:{value:(_vm.subscriptionPlan.productDiscount),callback:function ($$v) {_vm.$set(_vm.subscriptionPlan, "productDiscount", _vm._n($$v))},expression:"subscriptionPlan.productDiscount"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Abschlussbonus","type":"number","suffix":"Credits","min":"1","rules":[
                function (v) { return (v || v === 0) && v <= 0
                  ? 'Abschlussbonus kann nicht negativ sein' : true; }
              ],"required":""},model:{value:(_vm.subscriptionPlan.bonus),callback:function ($$v) {_vm.$set(_vm.subscriptionPlan, "bonus", _vm._n($$v))},expression:"subscriptionPlan.bonus"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-checkbox',{attrs:{"disabled":_vm.subscriptionPlan.oneTime,"label":"Wiederkehrend"},model:{value:(_vm.subscriptionPlan.recurring),callback:function ($$v) {_vm.$set(_vm.subscriptionPlan, "recurring", $$v)},expression:"subscriptionPlan.recurring"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-checkbox',{attrs:{"label":"Einmalige Bezahlung"},model:{value:(_vm.subscriptionPlan.oneTime),callback:function ($$v) {_vm.$set(_vm.subscriptionPlan, "oneTime", $$v)},expression:"subscriptionPlan.oneTime"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"primary","disabled":!_vm.valid},on:{"click":_vm.validate}},[_vm._v(" Speichern ")])],1),_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"absolute":"","indeterminate":"","bottom":""}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }