import { productsApi } from '@/api';
import type { Module } from 'vuex';
import type { TRootStore } from '@/store';
import type { IProduct } from '@/types/Products';
import { ICategory } from '@/types/Categories';
import {
  SET_SPECIAL_OFFER,
  SET_TOTAL_SPECIAL_OFFERS,
  SET_LAST_SPECIAL_OFFER_POSITION,
} from './storeTypes/mutationTypes';

export interface ISpecialOfferStoreState {
  specialOffer: IProduct | null;
  totalSpecialOffers: number | null;
  lastSpecialOfferPosition: number | null;
}

type TSpecialOfferStore = Module<ISpecialOfferStoreState, TRootStore>;

const SpecialOfferStore: TSpecialOfferStore = {
  state: () => ({
    specialOffer: null,
    totalSpecialOffers: null,
    lastSpecialOfferPosition: null,
  }),

  getters: {
    specialOffer: (state) => state.specialOffer,
    specialOfferCategorie: (state, getters) => {
      return (
        getters.allCategories.find((categorie: ICategory) => categorie.name === 'Special Offer') ||
        null
      );
    },
  },

  mutations: {
    [SET_SPECIAL_OFFER](state, product: IProduct) {
      state.specialOffer = product;
    },
    [SET_TOTAL_SPECIAL_OFFERS](state, total: number) {
      state.totalSpecialOffers = total;
    },
    [SET_LAST_SPECIAL_OFFER_POSITION](state, lastPosition: number) {
      state.lastSpecialOfferPosition = lastPosition;
    },
  },

  actions: {
    async initSpecialOffer({ getters, dispatch }) {
      if (getters.specialOfferCategorie) {
        const { total } = await productsApi.findProducts({
          query: {
            $joinRelation: 'categories',
            'categories.id': getters.specialOfferCategorie.id,
            type: {
              $ne: 'bundle',
            },
            editing: false,
            status: 'processed',
            visable: 1,
            $limit: 0,
          },
        });

        this.commit(SET_TOTAL_SPECIAL_OFFERS, total);
        await dispatch('loadSpecialOffer');
      }
    },

    async loadSpecialOffer({ commit, state, getters }) {
      if (state.totalSpecialOffers) {
        let randomCatPosition;
        do {
          randomCatPosition = Math.floor(Math.random() * state.totalSpecialOffers);
        } while (
          state.lastSpecialOfferPosition !== null &&
          state.totalSpecialOffers > 1 &&
          randomCatPosition === state.lastSpecialOfferPosition
        );

        commit(SET_LAST_SPECIAL_OFFER_POSITION, randomCatPosition);
        const { data } = await productsApi.findProducts({
          query: {
            $joinRelation: 'categories',
            $eager: {
              files: { types: true },
            },
            'categories.id': getters.specialOfferCategorie.id,
            type: {
              $ne: 'bundle',
            },
            editing: false,
            status: 'processed',
            visable: 1,
            $skip: randomCatPosition,
            $limit: 1,
          },
        });

        [state.specialOffer] = data;
      }
    },
  },
};

export default SpecialOfferStore;
