var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Bezahlungen-Übersicht ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.allPayments,"loading":_vm.paymentsLoading,"options":_vm.options,"server-items-length":_vm.paymentsTotal,"footer-props":{
          'items-per-page-options': [5, 10, 15, 30, 50],
          'items-per-page-text': 'Zeilen pro Seite',
        },"multi-sort":true},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.credits",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.credits))])]}},{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatMoney")(item.amount)))])]}},{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.createdAt)))])]}},{key:"item.updatedAt",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.updatedAt)))])]}},{key:"footer.page-text",fn:function(ref){
        var pageStart = ref.pageStart;
        var pageStop = ref.pageStop;
        var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" von "+_vm._s(itemsLength)+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }