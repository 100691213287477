<template>
  <div @contextmenu.prevent="() => { return false }">
    <video ref="videoPlayer" class="video-js vjs-default-skin vjs-fluid" />
  </div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import qualitySelector from '@silvermine/videojs-quality-selector';
import '@silvermine/videojs-quality-selector/dist/css/quality-selector.css';

qualitySelector(videojs);

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options);
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
