<template>
  <v-row>
    <v-col cols="12" md="4">
      <v-form ref="form" v-model="fromIsValid" lazy-validation>
        <v-card class="h-full" :loading="usersLoading">
          <v-card-title>Stammdaten</v-card-title>
          <v-divider />
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Vorname</v-list-item-title>
              <v-list-item-subtitle>{{ selectedUser.firstName }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Nachname</v-list-item-title>
              <v-list-item-subtitle>{{ selectedUser.lastName }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-text>
            <v-text-field
              v-model="patchUserData.email"
              dense
              :rules="[
                v => !!v || 'Email ist erforderlich',
              ]"
              label="E-Mail"
              required
            />
          
            <v-text-field
              v-model="patchUserData.password"
              type="password"
              dense
              :rules="[
                v => !!v || 'Passwort ist erforderlich',
                v => v.length >= 6 || 'Passwort muss min. 6 Zeichen haben'
              ]"
              :minlength="6"
              label="Passwort"
              required
            />

            <v-checkbox
              v-model="patchUserData.blocked"
              dense
              label="Blockiert"
            />

            <v-checkbox
              v-if="selectedUser.role === 'verifiedUser' || selectedUser.role === 'registeredUser'"
              v-model="setUserAdultConfirmed"
              dense
              label="Rolle auf &quot;Bestätiger Erwachsener&quot; setzten"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn :disabled="!fromIsValid" @click="submitUserPatchData">
              Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
    <v-col cols="12" md="4">
      <v-card class="h-full">
        <v-card-title>Statusinformationen</v-card-title>
        <v-divider />
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>ID</v-list-item-title>
            <v-list-item-subtitle>{{ selectedUser.id }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Rolle</v-list-item-title>
            <v-list-item-subtitle>{{ selectedUser.role | formatRole }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Angemeldet seit</v-list-item-title>
            <v-list-item-subtitle>{{ selectedUser.createdAt | formatDate }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
    <v-col cols="12" md="4">
      <v-card class="h-full">
        <v-card-title>Aboinformationen</v-card-title>
        <v-divider />
        <div v-if="activeSubscription">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Aboplan</v-list-item-title>
              <v-list-item-subtitle>
                {{ activeSubscription.subscriptionPlan.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Start</v-list-item-title>
              <v-list-item-subtitle>
                {{ activeSubscription.start | formatDate }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Ende</v-list-item-title>
              <v-list-item-subtitle>
                {{ activeSubscription.end | formatDate }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Gekündigt</v-list-item-title>
              <v-list-item-subtitle>
                {{ activeSubscription.canceled ? 'Ja' : 'Nein' }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <div class="px-4">
            <v-btn
              class="w-full "
              color="primary"
              @click="cancelSubscription"
            >
              Abo kündigen
            </v-btn>
          </div>
        </div>
        <div v-else>
          <v-card-text v-if="!activeSubscription">User hat kein Abo abgeschlossen</v-card-text>
          <div v-if="selectedUser.role === 'adultConfirmed'" class="px-4">
            <div>
              <v-select
                v-model="selectedOneTimeSubscriptionPlan"
                :items="oneTimeSubscriptionPlans"
                item-text="name"
                item-value="id"
                clearable
                label="Aboplan"
              />
              <v-btn
                :disabled="!selectedOneTimeSubscriptionPlan"
                class="w-full"
                color="primary"
                @click="assignSubscriptionToUser"
              >
                Abo zuweisen
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-col>
    <v-snackbar v-model="emailChangeError" multi-line>
      Die angebene E-Mail wird bereits verwendet.
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="emailChangeError = false"
        >
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import { subscriptionsPlansApi, usersApi } from '@/api';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { SET_USER } from '@/store/storeTypes/mutationTypes';

export default {
  props: {
    selectedUser: {
      type: Object,
      required: true,
    },
    activeSubscription: {
      type: [Object, null],
    },
  },

  data() {
    return {
      fromIsValid: true,
      patchUserData: {},
      emailChangeError: false,
      setUserAdultConfirmed: false,
      oneTimeSubscriptionPlans: [],
      selectedOneTimeSubscriptionPlan: null,
      subscriptionAssignmentLoading: false,
      cancelSubscriptionLoading: false,
    };
  },

  computed: {
    ...mapGetters(['usersLoading']),
  },

  async created() {
    this.patchUserData = {
      email: this.selectedUser.email,
      password: 'eeeeee',
      blocked: this.selectedUser.blocked,
    };
    this.setUserAdultConfirmed = false;
    if (this.selectedUser.role === 'adultConfirmed') {
      const { data } = await subscriptionsPlansApi.findSubscriptionPlans({
        query: {
          archived: false,
          oneTime: true,
          $limit: 150,
        },
      });
      this.oneTimeSubscriptionPlans = data;
    }
  },

  methods: {
    ...mapActions(['patchUser', 'findSubscriptionPlans']),
    ...mapMutations([SET_USER]),
    async submitUserPatchData() {
      const data = {
        email: this.patchUserData.email,
        blocked: this.patchUserData.blocked,
      };

      if (this.patchUserData.password !== 'eeeeee') {
        data.password = this.patchUserData.password;
      }

      if (this.setUserAdultConfirmed) {
        data.role = 'adultConfirmed';
      }

      try {
        await this.patchUser({
          id: this.selectedUser.id,
          data,
        });
      } catch (err) {
        if (err.code === 409) {
          this.emailChangeError = true;
        } else {
          throw err;
        }
      } finally {
        this.setUserAdultConfirmed = false;
      }
    },

    async assignSubscriptionToUser() {
      this.subscriptionAssignmentLoading = true;
      const subscription = await usersApi.assignSubscriptionToUser(
        this.selectedUser.id,
        this.selectedOneTimeSubscriptionPlan,
      );
      this.subscriptionAssignmentLoading = false;
      this.setUser({
        ...this.selectedUser,
        role: 'subscriber',
      });
      this.$emit('updateActiveSubscription', subscription);
    },

    async cancelSubscription() {
      this.cancelSubscriptionLoading = true;
      await usersApi.cancelUserSubscription(this.selectedUser.id);
      this.cancelSubscriptionLoading = false;
      this.setUser({
        ...this.selectedUser,
        role: 'adultConfirmed',
      });
      this.$emit('updateActiveSubscription', null);
    },
  },
};
</script>

