import type { RouteConfig } from 'vue-router';

const routes = (defaultRoute: string): Array<RouteConfig> => [
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "defaultroutes" */ '../ui/views/Auth.vue'),
    meta: {
      layout: 'Auth',
      public: true,
    },
  },
  {
    path: '/reset-password/:token',
    component: () => import(/* webpackChunkName: "products" */ '../ui/views/ResetPassword.vue'),
    meta: {
      layout: 'Auth',
      public: true,
      accessibleWithAuth: true,
    },
  },
  {
    path: '/verify-email/:token',
    component: () => import(/* webpackChunkName: "products" */ '../ui/views/VerifyEmail.vue'),
    meta: {
      layout: 'Auth',
      public: true,
      accessibleWithAuth: true,
    },
  },
  {
    path: '/change-email/:token',
    component: () => import(/* webpackChunkName: "products" */ '../ui/views/ChangeEmail.vue'),
    meta: {
      layout: 'Auth',
      public: true,
      accessibleWithAuth: true,
    },
  },
  {
    path: '/confirm-email',
    component: () => import(/* webpackChunkName: "products" */ '../ui/views/ConfirmEmail.vue'),
    meta: {
      layout: 'App',
      allowedUserRoles: ['registeredUser'],
    },
  },
  {
    path: '/verify-age',
    component: () => import(/* webpackChunkName: "products" */ '../ui/views/VerifyAge.vue'),
    meta: {
      layout: 'App',
      allowedUserRoles: ['verifiedUser'],
    },
  },
  {
    path: '/products/:filter?/:id?',
    component: () => import(/* webpackChunkName: "products" */ '../ui/views/Products.vue'),
    meta: {
      layout: 'App',
      allowedUserRoles: ['verifiedUser', 'adultConfirmed', 'subscriber'],
    },
  },
  {
    path: '/product/:id',
    component: () => import(/* webpackChunkName: "products" */ '../ui/views/ProductDetail.vue'),
    meta: {
      layout: 'App',
      allowedUserRoles: ['verifiedUser', 'adultConfirmed', 'subscriber'],
    },
  },
  {
    path: '/models',
    component: () => import(/* webpackChunkName: "products" */ '../ui/views/Models.vue'),
    meta: {
      layout: 'App',
      allowedUserRoles: ['verifiedUser', 'adultConfirmed', 'subscriber'],
    },
  },
  {
    path: '/models/:id',
    component: () => import(/* webpackChunkName: "products" */ '../ui/views/ModelDetail.vue'),
    meta: {
      layout: 'App',
      allowedUserRoles: ['verifiedUser', 'adultConfirmed', 'subscriber'],
    },
  },
  {
    path: '/add-credits',
    component: () => import(/* webpackChunkName: "products" */ '../ui/views/AddCredits.vue'),
    meta: {
      layout: 'App',
      allowedUserRoles: ['adultConfirmed', 'subscriber'],
    },
  },
  {
    path: '/add-credits/:slug',
    component: () => import(/* webpackChunkName: "products" */ '../ui/views/AddCredits.vue'),
    meta: {
      layout: 'App',
      allowedUserRoles: ['adultConfirmed', 'subscriber'],
    },
  },
  {
    path: '/add-credits-gold-cards',
    component: () =>
      import(/* webpackChunkName: "products" */ '../ui/views/AddCreditsGoldCards.vue'),
    meta: {
      layout: 'App',
      allowedUserRoles: ['adultConfirmed', 'subscriber'],
    },
  },
  {
    path: '/subscriptions',
    component: () => import(/* webpackChunkName: "products" */ '../ui/views/Subscriptions.vue'),
    meta: {
      layout: 'App',
      allowedUserRoles: ['adultConfirmed', 'subscriber'],
    },
  },
  {
    path: '/subscriptions/:slug',
    component: () => import(/* webpackChunkName: "products" */ '../ui/views/Subscriptions.vue'),
    meta: {
      layout: 'App',
      allowedUserRoles: ['adultConfirmed', 'subscriber'],
    },
  },
  /* {
    path: '/subscription/change-payment-method/:slug?',
    component: () =>
      import( '../ui/views/SubscriptionChangePaymentType.vue'),
    meta: {
      layout: 'App',
      allowedUserRoles: ['subscriber'],
    },
  }, */
  {
    path: '/news',
    component: () => import(/* webpackChunkName: "products" */ '../ui/views/News.vue'),
    meta: {
      layout: 'App',
    },
  },
  {
    path: '/my-account',
    component: () => import(/* webpackChunkName: "products" */ '../ui/views/MyAccount.vue'),
    meta: {
      layout: 'App',
    },
  },
  {
    path: '/content/:slug',
    component: () =>
      import(/* webpackChunkName: "defaultroutes" */ '../ui/views/Content/index.vue'),
    meta: {
      layout: 'App',
    },
  },
  {
    path: '/:path(.*)',
    redirect: defaultRoute,
  },
];

export default routes;
