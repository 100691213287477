import type { Params } from '@feathersjs/feathers';
import type { TFindResponse, TGetResponse } from '@/types/Api';
import type { ISubscription, ICreateSubscription, IPatchSubscription } from '@/types/Subscription';
import { client } from '../client';

const service = client.service('subscriptions');

const findSubscriptions = (params?: Params): TFindResponse<ISubscription> => {
  return service.find({
    ...(params || {}),
    query: {
      $limit: 30,
      ...(params?.query || {}),
    },
  });
};

const createSubscription = (
  data: ICreateSubscription,
  params?: Params,
): TGetResponse<ISubscription> => {
  return service.create(data, params || {});
};

const patchSubscription = (
  id: number,
  data: IPatchSubscription,
  params?: Params,
): TGetResponse<ISubscription> => {
  return service.patch(id, data, params);
};

const updatePaymentType = (
  subscriptionId: number,
  paymentTypeId: string,
  params?: Params,
): TGetResponse<ISubscription> => {
  return client
    .service(`subscriptions/${subscriptionId}/paymentTypeId`)
    .update(null, { paymentTypeId }, params);
};

const confirmNewPaymentType = (
  subscriptionId: number,
  params?: Params,
): TGetResponse<ISubscription> => {
  return client
    .service(`subscriptions/${subscriptionId}/paymentTypeId`)
    .update(null, { confirmed: true }, params);
};

export const subscriptionsApi = {
  findSubscriptions,
  createSubscription,
  patchSubscription,
  updatePaymentType,
  confirmNewPaymentType,
};
