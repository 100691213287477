import feathers from '@feathersjs/feathers';
import restApiClient from '@feathersjs/rest-client';
import { authClient } from './authClient';

const rest = restApiClient(process.env.VUE_APP_API_URL);
const restClient = feathers();

restClient.configure(rest.fetch(window.fetch));
restClient.configure(authClient);

export { restClient };
