<template>
  <button
    :type="type"
    class="
      uppercase tracking-wide text-xs inline-block
      px-2 py-1 rounded cursor-pointer hover:opacity-75
    "
    :class="`${selectedTheme} ${customClass}`"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'button',
    },
    theme: {
      type: String,
      default: 'akzent',
    },
    customClass: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      akzent: 'text-background bg-akzent',
      alert: 'text-background bg-red-700',
      orange: 'text-background bg-orange',
      light: '',
    };
  },

  computed: {
    selectedTheme() {
      return this[this.theme];
    },
  },
};
</script>
