import type { Module } from 'vuex';
import type { TRootStore } from '@/store';
import {
  RESET_PRODUCT_CREATION,
  UPDATE_PRODUCT_CREATION,
  SET_PRODUCT_CREATION_TYPE,
  SET_PRODUCT_CREATION_ORDER_ID,
  SET_PRODUCT_CREATION_TITLE,
  SET_PRODUCT_CREATION_KEYWORDS,
  SET_PRODUCT_CREATION_CREDITS,
  SET_PRODUCT_CREATION_LENGTH,
  SET_PRODUCT_CREATION_VISABLE,
  SET_PRODUCT_CREATION_PINNED,
  SET_PRODUCT_CREATION_IMAGE_COUNT,
  SET_PRODUCT_CREATION_PRODUCER,
  SET_PRODUCT_CREATION_RESOLUTION,
  SET_PRODUCT_CREATION_DIRECTLY_STREAMABLE_FOR_SUBSCRIBERS,
  SET_PRODUCT_CREATION_EXCLUSIVE_FOR_SUBSCRIBERS,
  SET_PRODUCT_CREATION_DOWNLOADABLE_FOR_SUBSCRIBERS,
  SET_PRODUCT_CREATION_TRANSLATION_ENGLISH_SHORT,
  SET_PRODUCT_CREATION_TRANSLATION_ENGLISH_LONG,
  SET_PRODUCT_CREATION_TRANSLATION_GERMAN_SHORT,
  SET_PRODUCT_CREATION_TRANSLATION_GERMAN_LONG,
  ADD_PRODUCT_CREATION_CATEGORIES,
  REMOVE_PRODUCT_CREATION_CATEGORIE,
  ADD_PRODUCT_CREATION_MODELS,
  REMOVE_PRODUCT_CREATION_MODEL,
  ADD_PRODUCT_CREATION_FILES,
  REMOVE_PRODUCT_CREATION_FILE,
  ADD_PRODUCT_CREATION_FILE_TYPE,
  REMOVE_PRODUCT_CREATION_FILE_TYPE,
  PRODUCT_CREATION_REMOVE_ALL_FILES,
  UPDATE_PRODUCT_CREATION_FILE,
} from './storeTypes/mutationTypes';

export interface ICreateProductStoreState {
  product: any;
}

type ICreateProductStore = Module<ICreateProductStoreState, TRootStore>;

const defaultProdcut = () => ({
  type: 'movie',
  orderId: '',
  title: '',
  keywords: '',
  credits: '',
  length: '',
  visable: true,
  pinned: false,
  imageCount: '',
  producer: '',
  resolution: '',
  directlyStreamableForSubscribers: false,
  exclusiveForSubscribers: false,
  downloadableForSubscibers: false,
  translations: [
    {
      language: 'en',
      shortDescription: '',
      longDescription: '',
    },
    {
      language: 'de',
      shortDescription: '',
      longDescription: '',
    },
  ],
  categories: [],
  models: [],
  files: [],
});

const createProductStore: ICreateProductStore = {
  state: () => ({
    product: defaultProdcut(),
  }),

  getters: {
    currentProductCreation: (state) => state.product,
  },

  mutations: {
    [RESET_PRODUCT_CREATION](state) {
      state.product = defaultProdcut();
    },
    [UPDATE_PRODUCT_CREATION](state, data: any) {
      state.product = { ...state.product, data };
    },
    [SET_PRODUCT_CREATION_TYPE](state, type: string) {
      state.product.type = type;
    },
    [SET_PRODUCT_CREATION_ORDER_ID](state, orderId: string) {
      state.product.orderId = orderId;
    },
    [SET_PRODUCT_CREATION_TITLE](state, title: string) {
      state.product.title = title;
    },
    [SET_PRODUCT_CREATION_KEYWORDS](state, keywords: string) {
      state.product.keywords = keywords;
    },
    [SET_PRODUCT_CREATION_CREDITS](state, credits: string) {
      state.product.credits = credits;
    },
    [SET_PRODUCT_CREATION_LENGTH](state, length: string) {
      state.product.length = length;
    },
    [SET_PRODUCT_CREATION_VISABLE](state, visable: boolean) {
      state.product.visable = visable;
    },
    [SET_PRODUCT_CREATION_PINNED](state, pinned: boolean) {
      state.product.pinned = pinned;
    },
    [SET_PRODUCT_CREATION_IMAGE_COUNT](state, imageCount: string) {
      state.product.imageCount = imageCount;
    },
    [SET_PRODUCT_CREATION_PRODUCER](state, producer: string) {
      state.product.producer = producer;
    },
    [SET_PRODUCT_CREATION_RESOLUTION](state, resolution: string) {
      state.product.resolution = resolution;
    },
    [SET_PRODUCT_CREATION_DIRECTLY_STREAMABLE_FOR_SUBSCRIBERS](
      state,
      directlyStreamableForSubscribers: boolean,
    ) {
      state.product.directlyStreamableForSubscribers = directlyStreamableForSubscribers;
    },
    [SET_PRODUCT_CREATION_EXCLUSIVE_FOR_SUBSCRIBERS](state, exclusiveForSubscribers: boolean) {
      state.product.exclusiveForSubscribers = exclusiveForSubscribers;
    },
    [SET_PRODUCT_CREATION_DOWNLOADABLE_FOR_SUBSCRIBERS](state, downloadableForSubscibers: boolean) {
      state.product.downloadableForSubscibers = downloadableForSubscibers;
    },
    [SET_PRODUCT_CREATION_TRANSLATION_ENGLISH_SHORT](state, value: string) {
      state.product.translations[0].shortDescription = value;
    },
    [SET_PRODUCT_CREATION_TRANSLATION_ENGLISH_LONG](state, value: string) {
      state.product.translations[0].longDescription = value;
    },
    [SET_PRODUCT_CREATION_TRANSLATION_GERMAN_SHORT](state, value: string) {
      state.product.translations[1].shortDescription = value;
    },
    [SET_PRODUCT_CREATION_TRANSLATION_GERMAN_LONG](state, value: string) {
      state.product.translations[1].longDescription = value;
    },
    [ADD_PRODUCT_CREATION_CATEGORIES](state, categories) {
      state.product.categories = state.product.categories.concat(categories);
    },
    [REMOVE_PRODUCT_CREATION_CATEGORIE](state, categorieId: number) {
      const index = state.product.categories.findIndex(
        (categorie: any) => categorie.id === categorieId,
      );
      if (index !== -1) {
        state.product.categories.splice(index, 1);
      }
    },
    [ADD_PRODUCT_CREATION_MODELS](state, models) {
      state.product.models = state.product.models.concat(models);
    },
    [REMOVE_PRODUCT_CREATION_MODEL](state, modelId: number) {
      const index = state.product.models.findIndex((model: any) => model.id === modelId);
      if (index !== -1) {
        state.product.models.splice(index, 1);
      }
    },
    [ADD_PRODUCT_CREATION_FILES](state, files) {
      state.product.files = state.product.files.concat(files);
    },
    [UPDATE_PRODUCT_CREATION_FILE](
      state,
      { internalId, updatedFile }: { internalId: number; updatedFile: any },
    ) {
      const index = state.product.files.findIndex((file: any) => file.internalId === internalId);
      if (index !== -1) {
        state.product.files[index] = {
          ...state.product.files[index],
          ...updatedFile,
        };
      }
    },
    [REMOVE_PRODUCT_CREATION_FILE](state, internalId: number) {
      const index = state.product.files.findIndex((file: any) => file.internalId === internalId);
      if (index !== -1) {
        state.product.files.splice(index, 1);
      }
    },
    [ADD_PRODUCT_CREATION_FILE_TYPE](
      state,
      { internalFileId, typeName }: { internalFileId: number; typeName: string },
    ) {
      const index = state.product.files.findIndex(
        (file: any) => file.internalId === internalFileId,
      );
      if (index !== -1) {
        state.product.files[index].types.push({ name: typeName });
      }
    },
    [REMOVE_PRODUCT_CREATION_FILE_TYPE](
      state,
      { internalFileId, typeName }: { internalFileId: number; typeName: string },
    ) {
      const index = state.product.files.findIndex(
        (file: any) => file.internalId === internalFileId,
      );
      if (index !== -1) {
        const typeIndex = state.product.files[index].types.findIndex(
          (type: any) => type.name === typeName,
        );
        if (index !== -1) {
          state.product.files[index].types.splice(typeIndex, 1);
        }
      }
    },
    [PRODUCT_CREATION_REMOVE_ALL_FILES](state) {
      state.product.files = [];
    },
  },
};

export default createProductStore;
