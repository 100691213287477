<template>
  <router-link :to="to" class="group shadow-none focus:outline-none focus:shadow-outline">
    <div class="group-hover:opacity-75 relative transition-all duration-150">
      <CustomImage :url="thumbnailSrc" aspect-ratio="3/4" />
    </div>
    <div 
      class="inline-flex justify-between items-center w-full md:block " 
      :class="{ 'mt-2 px-3 pb-3': !small, 'mt-1': small }"
    >
      <div class="font-bold" :class="{ 'text-xxs': small, 'text-sm': !small }">
        {{ title }}
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    thumbnailSrc: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
