<template>
  <span>{{ creditsWithProductDiscount }} Credits</span>
</template>

<script>
export default {
  props: {
    credits: {
      type: Number,
    },
    activeSubscription: {
      type: Object,
      required: false,
    },
  },
  computed: {
    creditsWithProductDiscount() {
      if (
        this.activeSubscription &&
        this.activeSubscription.subscriptionPlan &&
        this.activeSubscription.subscriptionPlan.productDiscount
      ) {
        return (
          this.credits -
          Math.ceil((this.credits * this.activeSubscription.subscriptionPlan.productDiscount) / 100)
        );
      }

      return this.credits;
    },
  },
};
</script>
