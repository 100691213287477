import type { Params } from '@feathersjs/feathers';
import type { TFindResponse, TGetResponse } from '@/types/Api';
import type { IBundle, IBundeProduct } from '@/types/Bundles';
import { client } from '../client';

const service = client.service('bundles');

const findBundles = (params?: Params): TFindResponse<IBundle> => {
  return service.find({
    ...(params || {}),
    query: {
      $limit: 30,
      ...(params?.query || {}),
    },
  });
};

const getBundle = (id: number, params?: Params): TGetResponse<IBundle> => {
  return service.get(id, params);
};

const patchBundle = (id: number, data: any, params?: Params): TGetResponse<IBundle> => {
  return service.patch(id, data, params);
};

const createBundle = (data: IBundle, params?: Params): TGetResponse<IBundle> => {
  return service.create(data, params);
};

const removeBundle = (id: number, params?: Params): TGetResponse<IBundle> => {
  return service.remove(id, params);
};

const addProductToBundle = (
  bundleId: number,
  productId: number,
  params?: Params,
): TGetResponse<IBundeProduct> => {
  return client.service(`bundles/${bundleId}/products`).create(
    {
      productId,
    },
    params,
  );
};

const removeProductFromBundle = (
  bundleId: number,
  productId: number,
  params?: Params,
): TGetResponse<IBundeProduct> => {
  return client.service(`bundles/${bundleId}/products`).remove(productId, params);
};

export const bundlesApi = {
  findBundles,
  getBundle,
  patchBundle,
  createBundle,
  removeBundle,
  addProductToBundle,
  removeProductFromBundle,
};
