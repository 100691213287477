<template>
  <v-dialog
    v-model="show"
    width="1200"
    scrollable
    persistent
  >
    <v-card>
      <v-stepper v-model="stepperStep" class="overflow-y-visible">
        <v-stepper-header>
          <v-stepper-step
            :complete="stepperStep > 1"
            step="1"
          >
            Produkteigentschaften
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="stepperStep > 2"
            step="2"
          >
            Beschreibungen
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="stepperStep > 3"
            step="3"
          >
            Kategorien & Models
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="stepperStep > 4"
            step="4"
          >
            Dateien
          </v-stepper-step>
          <v-divider />
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <ProductCreateDialogProperties 
              ref="propertiesForm"
              @next="stepperStep = 2" 
              @closeDialog="$emit('input', false);"
            />
          </v-stepper-content>
          <v-stepper-content step="2">
            <ProductCreateDialogTranslations
              ref="productTranslationInput"
              @back="stepperStep = 1"
              @next="stepperStep = 3"
            />
          </v-stepper-content>
          <v-stepper-content step="3">
            <ProductCreateDialogSelections 
              @back="stepperStep = 2"
              @next="stepperStep = 4"
            />
          </v-stepper-content>
          <v-stepper-content step="4">
            <ProductCreateDialogFiles
              @back="stepperStep = 3"
              @submit="createProductAndUploadFiles"
            />
          </v-stepper-content>
        </v-stepper-items>
        <v-overlay :value="loading" :dark="false">
          <v-card>
            <v-card-text class="text-center">
              <v-progress-linear :value="filesUploaded * 100 / filesTotal" class="mb-4" />
              {{ filesUploaded }} von {{ filesTotal }} Dateien sind hochgeladen. 
              <br> Bitte warten...
            </v-card-text>
          </v-card>
        </v-overlay>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import _ from 'lodash';
import {
  RESET_PRODUCT_CREATION,
  REMOVE_PRODUCT_CREATION_FILE,
} from '../../../../store/storeTypes/mutationTypes';
import ProductCreateDialogProperties from '../ProductCreateDialogProperties/index.vue';
import ProductCreateDialogTranslations from '../ProductCreateDialogTranslations/index.vue';
import ProductCreateDialogSelections from '../ProductCreateDialogSelections/index.vue';
import ProductCreateDialogFiles from '../ProductCreateDialogFiles/index.vue';

export default {
  components: {
    ProductCreateDialogProperties,
    ProductCreateDialogTranslations,
    ProductCreateDialogSelections,
    ProductCreateDialogFiles,
  },

  props: {
    value: {
      type: Boolean,
    },
  },

  data() {
    return {
      stepperStep: 1,
      loading: false,
      filesUploaded: 0,
      filesTotal: 0,
    };
  },

  computed: {
    ...mapGetters(['currentProductCreation']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    productType() {
      return this.currentProductCreation.type;
    },
  },

  watch: {
    value: {
      handler() {
        if (this.value) {
          this.$nextTick(() => {
            this.stepperStep = 1;
            this.$refs.productTranslationInput.resetTabs();
            this.$nextTick(() => {
              this.$refs.propertiesForm.resetForm();
              this.$nextTick(() => {
                this[RESET_PRODUCT_CREATION]();
              });
            });
          });
        }
      },
    },
    stepperStep: {
      handler() {
        if (this.stepperStep === 2) {
          this.$refs.productTranslationInput.resizeTabs();
        }
      },
    },
    productType: {
      handler() {
        if (this.productType === 'fotoset') {
          const movieFile = this.currentProductCreation.files.find((file) =>
            file.types.some((type) => type.name === 'movie'),
          );
          if (movieFile) {
            this[REMOVE_PRODUCT_CREATION_FILE](movieFile.internalId);
          }
        }
      },
    },
  },

  methods: {
    ...mapMutations([RESET_PRODUCT_CREATION, REMOVE_PRODUCT_CREATION_FILE]),
    ...mapActions(['createProduct']),
    async createProductAndUploadFiles() {
      this.filesTotal = this.currentProductCreation.files.length;
      this.filesUploaded = 0;
      this.loading = true;

      const data = _.pick(this.currentProductCreation, [
        'type',
        'orderId',
        'title',
        'keywords',
        'visable',
        'pinned',
        'credits',
        'length',
        'imageCount',
        'producer',
        'resolution',
        'directlyStreamableForSubscribers',
        'exclusiveForSubscribers',
        'downloadableForSubscibers',
        'translations',
      ]);
      data.categories = this.currentProductCreation.categories.map((categorie) => ({
        id: categorie.id,
      }));
      data.models = this.currentProductCreation.models.map((model) => ({
        id: model.id,
      }));

      const realFiles = [];
      data.files = this.currentProductCreation.files.map((file) => {
        realFiles.push(file.realFile);
        const dataFile = {
          types: file.types,
          mimeType: file.mimeType,
          fileSize: file.fileSize,
          name: file.name,
        };
        if (file.types.some((type) => type.name === 'movie')) {
          dataFile.trailerTimecodeStart = file.trailerTimecodeStart;
          dataFile.trailerTimecodeEnd = file.trailerTimecodeEnd;
          dataFile.bitrate = file.bitrate;
        }
        return dataFile;
      });

      const newProduct = await this.createProduct({ data });

      await Promise.all(
        newProduct.files.map(async (file, i) => {
          if (file.status === 'uploading') {
            const formData = new FormData();
            Object.keys(file.uploadFrom.fields).forEach((key) => {
              formData.append(key, file.uploadFrom.fields[key]);
            });

            formData.append('file', realFiles[i]);
            await this.$axios.post(file.uploadFrom.url, formData);
            this.filesUploaded += 1;
          }
        }),
      );

      this.loading = false;
      this.$emit('input', false);
      this.$router.push(`products/${newProduct.id}`);
    },
  },
};
</script>
