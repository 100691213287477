import { usersApi } from '@/api';
import type { Module } from 'vuex';
import type { TRootStore } from '@/store';
import type { IUser } from '@/types/Auth';
import type { IFindParams } from '@/types/Api';
import type { Params } from '@feathersjs/feathers';
import {
  SET_USERS,
  RESET_USERS,
  OPEN_USER,
  SET_USER,
  REMOVE_USER,
} from './storeTypes/mutationTypes';

export interface IUsersStoreState {
  users: IUser[];
  loading: boolean;
  page: number;
  total: number;
  selectedUser: number | null;
  limit: number;
}

type TUsersStore = Module<IUsersStoreState, TRootStore>;

const UsersStore: TUsersStore = {
  state: () => ({
    loading: false,
    users: [],
    page: 0,
    total: 0,
    selectedUser: null,
    limit: 10,
  }),

  getters: {
    allUsers: (state) => state.users,
    selectedUser: (state) => state.users.find((user) => user.id === state.selectedUser),
    usersLoading: (state) => state.loading,
    usersTotal: (state) => state.total,
  },

  mutations: {
    [SET_USERS](state, { data, refresh }: { data: IUser[]; refresh: boolean }) {
      state.users = [...(refresh ? [] : state.users), ...data];
    },
    [RESET_USERS](state) {
      state.page = 0;
      state.users = [];
    },
    [OPEN_USER](state, id: number | null) {
      state.selectedUser = id;
    },
    [SET_USER](state, usersDetail: IUser) {
      const index = state.users.findIndex((user) => usersDetail.id === user.id);
      if (index !== -1) {
        state.users[index] = usersDetail;
      } else {
        state.users.push(usersDetail);
      }
      state.users = [...state.users];
    },
    [REMOVE_USER](state, id: number) {
      const index = state.users.findIndex((user) => id === user.id);
      if (index !== -1) {
        state.users.splice(index, 1);
      }
    },
  },

  actions: {
    async findUsers({ commit, state }, params: IFindParams) {
      state.loading = true;
      try {
        const { data, total } = await usersApi.findUsers(params);
        commit(SET_USERS, { data, refresh: params.refresh });
        state.total = total;
      } finally {
        state.loading = false;
      }
    },

    async getUser({ commit, state }, { id, params }: { id: number; params?: Params }) {
      state.loading = true;
      try {
        const user = await usersApi.getUser(id, params);
        commit(SET_USER, user);
      } finally {
        state.loading = false;
      }
    },

    async patchUser(
      { commit, state },
      { id, data, params }: { id: number; data: any; params?: Params },
    ) {
      state.loading = true;
      try {
        const user = await usersApi.patchUser(id, data, params);
        commit(SET_USER, user);
      } finally {
        state.loading = false;
      }
    },

    async removeUser(
      { commit, state },
      { id, params }: { id: number; data: any; params?: Params },
    ) {
      state.loading = true;
      try {
        await usersApi.removeUser(id, params);
        commit(OPEN_USER, null);
        commit(REMOVE_USER, id);
      } finally {
        state.loading = false;
      }
    },
  },
};

export default UsersStore;
