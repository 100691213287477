import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import VueI18n from 'vue-i18n';
import PortalVue from 'portal-vue';
import VueCarousel from 'vue-carousel';
import axios, { AxiosStatic } from 'axios';
import moment from 'moment';
import App from './App.vue';
// import './registerServiceWorker';
import router from './router';
import store from './store';
import './reusableui/styles/index.scss';
// @ts-ignore
import de from '../../locales/de/de.json';
import en from '../../locales/en/en.json';
import layouts from './ui/layouts';
import components from './ui/components/shared';
import ReusableUi from './reusableui/config';

Vue.config.productionTip = false;

Vue.filter('formatMoney', (amount: number) => {
  return `${(amount / 100).toFixed(2)} €`;
});

Vue.filter('formatDate', (value: string) => {
  return moment(String(value)).format('HH:mm - DD.MM.YYYY');
});

Vue.use(VueCompositionAPI);
Vue.use(layouts);
Vue.use(components);
Vue.use(ReusableUi);
Vue.use(VueI18n);
Vue.use(PortalVue);
Vue.use(VueCarousel);

Vue.prototype.$moment = moment;

Vue.prototype.$axios = axios;
declare module 'vue/types/vue' {
  interface Vue {
    $axios: AxiosStatic;
  }
}

const i18n = new VueI18n({
  // something vue-i18n options here ...
  locale: localStorage.getItem('pm-locale') || 'en', // set locale
  fallbackLocale: 'de', // set fallback locale
  messages: {
    de,
    en,
  },
});

export { App, router, i18n, store };
