import type { Params } from '@feathersjs/feathers';
import type { TFindResponse, TGetResponse } from '@/types/Api';
import type { IUser } from '@/types/Auth';
import type { ISubscription } from '@/types/Subscription';
import { client } from '../client';

const service = client.service('users');

const findUsers = (params?: Params): TFindResponse<IUser> => {
  return service.find({
    ...(params || {}),
    query: {
      $limit: 30,
      ...(params?.query || {}),
    },
  });
};

const getUser = (id: number, params?: Params): TGetResponse<IUser> => {
  return service.get(id, params);
};

const patchUser = (id: number, data: any, params?: Params): TGetResponse<IUser> => {
  return service.patch(id, data, params);
};

const createUser = (data: IUser, params?: Params): TGetResponse<IUser> => {
  return service.create(data, params);
};

const removeUser = (id: number, params?: Params): TGetResponse<IUser> => {
  return service.remove(id, params);
};

const assignSubscriptionToUser = (userId: number, subscriptionPlanId: number): ISubscription => {
  return client.service(`users/${userId}/subscriptions`).create({
    subscriptionPlanId,
  });
};

const cancelUserSubscription = (userId: number) => {
  return client.service(`users/${userId}/subscriptions/cancel`).create({});
};

export const usersApi = {
  findUsers,
  getUser,
  patchUser,
  createUser,
  removeUser,
  assignSubscriptionToUser,
  cancelUserSubscription,
};
