<template>
  <v-dialog
    v-if="currentModel"
    v-model="show"
    width="600"
    scrollable
  >
    <v-card :loading="loading">
      <v-card-title>Model bearbeiten</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="model.name"
            :counter="50"
            :rules="[v => !!v || 'Name ist erforderlich']"
            label="Name"
            required
          />

          <v-text-field
            v-model="model.preferredWeapon"
            :counter="100"
            label="Bevorzugte Waffe"
          />

          <v-text-field
            v-model="model.activityStatus"
            :counter="100"
            label="Aktivität"
          />

          <v-select
            v-model="model.toplessActing"
            :items="selectItems"
            label="Topless Acting"
          />

          <v-select
            v-model="model.availableForCustoms"
            :items="selectItems"
            label="Verfübar für Customs"
          />

          <v-checkbox v-model="model.active" label="Model aktiv" />

          <v-img
            v-if="model.picture && !overwriteImage"
            :src="model.picture"
            class="mb-4 mx-auto"
            width="200"
            height="303"
          >
            <div 
              class="
                  w-full 
                  h-full 
                  cursor-pointer 
                  flex 
                  items-center 
                  justify-center 
                  bg-white 
                  bg-opacity-50 
                  transition-opacity 
                  duration-150
                  opacity-0
                  hover:opacity-100

                "
              @click="handleImageOverwrite"
            >
              <div>
                <v-icon>edit</v-icon> Bild ändern
              </div>
            </div>
          </v-img>
         

          <v-file-input 
            v-if="overwriteImage"
            ref="newImageInput"
            label="Foto" 
            accept="image/png, image/jpeg, image/gif" 
            :rules="fileRules" 
            @change="fileInput"
          />
          
          <v-img 
            v-if="overwriteImage && selectedFileURL" 
            :src="selectedFileURL"
            width="200"
            height="303"
            class="mb-4 mx-auto"
          />
        </v-form>
      </v-card-text>
      
      <v-card-actions>
        <v-btn
          text
          color="primary"
          :disabled="!valid"
          @click="validate"
        >
          Speichern
        </v-btn>
      </v-card-actions>
      <template slot="progress">
        <v-progress-linear
          absolute
          indeterminate 
          bottom
        />
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
    },
  },

  data() {
    return {
      loading: false,
      valid: true,
      model: {},
      selectItems: [
        { text: 'Nein', value: 0 },
        { text: 'Auf Anfrage', value: 1 },
        { text: 'Ja', value: 2 },
      ],
      fileRules: [
        (v) => !v || v.size < 5000000 || 'Datei muss kleiner als 5 MB sein',
        (v) => !!v || 'Datei ist erforderlich',
      ],
      overwriteImage: false,
      selectedFileURL: '',
      selectedFile: {},
    };
  },

  computed: {
    ...mapGetters(['currentModel']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value: {
      handler() {
        if (this.value) {
          this.model = { ...this.currentModel };
        } else {
          this.$refs.form.reset();
          this.model = {};
          this.overwriteImage = false;
          this.selectedFileURL = '';
          this.selectedFile = {};
        }
      },
    },
  },

  methods: {
    ...mapActions(['patchModel', 'getModel']),

    async validate() {
      try {
        const valid = this.$refs.form.validate();
        if (valid) {
          this.loading = true;
          const model = await this.patchModel({
            id: this.model.id,
            data: {
              name: this.model.name,
              preferredWeapon: this.model.preferredWeapon,
              toplessActing: this.model.toplessActing,
              availableForCustoms: this.model.availableForCustoms,
              active: this.model.active,
              activityStatus: this.model.activityStatus,
              pictureMimeType: this.overwriteImage ? this.selectedFile.type : undefined,
              updatePicture: this.overwriteImage ? true : undefined,
            },
          });

          if (this.overwriteImage) {
            const formData = new FormData();
            Object.keys(model.uploadFrom.fields).forEach((key) => {
              formData.append(key, model.uploadFrom.fields[key]);
            });

            formData.append('file', this.selectedFile);

            await this.$axios.post(model.uploadFrom.url, formData);

            await new Promise((res) => setTimeout(() => res(), 750));
            await this.getModel({ id: model.id });
          }

          this.loading = false;
          this.$emit('patched');
          this.$emit('input', false);
          this.$refs.form.reset();
        }
      } catch (err) {
        console.log(err);
      }
    },
    fileInput(file) {
      if (file === null) {
        this.selectedFileURL = '';
        this.selectedFile = {};
      } else {
        this.selectedFileURL = '';
        this.$nextTick(() => {
          this.selectedFileURL = URL.createObjectURL(file);
          this.model.pictureMimeType = file.type;
          this.selectedFile = file;
        });
      }
    },

    handleImageOverwrite() {
      this.overwriteImage = true;
      this.$nextTick(() => {
        this.$refs.newImageInput.$el.querySelector('input').click();
      });
    },
  },
};
</script>
