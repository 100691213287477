import { ageVerificationApi } from '@/api';
import type { Module } from 'vuex';
import type { TRootStore } from '@/store';
import type { IAgeVerification } from '@/types/AgeVerifications';
import type { IFindParams } from '@/types/Api';
import type { Params } from '@feathersjs/feathers';
import {
  SET_AGE_VERIFICATIONS,
  OPEN_AGE_VERIFICATION,
  SET_AGE_VERIFICATION,
} from './storeTypes/mutationTypes';

export interface IAgeVerificationStoreState {
  ageVerifications: IAgeVerification[];
  loading: boolean;
  page: number;
  total: number;
  selectedAgeVerification: number | null;
  limit: number;
}

type TAgeVerifictionStore = Module<IAgeVerificationStoreState, TRootStore>;

const AgeVerificationsStore: TAgeVerifictionStore = {
  state: () => ({
    loading: false,
    ageVerifications: [],
    page: 0,
    total: 0,
    selectedAgeVerification: null,
    limit: 10,
  }),

  getters: {
    allAgeVerifications: (state) => state.ageVerifications,
    currentAgeVerification: (state) =>
      state.ageVerifications.find(
        (ageVerification) => ageVerification.id === state.selectedAgeVerification,
      ) || null,
    ageVerificationsLoading: (state) => state.loading,
    ageVerificationsTotal: (state) => state.total,
  },

  mutations: {
    [SET_AGE_VERIFICATIONS](
      state,
      { data, refresh }: { data: IAgeVerification[]; refresh: boolean },
    ) {
      state.ageVerifications = [...(refresh ? [] : state.ageVerifications), ...data];
    },
    [OPEN_AGE_VERIFICATION](state, id: number) {
      state.selectedAgeVerification = id;
    },
    [SET_AGE_VERIFICATION](state, ageVerificationDetail: IAgeVerification) {
      const index = state.ageVerifications.findIndex(
        (ageVerification) => ageVerificationDetail.id === ageVerification.id,
      );
      if (index !== -1) {
        state.ageVerifications[index] = ageVerificationDetail;
      } else {
        state.ageVerifications.push(ageVerificationDetail);
      }
      state.ageVerifications = [...state.ageVerifications];
    },
  },

  actions: {
    async findAgeVerifications({ commit, state }, params: IFindParams) {
      state.loading = true;
      const { data, total } = await ageVerificationApi.findAgeVerifications(params);
      commit(SET_AGE_VERIFICATIONS, { data, refresh: params.refresh });
      state.total = total;

      state.loading = false;
    },

    async getAgeVerification({ commit, state }, { id, params }: { id: number; params?: Params }) {
      state.loading = true;
      const ageVerification = await ageVerificationApi.getAgeVerification(id, params);
      commit(SET_AGE_VERIFICATION, ageVerification);
      state.loading = false;
    },

    async acceptAgeVerification(
      { commit, state },
      { id, params }: { id: number; params?: Params },
    ) {
      state.loading = true;
      const ageVerification = await ageVerificationApi.patchAgeVerification(
        id,
        {
          status: 'confirmed',
          image: null,
        },
        params,
      );
      commit(SET_AGE_VERIFICATION, ageVerification);
      state.loading = false;
    },

    async rejectAgeVerification(
      { commit, state },
      { id, rejectionReason, params }: { id: number; rejectionReason: string; params?: Params },
    ) {
      state.loading = true;
      const ageVerification = await ageVerificationApi.patchAgeVerification(
        id,
        {
          status: 'rejected',
          image: null,
          rejectionReason,
        },
        params,
      );
      commit(SET_AGE_VERIFICATION, ageVerification);
      state.loading = false;
    },
  },
};

export default AgeVerificationsStore;
