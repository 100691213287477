






























import { defineComponent, PropType } from '@vue/composition-api';
import icons from './icons';

export default defineComponent({
  props: {
    name: {
      type: String as PropType<string>,
      required: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number as PropType<12 | 16 | 20 | 24 | 32 | 36 | 40>,
      default: null,
    },
    customViewBox: {
      type: String,
      required: false,
    },
    strokeWidth: {
      type: String,
      required: false,
    },
  },

  computed: {
    paths(): string[] {
      if (this.outline) {
        // @ts-ignore
        return icons[`${this.name}-outline`];
      }
      // @ts-ignore
      return icons[this.name];
    },
    sizeClass(): Record<string, boolean> {
      return {
        'w-3 h-3': this.size === 12,
        'w-4 h-4': this.size === 16,
        'w-5 h-5': this.size === 20,
        'w-6 h-6': this.size === 24,
        'w-8 h-8': this.size === 32,
        'w-9 h-9': this.size === 36,
        'w-10 h-10': this.size === 40,
      };
    },
    viewBox(): string {
      if (this.customViewBox) return this.customViewBox;
      return this.outline ? '0 0 24 24' : '0 0 20 20';
    },
  },
});
