var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{attrs:{"to":_vm.to,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isExactActive = ref.isExactActive;
var navigate = ref.navigate;
return [_c('a',{staticClass:"group focus:outline-none",attrs:{"href":href}},[_c('span',{staticClass:"relative flex h-10 items-center uppercase transition duration-150 ease-in-out\n      font-bold text-xs tracking-wider px-2 border-b-3 group-focus:text-akzent",class:{
        'border-transparent hover:text-akzent': !isExactActive,
        'border-akzent': isExactActive,
      },on:{"click":navigate}},[_c('span',{staticClass:"block"},[_vm._t("default")],2)])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }