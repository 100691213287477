<template>
  <v-dialog
    v-model="show"
    max-width="500"
  >
    <v-card :loading="loading">
      <v-card-title>
        <span class="headline">
          {{ action === 'create' ? 'Bundle erstellen' : 'Bundle bearbeiten' }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="fromIsValid" lazy-validation>                  
          <v-slider
            v-model="bundle.discount"
            max="100"
            min="1"
            label="Discount"
            class="pt-6"
          >
            <template v-slot:append>
              <div class="w-8 text-center">{{ bundle.discount }}%</div>
            </template>
          </v-slider>
          Produkte
          <v-chip-group
            column
          >
            <v-chip
              v-for="(product, i) in bundle.bundleProducts"
              :key="product.id"
              close
              @click:close="removeProductFromEditingBundle(i)"
            > 
              {{ product.title }} 
            </v-chip>
            <v-chip @click="openProductsSelectionDialog = true">
              <v-icon>add</v-icon>
            </v-chip>
          </v-chip-group>

          <SelectionDialog 
            v-model="openProductsSelectionDialog"
            :items="allProducts"
            :filter-items="bundle.bundleProducts"
            :item-text="'title'"
            :item-value="'id'"
            :loading="productsLoading"
            :all-items-loaded="allProductsLoaded"
            return-object
            @selectionCompleted="addProductsToEditingBundle"
            @load-more="lazyLoadProducts"
            @search="updateProductsSearch"
          >
            <template v-slot:title>
              Produkte hinzufügen
            </template>
            <template v-slot:submit-button>
              Hinzufügen
            </template>
            <template v-slot:no-options>
              Keine weiteren Produkte verfügbar.
            </template>
          </SelectionDialog>

          <div v-if="minProductsError" class="error--text">
            Es müssen min. 2 Produkte angeben werden.
          </div>


          Kategorien
          <v-chip-group
            column
          >
            <v-chip
              v-for="(categorie, i) in bundle.categories"
              :key="categorie.id"
              close
              @click:close="removeCategorieFromEditingBundle(i)"
            > 
              {{ categorie.name }} 
            </v-chip>
            <v-chip @click="openCategoriesSelectionDialog = true">
              <v-icon>add</v-icon>
            </v-chip>
          </v-chip-group>

          <SelectionDialog 
            v-model="openCategoriesSelectionDialog"
            :items="allCategories"
            :filter-items="bundle.categories"
            :item-text="'name'"
            :item-value="'id'"
            :loading="categoriesLoading"
            :all-items-loaded="allCategoriesLoaded"
            return-object
            @selectionCompleted="addCategoriesToEditingBundle"
            @load-more="lazyLoadCategories"
            @search="updateCategoriesSearch"
          >
            <template v-slot:title>
              Produkte hinzufügen
            </template>
            <template v-slot:submit-button>
              Hinzufügen
            </template>
            <template v-slot:no-options>
              Keine weiteren Produkte verfügbar.
            </template>
          </SelectionDialog>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="bundle.title"
                :counter="256"
                :rules="[v => !!v || 'Titel ist erforderlich']"
                label="Titel*"
                required
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="bundle.keywords" 
                label="Keywords"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-checkbox 
                v-model="bundle.visable" 
                label="Sichtbar"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-checkbox 
                v-model="bundle.pinned" 
                label="Pinned"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          text
          color="primary"
          @click="save"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SelectionDialog from '../SelectionDialog/index.vue';

export default {
  components: {
    SelectionDialog,
  },
  props: {
    value: {
      type: Boolean,
    },
    action: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,
      fromIsValid: true,
      bundle: {},
      openProductsSelectionDialog: false,
      openCategoriesSelectionDialog: false,
      productsQuery: {},
      categoriesQuery: {},
      minProductsError: false,
    };
  },

  computed: {
    ...mapGetters([
      'currentBundleProduct',
      'allProducts',
      'productsLoading',
      'allProductsLoaded',
      'allCategories',
      'categoriesLoading',
      'allCategoriesLoaded',
    ]),

    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value: {
      handler() {
        if (this.value) {
          this.$nextTick(() => {
            this.$refs.form.reset();

            this.$nextTick(() => {
              if (this.action === 'create') {
                this.bundle = {
                  title: '',
                  discount: 1,
                  bundleProducts: [],
                  categories: [],
                  keywords: '',
                  pinned: false,
                  visable: true,
                };
              } else {
                this.bundle = JSON.parse(JSON.stringify(this.currentBundleProduct));
              }
            });
          });
        }
      },
    },
    openProductsSelectionDialog(val) {
      if (val) {
        this.productsQuery = {
          $limit: 10,
          title: {
            $like: '%%',
          },
          type: {
            $ne: 'bundle',
          },
        };
        this.findProducts({
          query: this.productsQuery,
          refresh: true,
        });
      }
    },
    openCategoriesSelectionDialog(val) {
      if (val) {
        this.categoriesQuery = {
          $limit: 10,
          name: {
            $like: '%%',
          },
        };
        this.findCategories({
          query: this.categoriesQuery,
          refresh: true,
        });
      }
    },
    bundle: {
      handler() {
        if (this.minProductsError && this.bundle.bundleProducts.length > 1) {
          this.minProductsError = false;
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions([
      'findProducts',
      'createProduct',
      'patchProduct',
      'removeProductFromBundle',
      'addProductToBundle',
      'addCategorieToProduct',
      'removeCategorieFromProduct',
      'findCategories',
    ]),

    lazyLoadProducts() {
      this.findProducts({
        query: this.productsQuery,
        refresh: false,
      });
    },

    updateProductsSearch(value) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.productsQuery.title.$like = `%${value}%`;

        this.findProducts({
          query: this.productsQuery,
          refresh: true,
        });
      }, 275);
    },

    addProductsToEditingBundle(products) {
      this.bundle.bundleProducts = this.bundle.bundleProducts.concat(products);
    },

    removeProductFromEditingBundle(index) {
      this.bundle.bundleProducts.splice(index, 1);
    },

    lazyLoadCategories() {
      this.findCategories({
        query: this.categoriesQuery,
        refresh: false,
      });
    },

    updateCategoriesSearch(value) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.categoriesQuery.name.$like = `%${value}%`;

        this.findCategories({
          query: this.categoriesQuery,
          refresh: true,
        });
      }, 275);
    },

    addCategoriesToEditingBundle(categories) {
      this.bundle.categories = this.bundle.categories.concat(categories);
    },

    removeCategorieFromEditingBundle(index) {
      this.bundle.categories.splice(index, 1);
    },

    async save() {
      const valid = this.$refs.form.validate();
      if (valid) {
        if (this.bundle.bundleProducts.length < 2) {
          this.minProductsError = true;
          return;
        }

        this.loading = true;
        if (this.action === 'create') {
          const data = {
            type: 'bundle',
            discount: this.bundle.discount,
            bundleProducts: this.bundle.bundleProducts.map((product) => ({
              id: product.id,
            })),
            categories: this.bundle.categories.map((categorie) => ({
              id: categorie.id,
            })),
            title: this.bundle.title,
            keywords: this.bundle.keywords,
            visable: this.bundle.visable,
            pinned: this.bundle.pinned,
          };
          await this.createProduct({ data });
        } else {
          const productDeletions = this.currentBundleProduct.bundleProducts.filter(
            (currentBundleProduct) =>
              !this.bundle.bundleProducts.some(
                (editedBundeProduct) => currentBundleProduct.id === editedBundeProduct.id,
              ),
          );

          const productAdditions = this.bundle.bundleProducts.filter(
            (bundleProduct) =>
              !this.currentBundleProduct.bundleProducts.some(
                (currentBundleProduct) => currentBundleProduct.id === bundleProduct.id,
              ),
          );

          const categorieDeletions = this.currentBundleProduct.categories.filter(
            (currentBundleCategorie) =>
              !this.bundle.categories.some(
                (editedBundeCategorie) => currentBundleCategorie.id === editedBundeCategorie.id,
              ),
          );

          const categorieAdditions = this.bundle.categories.filter(
            (bundleCategorie) =>
              !this.currentBundleProduct.categories.some(
                (currentBundleCategorie) => currentBundleCategorie.id === bundleCategorie.id,
              ),
          );

          const data = {
            discount: this.bundle.discount,
            title: this.bundle.title,
            keywords: this.bundle.keywords,
            visable: this.bundle.visable,
            pinned: this.bundle.pinned,
          };

          await Promise.all([
            ...productAdditions.map((product) =>
              this.addProductToBundle({
                bundleId: this.currentBundleProduct.id,
                productId: product.id,
              }),
            ),
            ...productDeletions.map((product) =>
              this.removeProductFromBundle({
                bundleId: this.currentBundleProduct.id,
                productId: product.id,
              }),
            ),
            ...categorieAdditions.map((categorie) =>
              this.addCategorieToProduct({
                productId: this.currentBundleProduct.id,
                categorieId: categorie.id,
              }),
            ),
            ...categorieDeletions.map((categorie) =>
              this.removeCategorieFromProduct({
                productId: this.currentBundleProduct.id,
                categorieId: categorie.id,
              }),
            ),
            this.patchProduct({ id: this.currentBundleProduct.id, data }),
          ]);
        }

        this.loading = false;
        this.$emit('save');
        this.$emit('input', false);
      }
    },
  },
};
</script>
