import { modelsApi } from '@/api';
import type { Module } from 'vuex';
import type { TRootStore } from '@/store';
import type { IModel } from '@/types/Model';
import type { IFindParams } from '@/types/Api';
import type { Params } from '@feathersjs/feathers';
import {
  SET_MODELS,
  OPEN_MODEL,
  SET_MODEL,
  RESET_MODELS,
  REMOVE_MODEL,
} from './storeTypes/mutationTypes';

export interface IModelsStoreState {
  models: IModel[];
  loading: boolean;
  page: number;
  total: number;
  endOfList: boolean;
  selectedModel: number | null;
  limit: number;
}

type TModelsStore = Module<IModelsStoreState, TRootStore>;

const LIMIT = {
  xs: 6,
  sm: 8,
  md: 10,
  lg: 16,
  xl: 20,
  '2xl': 20,
};

const ModelsStore: TModelsStore = {
  state: () => ({
    loading: false,
    models: [],
    page: 0,
    total: 0,
    endOfList: false,
    selectedModel: null,
    limit: 25,
  }),

  getters: {
    allModels: (state) => state.models,
    allModelsLoaded: (state) => state.endOfList,
    currentModel: (state) => state.models.find((model) => model.id === state.selectedModel) || null,
    modelsLoading: (state) => state.loading,
    modelsTotal: (state) => state.total,
    // @ts-ignore
    modelsCurrentLimit: (state, getters) => LIMIT[getters.currentBreakpoint],
  },

  mutations: {
    [SET_MODELS](state, { data }: { data: IModel[] }) {
      state.models = [...state.models, ...data];
      if (state.total > state.page * state.limit) {
        state.endOfList = false;
      } else {
        state.endOfList = true;
      }
    },
    [RESET_MODELS](state) {
      state.page = 0;
      state.models = [];
    },
    [OPEN_MODEL](state, id: number) {
      state.selectedModel = id;
    },
    [SET_MODEL](state, modelDetail: IModel) {
      const index = state.models.findIndex((model) => modelDetail.id === model.id);
      if (index !== -1) {
        state.models[index] = modelDetail;
      } else {
        state.models.push(modelDetail);
      }
      state.models = [...state.models];
    },
    [REMOVE_MODEL](state, modelDetail: IModel) {
      const i = state.models.findIndex((model) => model.id === modelDetail.id);
      if (i !== -1) {
        state.models.splice(i, 1);
      }
    },
  },

  actions: {
    async findModels({ commit, state, getters }, params: IFindParams) {
      state.loading = true;
      const { refresh, query, ...restParams } = params || {};
      if (refresh) {
        commit(RESET_MODELS);
      }
      // @ts-ignore
      const limit: number = params?.query?.$limit || LIMIT[getters.currentBreakpoint];
      if (state.total > limit * state.page || refresh) {
        const { data, total } = await modelsApi.findModels({
          query: {
            $limit: limit,
            $skip: !params?.refresh ? limit * state.page : 0,
            ...(query || {}),
          },
          ...(restParams || {}),
        });
        state.limit = limit;
        state.total = total;
        state.page += 1;
        commit(SET_MODELS, { data, refresh: params?.refresh || false });
      }
      state.loading = false;
    },

    async getModel({ commit, state }, { id, params }: { id: number; params?: Params }) {
      state.loading = true;
      const model = await modelsApi.getModel(id, params);
      commit(SET_MODEL, model);
      state.loading = false;
    },

    async createModel({ commit, state }, { data, params }: { data: IModel; params?: Params }) {
      state.loading = true;
      const model = await modelsApi.createModel(data, params);
      commit(SET_MODEL, model);
      state.loading = false;
      return model;
    },

    async patchModel(
      { commit, state },
      { id, data, params }: { id: number; data: any; params?: Params },
    ) {
      state.loading = true;
      const model = await modelsApi.patchModel(id, data, params);
      commit(SET_MODEL, model);
      state.loading = false;
      return model;
    },

    async removeModel({ commit, state }, { id, params }: { id: number; params?: Params }) {
      state.loading = true;
      const model = await modelsApi.removeModel(id, params);
      commit(REMOVE_MODEL, model);
      state.loading = false;
      return model;
    },
  },
};

export default ModelsStore;
