<template>
  <div>
    <div class="grid grid-cols-4 gap-1 lg:gap-3 my-1 lg:mb-3 lg:mt-0">
      <div 
        v-for="(stepperStep, i) in stepperSteps"
        :key="`step-${i}`"
        class="
          px-3 py-2 flex items-center transition duration-150 ease-in-out border 
          border-transparent
        "
        :class="{
          'bg-akzent text-background': i === value,
          'bg-interface text-text': i !== value,
          'col-span-2': stepperStep.spanCol,     
          'cursor-pointer': stepperStep.clickable,
        }"
        @click="stepperStep.clickable ? $emit('input', i) : null"
      >
        <div 
          class="text-xs uppercase font-bold tracking-wide truncate leading-5"
          :class="{ 'opacity-50': i !== value }"
        >
          {{ $t(stepperStep.title) }}
        </div>
      </div>
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    stepperSteps: {
      type: Array,
      required: true,
    },
  },
};
</script>
