<template>
  <div v-if="currentUser">
    <NavigationDrawer :show="isDrawerOpen" @close="toggleDrawer" />
    <NavigationHeader>
      Fantasy-Action Movie Production
      <template v-slot:bar>
        <NavigationBarItem to="/products">
          {{ $t('allProducts') }}
        </NavigationBarItem>
        <NavigationBarItem to="/news">
          {{ $t('news') }}
        </NavigationBarItem>
        <NavigationBarItem to="/content/about-us">
          {{ $t('aboutUs') }}
        </NavigationBarItem>
        <NavigationBarItem to="/models">
          {{ $tc('model', 2) }}
        </NavigationBarItem>
        <NavigationBarItem to="/content/faq">
          {{ $tc('faq', 2) }}
        </NavigationBarItem>
        <NavigationBarItem to="/content/contact">
          {{ $t('contact') }}
        </NavigationBarItem>
      </template>
    </NavigationHeader>
    <Container inner-class="lg:pt-3 lg:pb-6 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-3">
      <NavigationSidebar>
        <NavigationSidebarItem akzent>
          <span class="text-sm">
            {{ currentUser.firstName }} {{ currentUser.lastName }}
          </span>
          <template v-slot:right>
            <Icon name="credits" :size="12" class="mr-px" />
            <span class="text-xs font-normal capitalize ml-px tracking-normal">
              {{ totalCredits }} {{ $tc('credits') }}
            </span>
          </template>
        </NavigationSidebarItem>
        <NavigationSidebarItem akzent custom>
          <IconButton
            v-if="currentUser.role !== 'verifiedUser' && currentUser.role !== 'registeredUser'"
            to="/products/my"
            icon="play-circle"
            show-label
            outline
            circle
          >
            {{ $t('myProducts') }}
          </IconButton>
          <div 
            v-if="currentUser.role === 'verifiedUser' || currentUser.role === 'registeredUser'"
            class="-mx-3 -mt-2 -mb-px" 
            :class="{
              'bg-red-700': currentUser.role === 'registeredUser',
              'bg-yellow-500': currentUser.role === 'verifiedUser'
            }"
          >
            <IconButton
              :to="currentUser.role === 'verifiedUser' ? '/verify-age': '/confirm-email'"
              show-label
              circle
              custom-svg
              class="px-3 py-2"
            >
              <template v-slot:customSvg>
                <img :src="activateAccountSvg" class="w-5 h-5 -ml-px -mt-px">
              </template>

              {{ $t('activateAccount') }} - {{ $t('stepXfromY', { 
                x: currentUser.role === 'registeredUser' ? 1 : 2, 
                y: 2 
              }) }}
            </IconButton>
          </div>
          
          <IconButton
            v-if="currentUser.role !== 'verifiedUser' && currentUser.role !== 'registeredUser'"
            to="/products/favorites"
            :size="20"
            show-label
            circle
            custom-svg
          >
            <template v-slot:customSvg>
              <img :src="heartSvg">
            </template>
            {{ $t('favorites') }}
          </IconButton>
          <IconButton
            v-if="currentUser.role !== 'verifiedUser' && currentUser.role !== 'registeredUser'"
            to="/add-credits"
            icon="credits"
            show-label
            outline
            circle
          >
            {{ $t('addCredits') }}
          </IconButton>
          <IconButton
            v-if="currentUser.role !== 'verifiedUser' && currentUser.role !== 'registeredUser'"
            to="/subscriptions"
            show-label
            circle
            custom-svg
          >
            <template v-slot:customSvg>
              <img :src="subscriptionSvg">
            </template>
            
            {{ $t('subscription') }}
          </IconButton>
          <IconButton
            to="/my-account"
            show-label
            circle
            custom-svg
          >
            <template v-slot:customSvg>
              <img :src="settingsSvg">
            </template>
            {{ $t('myAccount') }}
          </IconButton>
          
          <IconButton
            show-label
            circle
            custom-svg
            @click="onLogout"
          >
            <template v-slot:customSvg>
              <img class="-mr-1" :src="logoutSvg">
            </template>
            {{ $t('logout') }}
          </IconButton>
        </NavigationSidebarItem>
        <div class="lg:hidden">
          <NavigationSidebarItem to="/products">
            {{ $t('allProducts') }}
          </NavigationSidebarItem>
          <NavigationSidebarItem to="/news">
            {{ $t('news') }}
          </NavigationSidebarItem>
          <NavigationSidebarItem to="/content/about-us">
            {{ $t('aboutUs') }}
          </NavigationSidebarItem>
          <NavigationSidebarItem to="/models">
            {{ $tc('model', 2) }}
          </NavigationSidebarItem>
          <NavigationSidebarItem to="/content/contact">
            {{ $t('contact') }}
          </NavigationSidebarItem>
          <NavigationSidebarItem to="/content/faq">
            {{ $t('faq') }}
          </NavigationSidebarItem>
          <NavigationSidebarItem>
            {{ $t('changeLanguage') }}
            <template v-slot:right>
              <LanguageSwitch />
            </template>
          </NavigationSidebarItem>
        </div>
        <NavigationSidebarItem akzent>
          {{ $t('productCategories') }}
        </NavigationSidebarItem>
        <NavigationSidebarItem
          v-for="cat in allCategories"
          :key="cat.id"
          :to="`/products/category/${cat.id}`"
        >
          {{ cat.name }}
        </NavigationSidebarItem>
        <RecentModels />
        <SpecialOffer />
        <NavigationFooter />
      </NavigationSidebar>
      <div class="col-span-1 md:col-span-3 lg:col-span-4">
        <slot />
      </div>
    </Container>
    <AgeVerificationReminder 
      v-if="
        shouldShowLoginMessage && currentUser.role === 'verifiedUser' && !hasPendingAgeVerification
      " 
    />
    <SubscriptionRenewalReminder 
      v-if="shouldShowLoginMessage && expiredSubscription"
    />
    <NewAgbReminder 
      v-if="true && currentAgb && currentUser.agbVersion !== currentAgb.version"
      :current-agb="currentAgb"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapGetters, mapMutations } from 'vuex';
import { SET_LAST_VISITED_PAGE, TOGGLE_DRAWER } from '@/store/storeTypes/mutationTypes';
import {
  LanguageSwitch,
  SpecialOffer,
  RecentModels,
  AgeVerificationReminder,
  SubscriptionRenewalReminder,
  NewAgbReminder,
} from '@/ui/components';
import settingsSvg from '@/assets/settings.svg';
import subscriptionSvg from '@/assets/subscription.svg';
import logoutSvg from '@/assets/logout.svg';
import heartSvg from '@/assets/heart.svg';
import activateAccountSvg from '@/assets/activate-account.svg';

export default defineComponent({
  components: {
    LanguageSwitch,
    SpecialOffer,
    RecentModels,
    AgeVerificationReminder,
    SubscriptionRenewalReminder,
    NewAgbReminder,
  },

  data() {
    return {
      settingsSvg,
      subscriptionSvg,
      logoutSvg,
      heartSvg,
      activateAccountSvg,
      currentAgb: null,
    };
  },

  computed: {
    ...mapGetters([
      'currentUser',
      'totalCredits',
      'allCategories',
      'isDrawerOpen',
      'currentBreakpoint',
      'shouldShowLoginMessage',
      'hasPendingAgeVerification',
      'expiredSubscription',
    ]),
  },

  watch: {
    $route(to, from) {
      this[SET_LAST_VISITED_PAGE](from.path);
    },
  },

  async created() {
    const resp = await this.$axios(
      // eslint-disable-next-line max-len
      `${process.env.VUE_APP_DIRECTUS_URL}/items/agb?fields=*,translations.*&filter[active]=true`,
    );
    [this.currentAgb] = resp.data.data;
  },

  methods: {
    ...mapMutations([TOGGLE_DRAWER, SET_LAST_VISITED_PAGE]),
    onLogout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login');
      });
    },
  },
});
</script>
