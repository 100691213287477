import type { Module } from 'vuex';
import type { TRootStore } from '@/store';
import {
  CLEAR_SEARCH,
  TOGGLE_DRAWER,
  TOGGLE_MOBILE_SEARCH,
  SET_DEVICE,
  SET_PRODUCTS_VIEW_INFO,
  SET_MODELS_VIEW_INFO,
  SET_SHOW_LOGIN_MESSAGE,
  SET_LAST_VISITED_PAGE,
} from './storeTypes/mutationTypes';

export interface IDevice {
  vWidth: number;
  vHeight: number;
  breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | null;
  viewport: 'mobile' | 'tablet' | 'desktop' | null;
  touch: boolean;
  scrollY: number;
}

interface IViewState {
  lastFilter: string | null | undefined;
  lastIdParam: string | number | null | undefined;
  scrollY: number;
}
export interface IUiStoreState {
  searchValue: string | null;
  searchValueTags: string | null;
  searchFilter: string;
  mobileSearchOpen: boolean;
  drawerOpen: boolean;
  device: IDevice;
  productsView: IViewState;
  modelsView: IViewState;
  showLoginMessage: boolean;
  lastVisitedPage: string;
}

type TUiStore = Module<IUiStoreState, TRootStore>;

const uiStore: TUiStore = {
  state: () => ({
    searchValue: null,
    searchValueTags: null,
    searchFilter: 'title',
    mobileSearchOpen: false,
    drawerOpen: false,
    device: {
      vWidth: 0,
      vHeight: 0,
      viewport: null,
      breakpoint: null,
      touch: false,
      scrollY: 0,
    },
    productsView: {
      lastFilter: null,
      lastIdParam: null,
      scrollY: 0,
    },
    modelsView: {
      lastFilter: null,
      lastIdParam: null,
      scrollY: 0,
    },
    showLoginMessage: false,
    lastVisitedPage: '',
  }),

  getters: {
    isDrawerOpen: (state) => state.drawerOpen,
    isMobileSearchOpen: (state) => state.mobileSearchOpen,
    currentBreakpoint: (state) => state.device.breakpoint,
    lastProductsView: (state) => state.productsView,
    lastModelsView: (state) => state.modelsView,
    isMobile: (state) => ['xs', 'sm', 'md'].some((bp) => bp === state.device.breakpoint),
    shouldShowLoginMessage: (state) => state.showLoginMessage,
    lastVisitedPage: (state) => state.lastVisitedPage,
  },

  mutations: {
    [TOGGLE_DRAWER](state) {
      state.drawerOpen = !state.drawerOpen;
    },
    [TOGGLE_MOBILE_SEARCH](state) {
      state.mobileSearchOpen = !state.mobileSearchOpen;
    },
    [CLEAR_SEARCH](state) {
      state.searchValue = null;
      state.searchValueTags = null;
    },
    [SET_DEVICE](state, device: IDevice) {
      state.device = device;
      state.productsView.scrollY = device.scrollY;
      state.modelsView.scrollY = device.scrollY;
    },
    [SET_PRODUCTS_VIEW_INFO](state, productsView: IViewState) {
      state.productsView = productsView;
    },
    [SET_MODELS_VIEW_INFO](state, modelsView: IViewState) {
      state.modelsView = modelsView;
    },
    [SET_SHOW_LOGIN_MESSAGE](state, value: boolean) {
      state.showLoginMessage = value;
    },
    [SET_LAST_VISITED_PAGE](state, value: string) {
      state.lastVisitedPage = value;
    },
  },

  actions: {
    async initUserInterface({ dispatch }) {
      await Promise.all([
        dispatch('loadAllCategories', {
          query: {
            active: 1,
            $sort: {
              priority: -1,
            },
          },
          refresh: true,
        }),
        dispatch('getTotalAndSpentCredits'),
        dispatch('findRecentModels', {
          query: {
            active: 1,
          },
          refresh: true,
        }),
      ]);
      await dispatch('initSpecialOffer');
    },
  },
};

export default uiStore;
