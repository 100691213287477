
<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <SeperationBox>
      <template>
        {{ $t('news') }}
      </template>
    </SeperationBox>
    <div class="w-full -mt-4">
      <div 
        v-for="(news, i) in allNews" 
        :key="`news-${i}`" 
        class="cms-content"
      >
        <div class="news-content py-3" v-html="getTranslation(news)" />
        <div v-if="allNews.length - 1 > i" class="h-px w-full bg-text opacity-75" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SeperationBox from '../SeperationBox/index.vue';

export default {
  components: {
    SeperationBox,
  },

  data() {
    return {
      allNews: [],
    };
  },

  computed: {
    ...mapGetters(['currentUser']),
  },

  async created() {
    const resp = await this.$axios(
      // eslint-disable-next-line max-len
      `${process.env.VUE_APP_DIRECTUS_URL}/items/news?sort=-date_created&fields=*,translations.*&filter[show]=true`,
    );
    this.allNews = resp.data.data;
  },

  methods: {
    getTranslation(news) {
      let translation = news.translations.find((translation) =>
        translation.languages_id.startsWith(this.currentUser.language),
      ).content;
      if (!translation) {
        translation = news.translations[0].content;
      }
      return translation;
    },
  },
};
</script>
