<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="fixed top-0 left-0 bottom-0 right-0 z-50 overflow-y-scroll overflow-x-hidden">
    <div class="max-w-5xl min-h-screen bg-background mx-auto text-text">
      <div class="bg-interface">
        <div class="flex items-center pt-10 pb-2">
          <img :src="logoSvg" class="w-80 md:w-150 m-auto">
        </div>
        <div 
          class="
            font-bold tracking-wide lg:tracking-wider p-6 text-center
            pt-2 text-sm md:text-xl uppercase text-akzent
          "
        >
          Fantasy-Action Movie Production
        </div>
      </div>
      
      <div 
        class="bg-interface py-2 px-3 lg:px-6 flex justify-between items-center sticky top-0 z-10"
      >
        <div
          class="
            text-xs uppercase font-bold tracking-wide mr-2 
            truncate hover:opacity-50 cursor-pointer
          "
          @click="$emit('changeMode', 'login')"
        >
          {{ $t('backToRegistration') }}
        </div>
        <LanguageSwitch />
      </div>
      <div v-if="!loading" class="lg:px-6">
        <SeperationBox class="lg:mt-3">
          <template>
            {{ $t('contentPreview') }}
          </template>
        </SeperationBox>
        <VideoPlayer ref="previewSidePlayer" :options="videoOptions" />

        <SeperationBox class="lg:mt-3">
          <template>
            {{ $t('trailers') }}
          </template>
          <template v-if="moviesCount" slot="right">
            <div class="text-xs uppercase font-semibold tracking-widest">
              {{ $t('totalFilmsToDate') }}: {{ moviesCount.total }}
            </div>
          </template>
        </SeperationBox>
        <ImageSlider
          :images="trailerImages"
          :is-mobile="isMobile"
          clickable
          :icon="'play-circle'"
          @imageClicked="changeTrailer"
        />

        <SeperationBox class="lg:mt-3">
          <template>
            {{ $t('welcomeToAmazonCombat') }}
          </template>
        </SeperationBox>
        <div v-if="description" class="cms-content" v-html="getTranslation(description)" />

        <SeperationBox class="lg:mt-3">
          <template>
            {{ $t('imagePreviews') }}
          </template>
          <template v-if="fotosetsCount" slot="right">
            <div class="text-xs uppercase font-semibold tracking-widest">
              {{ $t('totalGalleriesToDate') }}: {{ fotosetsCount.total }}
            </div>
          </template>
        </SeperationBox>
        <ImageSlider
          class="lg:pb-6"
          :images="fotosetImages"
          :is-mobile="isMobile"
        />
      </div>
      <div v-else class="flex w-full justify-center py-6">
        <r-loading-circle class="w-8 h-8" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { productCountsApi } from '@/api';
import logoSvg from '@/assets/logo.svg';
import LanguageSwitch from '../LanguageSwitch/index.vue';
import SeperationBox from '../SeperationBox/index.vue';

export default {
  components: {
    LanguageSwitch,
    SeperationBox,
  },

  data() {
    return {
      loading: true,
      moviesCount: null,
      fotosetsCount: null,
      cmsPreviewSiteContent: null,
      description: null,
      trailerImages: [],
      trailers: [],
      fotosetImages: [],
      currentTrailer: {},
      logoSvg,
    };
  },

  computed: {
    ...mapGetters(['isMobile']),
    videoOptions() {
      const options = {
        autoplay: false,
        controls: true,
        disablePictureInPicture: true,
        sources: [
          {
            src: this.currentTrailer.url,
            type: this.currentTrailer.type,
          },
        ],
        controlBar: {
          liveDisplay: true,
          pictureInPictureToggle: false,
          children: ['playToggle', 'progressControl', 'volumePanel', 'fullscreenToggle'],
        },
      };

      return options;
    },
  },

  async created() {
    [this.moviesCount, this.fotosetsCount, this.cmsPreviewSiteContent] = await Promise.all([
      productCountsApi.getProductsCount('movie'),
      productCountsApi.getProductsCount('fotoset'),
      this.$axios(
        // eslint-disable-next-line max-len
        `${process.env.VUE_APP_DIRECTUS_URL}/items/preview_site?fields=*,translations.*,trailers.*,trailers.trailer.*,preview_images.*`,
      ),
    ]);

    this.description = this.cmsPreviewSiteContent.data.data.translations;
    this.trailerImages = this.cmsPreviewSiteContent.data.data.trailers.map((trailer) => ({
      id: trailer.trailer.id,
      url: `${process.env.VUE_APP_DIRECTUS_URL}/assets/${trailer.thumbnail}`,
    }));
    this.trailers = this.cmsPreviewSiteContent.data.data.trailers.map((trailer) => ({
      id: trailer.trailer.id,
      url: `${process.env.VUE_APP_DIRECTUS_URL}/assets/${trailer.trailer.id}`,
      type: trailer.trailer.type,
    }));
    [this.currentTrailer] = this.trailers;

    this.fotosetImages = this.cmsPreviewSiteContent.data.data.preview_images.map(
      (previewImage) => ({
        id: previewImage.id,
        url: `${process.env.VUE_APP_DIRECTUS_URL}/assets/${previewImage.image}`,
      }),
    );
    this.loading = false;
  },

  methods: {
    getTranslation(description) {
      let translation = description.find((translation) =>
        translation.languages_code.startsWith(this.$i18n.locale),
      ).content;
      if (!translation) {
        translation = description[0].content;
      }
      return translation;
    },

    changeTrailer(trailerImage) {
      const newTrailer = this.trailers.find((trailer) => trailer.id === trailerImage.id);
      if (newTrailer) {
        this.currentTrailer = newTrailer;
      }
      this.$refs.previewSidePlayer.player.src([
        {
          src: this.currentTrailer.url,
          type: this.currentTrailer.type,
        },
      ]);
      this.$refs.previewSidePlayer.$el.scrollIntoView({
        behavior: 'smooth',
      });
    },
  },
};
</script>
