<template>
  <v-dialog v-model="show" max-width="350">
    <v-card :loading="loading">
      <v-card-title>Wirklich?</v-card-title> 

      <v-card-text>
        Soll die Kategorie wirklich gelöschen werden? <br> 
        Alle Produktverknüpftungen dieser Kategorie werden ebenfalls gelöscht.
      </v-card-text>

      <v-card-actions>
        <v-btn
          text
          color="error"
          @click="confirmCategorieDeletion"
        >
          Löschen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters(['currentCategory']),

    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    ...mapActions(['removeCategorie']),
    async confirmCategorieDeletion() {
      this.loading = true;
      await this.removeCategorie({ id: this.currentCategory.id });
      this.$emit('delete');
      this.$emit('input', false);
      this.loading = false;
    },
  },
};
</script>
