import { productsApi, favoritesApi, purchasesApi } from '@/api';
import type { Module } from 'vuex';
import type { Params } from '@feathersjs/feathers';
import type { TRootStore } from '@/store';
import type { IProduct, IProductTranslation } from '@/types/Products';
import { ICategory } from '@/types/Categories';
import { IFavorites } from '@/types/Favorites';
import { IModel } from '@/types/Model';
import type { IFindParams } from '@/types/Api';
import { IPurchase } from '@/types/Purchases';
import {
  SET_PRODUCTS,
  SET_PRODUCT,
  RESET_PRODUCTS,
  OPEN_PRODUCT,
  SET_PRODUCT_TRANSLATION,
  REMOVE_PRODUCT_CATEGORIE,
  SET_PRODUCT_CATEGORIE,
  SET_PRODUCT_MODEL,
  REMOVE_PRODUCT_MODEL,
  SET_PRODUCT_FAVORITE,
  REMOVE_PRODUCT_FAVORITE,
  SET_PRODUCT_PURCHASE,
} from './storeTypes/mutationTypes';

export interface IProductsStoreState {
  products: IProduct[];
  loading: boolean;
  page: number;
  total: number;
  endOfList: boolean;
  selectedProduct: number | null;
  limit: number;
}

type TProductsStore = Module<IProductsStoreState, TRootStore>;

const LIMIT = {
  xs: 6,
  sm: 6,
  md: 10,
  lg: 12,
  xl: 16,
  '2xl': 16,
};

const ProductsStore: TProductsStore = {
  state: () => ({
    loading: false,
    products: [],
    page: 0,
    total: 0,
    endOfList: false,
    selectedProduct: null,
    limit: 6,
  }),

  getters: {
    allProducts: (state) => state.products,
    allProductsLoaded: (state) => state.endOfList,
    currentProduct: (state) =>
      state.products.find((product) => state.selectedProduct === product.id) || null,
    productsLoading: (state) => state.loading,
    productsTotal: (state) => state.total,
    // @ts-ignore
    productsCurrentLimit: (state, getters) => LIMIT[getters.currentBreakpoint],
  },

  mutations: {
    [SET_PRODUCTS](state, { data }: { data: IProduct[] }) {
      state.products = [...state.products, ...data];
      if (state.total > state.page * state.limit) {
        state.endOfList = false;
      } else {
        state.endOfList = true;
      }
    },
    [RESET_PRODUCTS](state) {
      state.page = 0;
      state.products = [];
    },
    [OPEN_PRODUCT](state, id: number) {
      state.selectedProduct = id;
    },
    [SET_PRODUCT](state, productDetail: IProduct) {
      const index = state.products.findIndex((product) => productDetail.id === product.id);
      if (index !== -1) {
        state.products[index] = productDetail;
      } else {
        state.products.push(productDetail);
      }
      state.products = [...state.products];
    },
    [SET_PRODUCT_TRANSLATION](
      state,
      {
        productTranslation,
        productId,
      }: { productId: number; productTranslation: IProductTranslation },
    ) {
      const index = state.products.findIndex((product) => productId === product.id);
      if (index !== -1 && state.products[index].translations) {
        const translationIndex = state.products[index].translations.findIndex(
          (translation) => translation.language === productTranslation.language,
        );

        if (index !== -1) {
          state.products[index].translations[translationIndex] = productTranslation;
        } else {
          state.products[index].translations.push(productTranslation);
        }
      }
    },
    [REMOVE_PRODUCT_CATEGORIE](
      state,
      { productId, categorieId }: { productId: number; categorieId: number },
    ) {
      const index = state.products.findIndex((product) => productId === product.id);
      if (index !== -1 && state.products[index].categories) {
        const i = state.products[index].categories.findIndex(
          (categorie) => categorie.id === categorieId,
        );
        if (i !== -1) {
          state.products[index].categories.splice(i, 1);
        }
      }
    },
    [SET_PRODUCT_CATEGORIE](
      state,
      { productId, categorie }: { productId: number; categorie: ICategory },
    ) {
      const index = state.products.findIndex((product) => productId === product.id);
      if (index !== -1 && state.products[index].categories) {
        state.products[index].categories.push(categorie);
      }
    },
    [REMOVE_PRODUCT_MODEL](state, { productId, modelId }: { productId: number; modelId: number }) {
      const index = state.products.findIndex((product) => productId === product.id);
      if (index !== -1 && state.products[index].models) {
        const i = state.products[index].models.findIndex((model) => model.id === modelId);
        if (i !== -1) {
          state.products[index].models.splice(i, 1);
        }
      }
    },
    [SET_PRODUCT_MODEL](state, { productId, model }: { productId: number; model: IModel }) {
      const index = state.products.findIndex((product) => productId === product.id);
      if (index !== -1 && state.products[index].models) {
        state.products[index].models.push(model);
      }
    },
    [SET_PRODUCT_FAVORITE](
      state,
      { productId, favorite }: { productId: number; favorite: IFavorites },
    ) {
      const index = state.products.findIndex((product) => productId === product.id);
      if (index !== -1) {
        state.products[index].favorites.push(favorite);
      }
    },
    [REMOVE_PRODUCT_FAVORITE](state, { productId, userId }: { productId: number; userId: number }) {
      const index = state.products.findIndex((product) => productId === product.id);
      if (index !== -1 && state.products[index].favorites) {
        const i = state.products[index].favorites.findIndex(
          (favorite) => favorite.userId === userId,
        );
        if (i !== -1) {
          state.products[index].favorites.splice(i, 1);
        }
      }
    },
    [SET_PRODUCT_PURCHASE](
      state,
      { productId, purchase }: { productId: number; purchase: IPurchase },
    ) {
      const index = state.products.findIndex((product) => productId === product.id);
      if (index !== -1) {
        state.products[index].purchases.push(purchase);
        state.products[index].accessStatus = 2;
      }
    },
  },

  actions: {
    listenToProductEvents({ commit, state }) {
      productsApi.removeEventListener();
      productsApi.listenToEvents((event, payload) => {
        if (event === 'patched') {
          const product = state.products.find((product) => product.id === payload.id);
          if (product) {
            const alreadyResolvedRessources = {
              categories: product.categories,
              bundles: product.bundles,
              files: product.files,
              models: product.models,
              translations: product.translations,
            };
            commit(SET_PRODUCT, {
              ...payload,
              ...alreadyResolvedRessources,
            });
          }
        }
      });
    },

    async createProduct({ commit, state }, { data, params }: { data: IProduct; params?: Params }) {
      state.loading = true;
      const newProduct = await productsApi.createProduct(data, params);
      commit(SET_PRODUCT, newProduct);
      state.loading = false;
      return newProduct;
    },

    async findProducts({ commit, state, getters }, params: IFindParams) {
      state.loading = true;
      const { refresh, query, ...restParams } = params || {};
      if (refresh) {
        commit(RESET_PRODUCTS);
      }
      // @ts-ignore
      const limit: number = params?.query?.$limit || LIMIT[getters.currentBreakpoint];
      if (state.total > limit * state.page || refresh) {
        const { data, total } = await productsApi.findProducts({
          query: {
            $limit: limit,
            $skip: !params?.refresh ? limit * state.page : 0,
            ...(query || {}),
          },
          ...(restParams || {}),
        });
        state.limit = limit;
        state.total = total;
        state.page += 1;
        commit(SET_PRODUCTS, { data, refresh: params?.refresh || false });
      }
      state.loading = false;
    },

    async getProduct({ commit, state }, { id, params }: { id: number; params?: Params }) {
      state.loading = true;
      const product = await productsApi.getProduct(id, params);
      commit(SET_PRODUCT, product);
      state.loading = false;
    },

    async patchProduct(
      { state },
      { id, data, params }: { id: number; data: any; params?: Params },
    ) {
      state.loading = true;
      const prodcut = await productsApi.patchProduct(id, data, params);
      state.loading = false;
      return prodcut;
    },

    async patchProductTranslation(
      { commit, state },
      {
        productId,
        translationId,
        data,
        params,
      }: { productId: number; translationId: number; data: any; params?: Params },
    ) {
      state.loading = true;
      const productTranslation = await productsApi.patchProductTranslation(
        translationId,
        productId,
        data,
        params,
      );
      commit(SET_PRODUCT_TRANSLATION, { productTranslation, productId });

      state.loading = false;
      return productTranslation;
    },

    async addCategorieToProduct(
      { commit, state },
      { productId, categorieId }: { productId: number; categorieId: number },
    ) {
      state.loading = true;

      const params = {
        query: { $eager: '[categorie]' },
      };

      const productCategorie = await productsApi.addCategorieToProduct(
        categorieId,
        productId,
        params,
      );
      commit(SET_PRODUCT_CATEGORIE, {
        productId,
        categorie: productCategorie.categorie,
      });

      state.loading = false;
      return productCategorie.categorie;
    },

    async removeCategorieFromProduct(
      { commit, state },
      { productId, categorieId }: { productId: number; categorieId: number },
    ) {
      state.loading = true;

      const params = {
        query: { $eager: '[categorie]' },
      };

      const productCategorie = await productsApi.removeCategorieFromProduct(
        categorieId,
        productId,
        params,
      );
      commit(REMOVE_PRODUCT_CATEGORIE, { productId, categorieId });

      state.loading = false;
      return productCategorie.categorie;
    },

    async addModelToProduct(
      { commit, state },
      { productId, modelId }: { productId: number; modelId: number },
    ) {
      state.loading = true;

      const params = {
        query: { $eager: '[model]' },
      };

      const productModel = await productsApi.addModelToProduct(modelId, productId, params);
      commit(SET_PRODUCT_MODEL, {
        productId,
        model: productModel.model,
      });

      state.loading = false;
      return productModel.model;
    },

    async removeModelFromProduct(
      { commit, state },
      { productId, modelId }: { productId: number; modelId: number },
    ) {
      state.loading = true;

      const params = {
        query: { $eager: '[model]' },
      };

      const productModel = await productsApi.removeModelFromProduct(modelId, productId, params);
      commit(REMOVE_PRODUCT_MODEL, { productId, modelId });

      state.loading = false;
      return productModel.model;
    },

    async favorProduct({ commit }, productId: number) {
      commit(SET_PRODUCT_FAVORITE, {
        productId,
        favorite: {
          productId,
          userId: this.getters.currentUser.id,
        },
      });

      await favoritesApi.createFavorite({
        productId,
      });
    },

    async unfavorProduct({ commit, getters }, productId: number) {
      commit(REMOVE_PRODUCT_FAVORITE, { productId, userId: this.getters.currentUser.id });
      await favoritesApi.removeFavorite(`${getters.currentUser.id}, ${productId}`);
    },

    async purchaseProdcut({ commit, dispatch }, productId: number) {
      const purchase = await purchasesApi.createPurchase({
        productId,
      });

      commit(SET_PRODUCT_PURCHASE, {
        productId,
        purchase,
      });

      dispatch('getTotalAndSpentCredits');
    },

    async purchaseBundle({ dispatch }, { bundleId }: { bundleId: number }) {
      console.log(bundleId);
      const purchases = await purchasesApi.createPurchase({
        productId: bundleId,
      });

      dispatch('getTotalAndSpentCredits');
      return purchases;
    },
  },
};

export default ProductsStore;
