import Vue from 'vue';
import { App, router, i18n, store } from './all';
import './scss/index.scss';
import './main.css';

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app');
