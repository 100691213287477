<template>
  <div class="relative -mx-2">
    <div
      class="
        top-0
        left-0
        absolute
        z-10 
        h-full 
        flex 
        items-center 
        pl-4 
        w-12
        md:w-16
        cursor-pointer
        dark-gradient-right-to-left
        opacity-0
        transition-opacity
        opacity
        duration-200
      "
      :class="{
        'opacity-100': hasPrevSlide,
      }"
      @click="prevSliderImage"
    >
      <Icon
        name="arrowLeft"
        outline
        class="w-6 h-12 lg:w-8 lg:h-16"
        :custom-view-box="'0 0 21 35'"
      />
    </div>

    <div
      class="
        absolute 
        top-0
        right-0
        z-10 
        h-full 
        flex 
        items-center
        justify-end
        pr-4
        w-12
        md:w-16
        cursor-pointer
        dark-gradient-left-to-right
        opacity-0
        transition-opacity
        opacity
        duration-200
      "
      :class="{
        'opacity-100': hasNextSlide,
      }"
      @click="nextSliderImage"
    >
      <Icon
        name="arrowRight"
        outline
        class="w-6 h-12 lg:w-8 lg:h-16"
        :custom-view-box="'0 0 21 35'"
      />
    </div>
    <carousel
      ref="imageSlider"
      v-model="slider.position"
      :per-page="slider.itemsPagePage"
      :pagination-enabled="false"
    >
      <slide 
        v-for="(image, i) in images" 
        :key="`slider-image-${i}`"
        class="relative"
        :class="{ 'cursor-pointer transition-opacity duration-300 hover:opacity-75': clickable }"
        @slide-click="$emit('imageClicked', image)"
      >
        <div 
          v-if="icon" 
          class="absolute top-0 left-0 w-full h-full flex items-center justify-center"
        >
          <Icon :name="icon" class="w-6 h-6 text-background bg-text rounded-full opacity-75" />
        </div>
        <CustomImage
          class="mx-2"
          :url="image.url"
          aspect-ratio="16/9"
        />
      </slide>
    </carousel>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
  },

  data() {
    return {
      slider: {
        position: 0,
        itemsPagePage: 3,
      },
    };
  },

  computed: {
    hasPrevSlide() {
      return this.slider.position !== 0;
    },
    hasNextSlide() {
      if (this.images.length > (this.slider.position + 1) * this.slider.itemsPagePage) {
        return true;
      }
      return false;
    },
  },

  watch: {
    isMobile: {
      immediate: true,
      handler(val) {
        if (val) {
          this.slider.itemsPagePage = 1;
        } else {
          this.slider.itemsPagePage = 3;
        }
      },
    },
  },

  methods: {
    nextSliderImage() {
      this.$refs.imageSlider.advancePage('forward');
    },
    prevSliderImage() {
      this.$refs.imageSlider.advancePage('backward');
    },
  },
};
</script>
