import type { Params } from '@feathersjs/feathers';
import type { TFindResponse, TGetResponse } from '@/types/Api';
import type { IPurchase, ICreatePurchase } from '@/types/Purchases';
import { client } from '../client';

const service = client.service('purchases');

const findPurchases = (params?: Params): TFindResponse<IPurchase> => {
  return service.find({
    ...(params || {}),
    query: {
      $limit: 30,
      ...(params?.query || {}),
    },
  });
};

const getPurchase = (id: string, params?: Params): TGetResponse<IPurchase> => {
  return service.get(id, params);
};

const patchPurchase = (id: string, data: any, params?: Params): TGetResponse<IPurchase> => {
  return service.patch(id, data, params);
};

const createPurchase = (
  data: ICreatePurchase,
  params?: Params,
): TGetResponse<IPurchase | IPurchase[]> => {
  return service.create(data, params);
};

const removePurchase = (id: string, params?: Params): TGetResponse<IPurchase> => {
  return service.remove(id, params);
};

export const purchasesApi = {
  findPurchases,
  getPurchase,
  patchPurchase,
  createPurchase,
  removePurchase,
};
