import { userCreditsApi } from '@/api';
import type { Module } from 'vuex';
import type { TRootStore } from '@/store';
import type { IUserCredit } from '@/types/UserCredits';
import type { IFindParams } from '@/types/Api';
import type { Params } from '@feathersjs/feathers';
import { SET_USER_CREDITS, SET_USER_CREDIT } from './storeTypes/mutationTypes';

export interface IUserCreditsStoreState {
  userCredits: IUserCredit[];
  loading: boolean;
  page: number;
  total: number;
  limit: number;
}

type TUserCreditsStore = Module<IUserCreditsStoreState, TRootStore>;

const UsersCreditStore: TUserCreditsStore = {
  state: () => ({
    loading: false,
    userCredits: [],
    page: 0,
    total: 0,
    limit: 10,
  }),

  getters: {
    allUserCredits: (state) => state.userCredits,
    userCreditsLoading: (state) => state.loading,
    userCreditsTotal: (state) => state.total,
  },

  mutations: {
    [SET_USER_CREDITS](state, { data, refresh }: { data: IUserCredit[]; refresh: boolean }) {
      state.userCredits = [...(refresh ? [] : state.userCredits), ...data];
    },
    [SET_USER_CREDIT](state, usersCreditsDetail: IUserCredit) {
      const index = state.userCredits.findIndex(
        (userCredits) => usersCreditsDetail.id === userCredits.id,
      );
      if (index !== -1) {
        state.userCredits[index] = usersCreditsDetail;
      } else {
        state.userCredits.push(usersCreditsDetail);
      }
      state.userCredits = [...state.userCredits];
    },
  },

  actions: {
    async findUserCredits({ commit, state }, params: IFindParams) {
      state.loading = true;
      const { data, total } = await userCreditsApi.findUserCredits(params);
      commit(SET_USER_CREDITS, { data, refresh: params.refresh });
      state.total = total;
      state.loading = false;
    },

    async createUserCredit(
      { commit, state },
      { data, params }: { data: IUserCredit; params?: Params },
    ) {
      state.loading = true;
      const userCredit = await userCreditsApi.createUserCredit(data, params);
      commit(SET_USER_CREDIT, userCredit);
      state.loading = false;
      return userCredit;
    },
  },
};

export default UsersCreditStore;
