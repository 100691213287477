import type { ILogin, IUser } from '@/types/Auth';
import { client } from '../client';

const login = ({
  email,
  password,
}: ILogin): Promise<{
  accessToken: string;
  authentication: Record<string, any>;
  user: IUser;
}> => {
  // @ts-ignore
  return client.authenticate({
    strategy: 'local',
    email,
    password,
  });
};

const relogin = (): Promise<{
  accessToken: string;
  authentication: Record<string, any>;
  user: IUser;
}> => {
  // @ts-ignore
  return client.reAuthenticate();
};

const logout = () => {
  return client.logout();
};

export const authenticationApi = {
  login,
  relogin,
  logout,
};
