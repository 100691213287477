<template>
  <div class="bg-background hidden lg:block z-10 top-0">
    <template v-if="!isMobile">
      <slot />
    </template>

    <Portal v-else to="drawer-content">
      <slot />
    </Portal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['currentBreakpoint']),
    isMobile() {
      return ['xs', 'sm', 'md'].some((bp) => bp === this.currentBreakpoint);
    },
  },
};
</script>
