import { Application } from '@feathersjs/feathers';
import { wsClient } from './wsClient';
import { restClient } from './restClient';

class ApiClient {
  client: Application<any>;

  isWsClient: boolean;

  constructor() {
    if (process.env.VUE_APP_MODE === 'admin' && wsClient) {
      this.client = wsClient;
      this.isWsClient = true;
    } else {
      this.client = restClient;
      this.isWsClient = false;
    }
  }
}

const { client, isWsClient } = new ApiClient();

export { client, isWsClient };
