<template>
  <v-card v-if="loaded">
    <v-overlay 
      v-if="currentProduct.status === 'processing' && !currentProduct.editing" 
      absolute 
      :z-index="1"
    > 
      Dateien werden verarbeitet.
      Bitte warten... 
      <v-icon xl>sync</v-icon>
    </v-overlay> 
    <v-card-title>
      Dateien

      <input
        ref="imageUploader"
        class="d-none"
        type="file"
        accept="image/jpeg, image/png, image/gif"
        multiple
        @change="onFileChanged"
      >
    </v-card-title>
    <v-divider />
    <v-toolbar elevation="0">
      <v-toolbar-title>Bilder</v-toolbar-title>
      <v-spacer />
      <v-btn @click="$refs.imageUploader.click()"> Bilder hinzufügen </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col
          v-for="(imageFile, i) in imageFiles"
          :key="`imageFile-${i}`"
          cols="12"
          sm="6"
          md="4"
          lg="2"
          xl="2"
        >
          <v-card>
            <div>
              <v-img
                v-if="imageFile.status === 'uploaded'"
                :src="imageFile.url"
                height="250"
                contain
              />
              <div 
                v-if="imageFile.status === 'uploading'" 
                class="w-full flex justify-center items-center" 
                style="height: 250px"
              >
                <v-progress-circular :size="70" :width="6" indeterminate />
              </div>
              <div 
                v-if="imageFile.status === 'failed'" 
                class="w-full flex justify-center items-center" 
                style="height: 250px"
              >
                <div class="text-center">
                  <v-icon large color="error">error</v-icon>
                  <div class="text-xs">
                    Fehler beim Hochladen dieser Datei!
                    Versuchen Sie die Datei zu löschen & erneut hochzuladen.
                  </div>
                </div>
              </div>
            </div>

            <v-divider />

            <v-container>
              <div v-if="currentProduct.type === 'fotoset'">
                <v-checkbox
                  v-model="imageFile.isPreviewImage"
                  dense
                  label="Vorschaubild"
                  readonly
                  :disabled="imageFile.isThumbnail"
                  @click="invertPreviewImageState(imageFile)"
                />
                <v-checkbox
                  v-model="imageFile.isThumbnail"
                  dense
                  label="Thumbnail"
                  readonly
                  :disabled="!imageFile.isPreviewImage || imageFile.isThumbnail"
                  @click="changeThumbnail(imageFile)"
                />
              </div>
              <div v-else>
                <v-checkbox
                  v-model="imageFile.isThumbnail"
                  dense
                  label="Thumbnail"
                  readonly
                  :disabled="imageFile.isThumbnail"
                  @click="changeThumbnail(imageFile)"
                />
              </div>
              <v-btn :disabled="imageFile.isThumbnail" @click="deleteImageFile(imageFile)">
                <v-icon>delete</v-icon>
                Löschen
              </v-btn>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <div v-if="currentProduct.type === 'movie'">
      <v-toolbar elevation="0">
        <v-toolbar-title>Medien</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col
            col="12"
            md="4"
            lg="3"
          >
            <div class="sticky top-4">
              <div class="text-xl mb-2">Quelldatei:</div>
              <v-card 
                v-if="sourceMovieFile" 
                two-line 
                :loading="sourceMovieFile.status === 'uploading'"
              >
                <v-list class="relative">
                  <v-overlay 
                    v-if="sourceMovieFile.status === 'failed'" 
                    absolute 
                    :z-index="1"
                    class="w-full flex justify-center items-center" 
                  > 
                    <div class="text-center">
                      <v-icon large color="error">error</v-icon>
                      <div class="text-xs">
                        Fehler beim Hochladen dieser Datei!
                        Versuchen Sie die Datei zu löschen & erneut hochzuladen.
                      </div>
                    </div>
                  </v-overlay> 
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Typ:</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ sourceMovieFile.types[0].name | fileType }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Format:</v-list-item-title>
                      <v-list-item-subtitle>{{ sourceMovieFile.mimeType }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Qualität:</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ sourceMovieFile.quality ? sourceMovieFile.quality : 'Original' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Bitrate:</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ sourceMovieFile.bitrate }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Trailer Start-Timecode (HH:MM:SS:FF)</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ sourceMovieFile.trailerTimecodeStart }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Trailer End-Timecode (HH:MM:SS:FF)</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ sourceMovieFile.trailerTimecodeEnd }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Status:</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ sourceMovieFile.status | fileStatus }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-card-actions>
                  <v-spacer />
                  <v-btn @click="removeMovieFile">
                    <v-icon>delete</v-icon>
                    Löschen
                  </v-btn>
                </v-card-actions>
              </v-card>
              <v-card v-else-if="!sourceMovieFile && newMovieFile">
                <v-form ref="form" v-model="fromIsValid" lazy-validation>
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Typ:</v-list-item-title>
                        <v-list-item-subtitle>
                          Film
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Format:</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ newMovieFile.mimeType }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Größe:</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ newMovieFile.size | prettyBytes }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-card-text>
                      <v-text-field
                        v-model.number="newMovieFileSettings.bitrate"
                        label="Bitrate (Bit/s)"
                        number
                        type="number"
                        :rules="rules.bitrateRules"
                        required
                      />
                      <v-text-field
                        v-model="newMovieFileSettings.trailerTimecodeStart"
                        label="Trailer Start-Timecode (HH:MM:SS:FF)"
                        :rules="rules.timecodeRules"
                        required
                      />
                      <v-text-field
                        v-model="newMovieFileSettings.trailerTimecodeEnd"
                        label="Trailer End-Timecode (HH:MM:SS:FF)"
                        :rules="rules.timecodeRules"
                        required
                      />
                    </v-card-text>
                  </v-list>
              
                  <v-card-actions>
                    <v-btn :disabled="!fromIsValid" @click="uploadNewMovieFile">
                      <v-icon>upload</v-icon>
                      Hochladen
                    </v-btn>
                    <v-btn @click="newMovieFile = null">
                      <v-icon>delete</v-icon>
                      Löschen
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
              <v-card v-else class="w-full flex justify-center items-center" style="height: 250px">
                <input
                  ref="movieUploader"
                  class="d-none"
                  type="file"
                  accept="video/x-ms-wmv, video/mp4"
                  @change="createMovieFile"
                >
                <v-btn @click="$refs.movieUploader.click()">
                  <v-icon x-large> add </v-icon>
                </v-btn>
              </v-card>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="8"
            lg="9"
          >
            <div class="text-xl mb-2">Konvertierungsdateien:</div>

            <v-row v-if="conversionFiles">
              <v-col
                v-for="(conversionFile, i) in conversionFiles"
                :key="`conversionFile-${i}`"
                cols="12"
                sm="6"
                md="4"
                lg="3"
                xl="2"
              >
                <v-card>
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Typ:</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ conversionFile.types[0].name | fileType }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Format:</v-list-item-title>
                        <v-list-item-subtitle>{{ conversionFile.mimeType }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Qualität:</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ conversionFile.quality ? conversionFile.quality : 'Original' }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Status:</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ conversionFile.status | fileStatus }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
    <div v-if="currentProduct.type === 'fotoset' && zipFile">
      <v-toolbar elevation="0">
        <v-toolbar-title>Zip-Datei</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            xl="2"
          >
            <v-card>
              <v-list two-line>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Typ:</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ zipFile.types[0].name | fileType }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Format:</v-list-item-title>
                    <v-list-item-subtitle>{{ zipFile.mimeType }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Status:</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ zipFile.status | fileStatus }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
    <v-snackbar v-model="fileError" multi-line>
      {{ fileErrorMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="fileError = false"
        >
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
    <div v-if="currentProduct.editing" class="sticky bottom-4 my-4 w-full flex justify-center">
      <v-btn
        :disabled="
          currentProduct.type === 'movie' && !sourceMovieFile || 
            allProductFiles.some(file => file.status === 'uploading')
        "
        @click="stopEditingProduct"
      >
        Bearbeitung beenden
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { SET_PRODUCT_FILE } from '../../../../store/storeTypes/mutationTypes';

export default {
  filters: {
    fileStatus(status) {
      switch (status) {
        case 'uploading':
          return 'Am hochladen';
        case 'waitingForUpload':
          return 'Wartet auf Bestätigung';
        case 'converting':
          return 'Am konvertieren';
        case 'uploaded':
          return 'Hochgeladen';
        case 'failed':
          return 'Fehlgeschlagen.';
        default:
          return '';
      }
    },
    fileType(type) {
      switch (type) {
        case 'movie':
          return 'Film';
        case 'stream':
          return 'Stream';
        case 'trailer':
          return 'Trailer';
        case 'zip':
          return 'Zip';
        default:
          return '';
      }
    },
  },
  props: {
    value: {
      type: Boolean,
    },
  },

  data() {
    return {
      selectedFile: null,
      loaded: false,
      changingThumbnail: false,
      fileError: false,
      fileErrorMsg: '',
      newMovieFile: null,
      newMovieFileSettings: {
        bitrate: 10000000,
        trailerTimecodeStart: '00:00:00:00',
        trailerTimecodeEnd: '00:00:30:00',
      },
      rules: {
        bitrateRules: [
          (v) => v !== '' || 'Bitrate ist erforderlich',
          (v) => v > 0 || 'Bitrate ist erforderlich',
          (v) => v < 9223372036854775807 || 'Bitrate zu hoch',
        ],
        timecodeRules: [
          (v) =>
            /(^(?:(?:[0-1][0-9]|[0-2][0-3]):)(?:[0-5][0-9]:){2}(?:[0-2][0-9])$)/.test(v) ||
            'Geben Sie einen validen Timecode an (HH:MM:SS:FF)',
        ],
      },
      fromIsValid: true,
      deletedImageFiles: [],
    };
  },

  computed: {
    ...mapGetters(['currentProduct', 'allProductFiles', 'productFilesTotal']),
    uploadingFile: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    imageFiles() {
      const filesFiltered = this.allProductFiles.filter((file) =>
        file.types.some((type) => type.name === 'previewImage' || type.name === 'fotosetImage'),
      );
      filesFiltered.forEach((file) => {
        file.isThumbnail = file.types.some((type) => type.name === 'thumbnail');
        file.isPreviewImage = file.types.some((type) => type.name === 'previewImage');
      });
      return filesFiltered;
    },
    sourceMovieFile() {
      return this.allProductFiles.find(
        (file) => file.sourceFileId === null && file.types.some((type) => type.name === 'movie'),
      );
    },
    conversionFiles() {
      return this.allProductFiles.filter(
        (file) =>
          file.sourceFileId !== null &&
          file.types.some(
            (type) => type.name === 'movie' || type.name === 'trailer' || type.name === 'stream',
          ),
      );
    },
    zipFile() {
      return this.allProductFiles.find((file) => file.types.some((type) => type.name === 'zip'));
    },
  },

  async created() {
    await this.loadAllProductFiles({
      query: {
        productId: this.currentProduct.id,
        $limit: 150,
      },
      refresh: true,
    });
    this.listenToProductFileEvents();

    this.loaded = true;
  },

  methods: {
    ...mapActions([
      'loadAllProductFiles',
      'getProductFile',
      'patchProduct',
      'createProductFile',
      'removeProductFile',
      'listenToProductFileEvents',
      'addTypeToProductFile',
      'removeTypeFromProductFile',
    ]),
    ...mapMutations([SET_PRODUCT_FILE]),

    isMovieFile(file) {
      return file.types.some(
        (type) => type.name === 'movie' || type.name === 'trailer' || type.name === 'stream',
      );
    },

    isZipFile(file) {
      return file.types.some((type) => type.name === 'zip');
    },

    isSourceMovieFile(file) {
      return file.sourceFileId === null && file.types.some((type) => type.name === 'movie');
    },

    async setEditingModus() {
      if (!this.currentProduct.editing) {
        await this.patchProduct({
          id: this.currentProduct.id,
          data: {
            editing: true,
          },
        });
      }
    },

    async changeThumbnail(file) {
      if (
        !this.changingThumbnail &&
        !file.isThumbnail &&
        file.types.some((type) => type.name === 'previewImage')
      ) {
        await this.setEditingModus();
        this.changingThumbnail = true;
        const oldThumbnail = this.imageFiles.find((file) => file.isThumbnail);

        if (oldThumbnail) {
          await this.removeTypeFromProductFile({
            productFileId: oldThumbnail.id,
            typeName: 'thumbnail',
          });
        }

        await this.addTypeToProductFile({
          productFileId: file.id,
          typeName: 'thumbnail',
        });

        this.changingThumbnail = false;
      }
    },

    async invertPreviewImageState(file) {
      if (!file.isThumbnail) {
        await this.setEditingModus();
        if (file.isPreviewImage) {
          await this.removeTypeFromProductFile({
            productFileId: file.id,
            typeName: 'previewImage',
          });
        } else {
          await this.addTypeToProductFile({
            productFileId: file.id,
            typeName: 'previewImage',
          });
        }
      }
    },

    async deleteImageFile(file) {
      if (!file.isThumbnail) {
        if (!this.deletedImageFiles.some((imageFile) => imageFile === file.id)) {
          this.deletedImageFiles.push(file.id);
          await this.setEditingModus();
          this.removeProductFile({ id: file.id });
        }
      }
    },

    async onFileChanged(e) {
      const files = Array.from(e.target.files);

      if (files.length) {
        const invalidFileType = files.some(
          (file) =>
            file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif',
        );
        if (invalidFileType) {
          this.fileError = false;
          this.fileErrorMsg = '';
          this.$nextTick(() => {
            this.fileError = true;
            this.fileErrorMsg =
              'Ungültig Dateityp verwendet! Es sind nur .jpg, .png & .gif Dateien erlaubt.';
          });

          return;
        }

        await this.setEditingModus();

        // eslint-disable-next-line no-restricted-syntax
        for (const file of files) {
          const data = {
            productId: this.currentProduct.id,
            mimeType: file.type,
            types: [],
            fileSize: file.size,
            name: file.name,
          };

          if (this.currentProduct.type === 'fotoset') {
            data.types.push({ name: 'fotosetImage' });
          } else {
            data.types.push({ name: 'previewImage' });
          }

          // eslint-disable-next-line no-await-in-loop
          const productFile = await this.createProductFile({
            data,
          });

          const formData = new FormData();
          Object.keys(productFile.uploadFrom.fields).forEach((key) => {
            formData.append(key, productFile.uploadFrom.fields[key]);
          });

          formData.append('file', file);
          try {
            this.uploadingFile = true;
            // eslint-disable-next-line no-await-in-loop
            await this.$axios.post(productFile.uploadFrom.url, formData);
          } catch (err) {
            console.log(err);
          }
        }

        this.uploadingFile = false;
        this.$refs.imageUploader.value = '';
      }
    },

    async removeMovieFile() {
      await this.setEditingModus();
      this.removeProductFile({ id: this.sourceMovieFile.id });
    },

    async createMovieFile(e) {
      if (!this.sourceMovieFile) {
        const file = e.target.files[0];

        this.fileError = false;
        this.fileErrorMsg = '';

        if (file.type !== 'video/x-ms-wmv' && file.type !== 'video/mp4') {
          this.$nextTick(() => {
            this.fileError = true;
            this.fileErrorMsg =
              'Ungültig Dateityp verwendet! Es ist sind nur .wmv & .mp4 Dateien erlaubt.';
          });
          return;
        }
        if (file.size > 268435456000) {
          this.$nextTick(() => {
            this.fileError = true;
            this.fileErrorMsg = 'Datei ist zu groß. Max. 250GB.';
          });
          return;
        }

        this.newMovieFile = file;
      }
    },

    async uploadNewMovieFile() {
      const valid = this.$refs.form.validate();
      if (valid) {
        await this.setEditingModus();

        const resp = await this.createProductFile({
          data: {
            productId: this.currentProduct.id,
            mimeType: this.newMovieFile.type,
            types: [{ name: 'movie' }],
            fileSize: this.newMovieFile.size,
            name: this.newMovieFile.name,
            ...this.newMovieFileSettings,
          },
        });
        const sourceMovieFile = resp.find((file) => this.isSourceMovieFile(file));

        const formData = new FormData();
        Object.keys(sourceMovieFile.uploadFrom.fields).forEach((key) => {
          formData.append(key, sourceMovieFile.uploadFrom.fields[key]);
        });

        formData.append('file', this.newMovieFile);
        try {
          this.uploadingFile = true;
          await this.$axios.post(sourceMovieFile.uploadFrom.url, formData);
        } catch (err) {
          console.log(err);
        } finally {
          this.uploadingFile = false;
        }
      }
    },

    stopEditingProduct() {
      if (
        this.currentProduct.editing &&
        ((this.currentProduct.type === 'movie' && this.sourceMovieFile) ||
          this.currentProduct.type === 'fotoset')
      ) {
        this.patchProduct({
          id: this.currentProduct.id,
          data: {
            editing: false,
          },
        });
      }
    },
  },
};
</script>
