<template>
  <div id="device-observer" />
</template>

<script>
import { useWindowSize, useBreakpoints, useWindowScroll, breakpointsTailwind } from '@vueuse/core';
import { mapMutations } from 'vuex';
import { SET_DEVICE } from '@/store/storeTypes/mutationTypes';

export default {
  setup() {
    const { width, height } = useWindowSize();
    const { y: scrollY } = useWindowScroll();
    const breakpoints = useBreakpoints(breakpointsTailwind);

    return {
      width,
      height,
      scrollY,
      breakpoints,
    };
  },

  data: () => ({
    currentBreakpoint: '',
    touchDevice: false,
  }),

  computed: {
    currentViewport() {
      if (['xs', 'sm'].includes(this.currentBreakpoint)) return 'mobile';
      if (['md', 'lg'].includes(this.currentBreakpoint)) return 'tablet';
      return 'desktop';
    },
  },

  watch: {
    width: {
      immediate: true,
      handler() {
        this.currentBreakpoint = this.getBreakpoint();
        this.touchDevice = this.checkForTouchSupport();
        this.setDeviceInfo();
      },
    },
    scrollY: {
      immediate: true,
      handler() {
        this.setDeviceInfo();
      },
    },
  },

  methods: {
    ...mapMutations([SET_DEVICE]),
    setDeviceInfo() {
      this[SET_DEVICE]({
        vWidth: this.width,
        vHeight: this.height,
        viewport: this.currentViewport,
        breakpoint: this.currentBreakpoint,
        touch: this.touchDevice,
        scrollY: this.scrollY,
      });
    },
    checkForTouchSupport() {
      return (
        'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
      );
    },
    getBreakpoint() {
      if (this.breakpoints.isGreater('2xl')) return '2xl';
      if (this.breakpoints.isGreater('xl')) return 'xl';
      if (this.breakpoints.isGreater('lg')) return 'lg';
      if (this.breakpoints.isGreater('md')) return 'md';
      if (this.breakpoints.isGreater('sm')) return 'sm';
      return 'xs';
    },
  },
};
</script>
