import type { Params } from '@feathersjs/feathers';
import type { TFindResponse } from '@/types/Api';
import type { ISubscriptionFee } from '@/types/SubscriptionFee';
import { client } from '../client';

const service = client.service('subscription-fees');

const findSubscriptionFees = (params?: Params): TFindResponse<ISubscriptionFee> => {
  return service.find({
    ...(params || {}),
    query: {
      $limit: 30,
      ...(params?.query || {}),
    },
  });
};
export const subscriptionFeesApi = {
  findSubscriptionFees,
};
