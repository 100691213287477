<template>
  <div 
    class="col-span-12 md:col-span-6 bg-interface p-6 flex items-center justify-center flex-wrap"
  >
    <div class="whitespace-pre-line text-center w-full">{{ $t('buyGoldCardExplanation') }}</div>
    <a
      href="https://olafwinter.wixsite.com/mysexyamazons/services-5"
      target="_blank"
      rel="noopener noreferrer"
      class="mt-3 uppercase tracking-wide text-xs bg-akzent 
            text-background inline-block px-2 py-1 rounded
            cursor-pointer hover:opacity-75"
    >
      {{ $t('goToGoldCardShop') }}
    </a>
  </div>
</template>

<script>
export default {};
</script>
