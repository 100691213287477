import type { Params } from '@feathersjs/feathers';
import type { TFindResponse, TGetResponse } from '@/types/Api';
import type { IUserCredit } from '@/types/UserCredits';
import { client } from '../client';

const service = client.service('user-credits');

const findUserCredits = (params?: Params): TFindResponse<IUserCredit> => {
  return service.find({
    ...(params || {}),
    query: {
      $limit: 30,
      ...(params?.query || {}),
    },
  });
};

const getUserCredit = (id: number, params?: Params): TGetResponse<IUserCredit> => {
  return service.get(id, params);
};

const createUserCredit = (data: IUserCredit, params?: Params): TGetResponse<IUserCredit> => {
  return service.create(data, params);
};

const getTotalUserCredits = (userId: number, params?: Params): TGetResponse<any> => {
  return client.service(`users/${userId}/totalCredits`).find(params);
};

export const userCreditsApi = {
  findUserCredits,
  getUserCredit,
  getTotalUserCredits,
  createUserCredit,
};
