import { categoriesApi } from '@/api';
import type { Module } from 'vuex';
import type { TRootStore } from '@/store';
import type { ICategory } from '@/types/Categories';
import type { IFindParams } from '@/types/Api';
import type { Params } from '@feathersjs/feathers';
import {
  SET_CATEGORIES,
  OPEN_CATEGORY,
  SET_CATEGORIE,
  REMOVE_CATEGORIE,
  RESET_CATEGORIES,
} from './storeTypes/mutationTypes';

export interface ICategoriesStoreState {
  categories: ICategory[];
  loading: boolean;
  page: number;
  total: number;
  endOfList: boolean;
  selectedCategory: number | null;
  limit: number;
}

type TCategoriesStore = Module<ICategoriesStoreState, TRootStore>;

const CategoriesStore: TCategoriesStore = {
  state: () => ({
    loading: false,
    categories: [],
    page: 0,
    total: 0,
    endOfList: false,
    selectedCategory: null,
    limit: 10,
  }),

  getters: {
    allCategories: (state) => state.categories,
    allCategoriesLoaded: (state) => state.endOfList,
    currentCategory: (state) =>
      state.categories.find((category) => category.id === state.selectedCategory),
    categoriesLoading: (state) => state.loading,
    categoriesTotal: (state) => state.total,
  },

  mutations: {
    [SET_CATEGORIES](state, { data }: { data: ICategory[] }) {
      state.categories = [...state.categories, ...data];
      if (state.total > state.page * state.limit) {
        state.endOfList = false;
      } else {
        state.endOfList = true;
      }
    },
    [RESET_CATEGORIES](state) {
      state.page = 0;
      state.categories = [];
    },
    [OPEN_CATEGORY](state, id: number | null) {
      state.selectedCategory = id;
    },
    [SET_CATEGORIE](state, categorieDetail: ICategory) {
      const index = state.categories.findIndex((categorie) => categorieDetail.id === categorie.id);
      if (index !== -1) {
        state.categories[index] = categorieDetail;
      } else {
        state.categories.push(categorieDetail);
      }
      state.categories = [...state.categories];
    },
    [REMOVE_CATEGORIE](state, categoreDetail: ICategory) {
      const i = state.categories.findIndex((categorie) => categorie.id === categoreDetail.id);
      if (i !== -1) {
        state.categories.splice(i, 1);
      }
    },
  },

  actions: {
    async findCategories({ commit, state }, params: IFindParams) {
      state.loading = true;
      const { refresh, query, ...restParams } = params || {};
      if (refresh) {
        commit(RESET_CATEGORIES);
      }
      // @ts-ignore
      const limit: number = params?.query?.$limit || 10;
      if (state.total > limit * state.page || refresh) {
        const { data, total } = await categoriesApi.findCategories({
          query: {
            $limit: limit,
            $skip: !params?.refresh ? limit * state.page : 0,
            ...(query || {}),
          },
          ...(restParams || {}),
        });
        state.limit = limit;
        state.total = total;
        state.page += 1;
        commit(SET_CATEGORIES, { data });
      }
      state.loading = false;
    },

    async loadAllCategories({ commit, state }, params: IFindParams) {
      state.loading = true;
      commit(RESET_CATEGORIES);
      const { data, total, limit } = await categoriesApi.findCategories(params);
      commit(SET_CATEGORIES, { data });
      state.limit = limit;
      state.total = total;
      state.page += 1;

      if (total > limit) {
        const totalLeft = total - limit;
        const requests = [];
        let skip = 0;
        while (skip < totalLeft) {
          skip += limit;
          requests.push({
            ...params.query,
            $limit: limit,
            $skip: skip,
          });
        }

        await Promise.all(
          requests.map(async (request) => {
            const { data } = await categoriesApi.findCategories({
              query: request,
            });
            state.page += 1;
            commit(SET_CATEGORIES, { data });
          }),
        );
      }

      state.loading = false;
    },

    async createCategorie(
      { commit, state },
      { data, params }: { data: ICategory; params?: Params },
    ) {
      state.loading = true;
      const categorie = await categoriesApi.createCategorie(data, params);
      commit(SET_CATEGORIE, categorie);
      state.loading = false;
      return categorie;
    },

    async patchCategorie(
      { commit, state },
      { id, data, params }: { id: number; data: any; params?: Params },
    ) {
      state.loading = true;
      const categorie = await categoriesApi.patchCategorie(id, data, params);
      commit(SET_CATEGORIE, categorie);
      state.loading = false;
      return categorie;
    },

    async removeCategorie({ commit, state }, { id, params }: { id: number; params?: Params }) {
      state.loading = true;
      const categorie = await categoriesApi.removeCategorie(id, params);
      commit(REMOVE_CATEGORIE, categorie);
      state.loading = false;
      return categorie;
    },
  },
};

export default CategoriesStore;
