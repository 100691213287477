<template>
  <div> 
    <div class="flex justify-center p-12">
      <v-btn color="error" @click="showDialog = true">
        User löschen
      </v-btn>
    </div>
    <v-dialog v-model="showDialog" max-width="350">
      <v-card :loading="loading">
        <div v-if="!error">
          <v-card-title>Wirklich?</v-card-title> 

          <v-card-text>
            Soll der User wirklich gelöscht werden?
          </v-card-text>

          <v-card-actions>
            <v-btn
              text
              color="error"
              @click="confirmUserDeletion"
            >
              Löschen
            </v-btn>
          </v-card-actions>
        </div>
        <div v-else>
          <v-card-title>Fehler!</v-card-title> 

          <v-card-text>
            Da der User noch offene Abo-Abbuchungen hat, kann dieser nicht gelöscht werden.
          </v-card-text>

          <v-card-actions>
            <v-btn
              text
              @click="showDialog = false"
            >
              Schließen
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      showDialog: false,
      loading: false,
      error: false,
    };
  },

  computed: {
    ...mapGetters(['selectedUser']),
  },

  methods: {
    ...mapActions(['removeUser']),
    async confirmUserDeletion() {
      this.loading = true;
      try {
        await this.removeUser({ id: this.selectedUser.id });
        this.showDialog = false;
        this.$router.push('/users');
      } catch (err) {
        if (err.code === 409) {
          this.error = true;
        }
      }
      this.loading = false;
    },
  },
};
</script>
