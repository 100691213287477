import { subscriptionsPlansApi } from '@/api';
import type { Module } from 'vuex';
import type { TRootStore } from '@/store';
import type { ISubscriptionPlan } from '@/types/SubscriptionPlans';
import type { IFindParams } from '@/types/Api';
import type { Params } from '@feathersjs/feathers';
import {
  SET_SUBSCRIPTION_PLANS,
  OPEN_SUBSCRIPTION_PLAN,
  SET_SUBSCRIPTION_PLAN,
  RESET_SUBSCRIPTION_PLANS,
} from './storeTypes/mutationTypes';

export interface ISubscriptionPlanStoreState {
  subscriptionPlans: ISubscriptionPlan[];
  loading: boolean;
  page: number;
  total: number;
  endOfList: boolean;
  selectedSubscriptionPlan: number | null;
  limit: number;
}

type TSubscriptionPlanStore = Module<ISubscriptionPlanStoreState, TRootStore>;

const SubscriptionPlansStore: TSubscriptionPlanStore = {
  state: () => ({
    loading: false,
    subscriptionPlans: [],
    page: 0,
    total: 0,
    endOfList: false,
    selectedSubscriptionPlan: null,
    limit: 10,
  }),

  getters: {
    allSubscriptionPlans: (state) => state.subscriptionPlans,
    allSubscriptionPlansLoaded: (state) => state.endOfList,
    currentSubscriptionPlan: (state) =>
      state.subscriptionPlans.find(
        (subsriptionPlan) => subsriptionPlan.id === state.selectedSubscriptionPlan,
      ),
    subscriptionPlansLoading: (state) => state.loading,
    subscriptionPlansTotal: (state) => state.total,
  },

  mutations: {
    [SET_SUBSCRIPTION_PLANS](state, { data }: { data: ISubscriptionPlan[] }) {
      state.subscriptionPlans = [...state.subscriptionPlans, ...data];
      if (state.total > state.page * state.limit) {
        state.endOfList = false;
      } else {
        state.endOfList = true;
      }
    },
    [RESET_SUBSCRIPTION_PLANS](state) {
      state.page = 0;
      state.subscriptionPlans = [];
    },
    [OPEN_SUBSCRIPTION_PLAN](state, id: number | null) {
      state.selectedSubscriptionPlan = id;
    },
    [SET_SUBSCRIPTION_PLAN](state, subscriptionDetail: ISubscriptionPlan) {
      const index = state.subscriptionPlans.findIndex(
        (subscriptionPlan) => subscriptionDetail.id === subscriptionPlan.id,
      );
      if (index !== -1) {
        state.subscriptionPlans[index] = subscriptionDetail;
      } else {
        state.subscriptionPlans.push(subscriptionDetail);
      }
      state.subscriptionPlans = [...state.subscriptionPlans];
    },
  },

  actions: {
    async findSubscriptionPlans({ commit, state }, params: IFindParams) {
      state.loading = true;
      const { refresh, query, ...restParams } = params || {};
      if (refresh) {
        commit(RESET_SUBSCRIPTION_PLANS);
      }
      // @ts-ignore
      const limit: number = params?.query?.$limit || 10;
      if (state.total > limit * state.page || refresh) {
        const { data, total } = await subscriptionsPlansApi.findSubscriptionPlans({
          query: {
            $limit: limit,
            $skip: !params?.refresh ? limit * state.page : 0,
            ...(query || {}),
          },
          ...(restParams || {}),
        });
        state.limit = limit;
        state.total = total;
        state.page += 1;
        commit(SET_SUBSCRIPTION_PLANS, { data });
      }
      state.loading = false;
    },

    async createSubscriptionPlan(
      { commit, state },
      { data, params }: { data: ISubscriptionPlan; params?: Params },
    ) {
      state.loading = true;
      const subscriptionPlan = await subscriptionsPlansApi.createSubscriptionPlan(data, params);
      commit(SET_SUBSCRIPTION_PLAN, subscriptionPlan);
      state.loading = false;
      return subscriptionPlan;
    },

    async patchSubscriptionPlan(
      { commit, state },
      { id, data, params }: { id: number; data: any; params?: Params },
    ) {
      state.loading = true;
      const subscriptionPlan = await subscriptionsPlansApi.patchSubscriptionPla(id, data, params);
      commit(SET_SUBSCRIPTION_PLAN, subscriptionPlan);
      state.loading = false;
      return subscriptionPlan;
    },
  },
};

export default SubscriptionPlansStore;
