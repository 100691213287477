<template>
  <v-app>
    <v-main>
      <v-container fill-height="fill-height">
        <v-layout align-center="align-center" justify-center="justify-center">  
          <v-card>
            <v-card-title>
              <v-icon class="mr-2" large="large">admin_panel_settings</v-icon> 
              Admin-Panel
            </v-card-title>
            <v-card-text>
              <div class="subheading">
                Loggen Sie sich in Ihr Admin-Panel ein:
              </div>
              <v-form ref="form" v-model="fromIsValid" lazy-validatio>
                <v-text-field
                  v-model="loginForm.email"
                  light="light"
                  prepend-icon="email"
                  label="Email"
                  type="email"
                  required
                  :rules="[v => !!v || 'Email ist erforderlich']"
                />
                <v-text-field
                  v-model="loginForm.password"
                  light="light"
                  prepend-icon="lock"
                  label="Password"
                  type="password"
                  required
                  :rules="[v => !!v || 'Password ist erforderlich']"
                />
                <transition name="fade">
                  <div v-if="invalidLogin" class=" pb-3 red--text flex items-center">
                    <v-icon class="mr-2" color="red">error</v-icon> 
                    E-Mail oder Passwort falsch.
                  </div>
                </transition>
                <v-btn
                  block="block"
                  type="submit"
                  @click.prevent="onLogin"
                >
                  Einloggen
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { SET_SHOW_LOGIN_MESSAGE } from '@/store/storeTypes/mutationTypes';

export default {
  data() {
    return {
      loginForm: {
        email: '',
        password: '',
      },
      fromIsValid: true,
      invalidLogin: false,
    };
  },

  computed: {
    ...mapGetters(['routeAfterLogin', 'hasLoginError']),
  },

  watch: {
    loginForm: {
      deep: true,
      handler() {
        this.invalidLogin = false;
      },
    },
  },

  methods: {
    ...mapActions(['login']),
    ...mapMutations([SET_SHOW_LOGIN_MESSAGE]),
    async onLogin() {
      const valid = this.$refs.form.validate();
      if (valid && !this.invalidLogin) {
        await this.login({
          email: this.loginForm.email,
          password: this.loginForm.password,
        });

        if (!this.hasLoginError) {
          this[SET_SHOW_LOGIN_MESSAGE](true);
          this.$router.push(this.routeAfterLogin);
        } else {
          this.invalidLogin = true;
        }
      }
    },
  },
};
</script>
