<template>
  <div v-if="!allBundleProductsPurchased">
    <SeperationBox>
      <template>
        {{ $t('availableBundles') }}
      </template>
    </SeperationBox>
    <ProductDetailBundlesItem
      v-for="bundle in currentProduct.bundles" 
      :key="`product-detail-bundle-left-${bundle.id}`"
      :bundle="bundle"
      @purchaseBundle="$e => $emit('purchaseBundle', $e)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SeperationBox from '../SeperationBox/index.vue';
import ProductDetailBundlesItem from '../ProductDetailBundlesItem/index.vue';

export default {
  components: {
    SeperationBox,
    ProductDetailBundlesItem,
  },

  computed: {
    ...mapGetters(['currentProduct', 'currentUser']),
    allBundleProductsPurchased() {
      return (
        this.currentProduct &&
        this.currentUser &&
        this.currentProduct.bundles.every((bundle) =>
          bundle.bundleProducts.every((product) =>
            product.purchases.some((purchase) => purchase.userId === this.currentUser.id),
          ),
        )
      );
    },
  },
};
</script>
