import { productFilesApi } from '@/api';
import type { Module } from 'vuex';
import type { Params } from '@feathersjs/feathers';
import type { TRootStore } from '@/store';
import type { IProductFile } from '@/types/ProductFile';
import type { IFindParams } from '@/types/Api';
import {
  SET_PRODUCT_FILES,
  SET_PRODUCT_FILE,
  RESET_PRODUCT_FILES,
  OPEN_PRODUCT_FILE,
  REMOVE_PRODUCT_FILE,
  SET_PRODUCT_FILE_TYPE,
  REMOVE_PRODUCT_FILE_TYPE,
} from './storeTypes/mutationTypes';

export interface IProductFilesStoreState {
  productFiles: IProductFile[];
  loading: boolean;
  page: number;
  total: number;
  endOfList: boolean;
  selectedProductFile: number | null;
  limit: number;
}

type TProductFilesStore = Module<IProductFilesStoreState, TRootStore>;

const ProductFilesStore: TProductFilesStore = {
  state: () => ({
    loading: false,
    productFiles: [],
    page: 0,
    total: 0,
    endOfList: false,
    selectedProductFile: null,
    limit: 50,
  }),

  getters: {
    allProductFiles: (state) => state.productFiles,
    allProductsFilesLoaded: (state) => state.endOfList,
    currentProductFile: (state) =>
      state.productFiles.find((productFile) => state.selectedProductFile === productFile.id) ||
      null,
    productFilesLoading: (state) => state.loading,
    productFilesTotal: (state) => state.total,
  },

  mutations: {
    [SET_PRODUCT_FILES](state, { data, refresh }: { data: IProductFile[]; refresh: boolean }) {
      state.productFiles = [...(refresh ? [] : state.productFiles), ...data];
    },
    [RESET_PRODUCT_FILES](state) {
      state.page = 0;
      state.productFiles = [];
    },
    [OPEN_PRODUCT_FILE](state, id: number) {
      state.selectedProductFile = id;
    },
    [SET_PRODUCT_FILE](state, productFileDetail: IProductFile) {
      const index = state.productFiles.findIndex(
        (productFile) => productFileDetail.id === productFile.id,
      );
      if (index !== -1) {
        state.productFiles[index] = productFileDetail;
      } else {
        state.productFiles.push(productFileDetail);
      }
      state.productFiles = [...state.productFiles];
    },
    [REMOVE_PRODUCT_FILE](state, productFileDetail: IProductFile) {
      const i = state.productFiles.findIndex(
        (productFiles) => productFiles.id === productFileDetail.id,
      );
      if (i !== -1) {
        state.productFiles.splice(i, 1);
      }
    },
    [SET_PRODUCT_FILE_TYPE](
      state,
      { productFileId, typeName }: { productFileId: number; typeName: string },
    ) {
      const index = state.productFiles.findIndex((prodcutFile) => prodcutFile.id === productFileId);
      if (index !== -1 && state.productFiles[index].types) {
        state.productFiles[index].types.push({ name: typeName });
      }
    },
    [REMOVE_PRODUCT_FILE_TYPE](
      state,
      { productFileId, typeName }: { productFileId: number; typeName: string },
    ) {
      const index = state.productFiles.findIndex((prodcutFile) => prodcutFile.id === productFileId);
      if (index !== -1 && state.productFiles[index].types) {
        const i = state.productFiles[index].types.findIndex((type) => type.name === typeName);
        if (i !== -1) {
          state.productFiles[index].types.splice(i, 1);
        }
      }
    },
  },

  actions: {
    listenToProductFileEvents({ commit, state }) {
      productFilesApi.removeEventListener();
      productFilesApi.listenToEvents((event, payload) => {
        if (event === 'patched') {
          const productFile = state.productFiles.find(
            (productFile) => productFile.id === payload.id,
          );
          if (productFile) {
            const resolvedRessources = {
              types: productFile.types,
            };

            commit(SET_PRODUCT_FILE, {
              ...payload,
              ...resolvedRessources,
            });
          }
        } else if (event === 'removed') {
          commit(REMOVE_PRODUCT_FILE, payload);
        } else if (event === 'created') {
          commit(SET_PRODUCT_FILE, payload);
        }
      });
    },

    async findProductFiles({ commit, state }, params: IFindParams) {
      state.loading = true;
      const { data, total } = await productFilesApi.findProductsFiles(params);
      commit(SET_PRODUCT_FILES, { data, refresh: params.refresh });
      state.total = total;
      state.loading = false;
    },

    async loadAllProductFiles({ commit, state }, params: IFindParams) {
      state.loading = true;
      const { data, total, limit } = await productFilesApi.findProductsFiles(params);
      commit(SET_PRODUCT_FILES, { data, refresh: params.refresh });
      state.total = total;

      if (total > limit) {
        const totalLeft = total - limit;
        const requests = [];
        let skip = 0;
        while (skip < totalLeft) {
          skip += limit;
          requests.push({
            ...params.query,
            $limit: limit,
            $skip: skip,
          });
        }

        await Promise.all(
          requests.map(async (request) => {
            const { data } = await productFilesApi.findProductsFiles({
              query: request,
            });
            commit(SET_PRODUCT_FILES, { data, refresh: false });
          }),
        );
      }

      state.loading = false;
    },

    async getProductFile({ commit, state }, { id, params }: { id: number; params?: Params }) {
      state.loading = true;
      const prodcutFile = await productFilesApi.getProductsFile(id, params);
      commit(SET_PRODUCT_FILE, prodcutFile);
      state.loading = false;
    },

    async createProductFile({ state }, { data, params }: { data: IProductFile; params?: Params }) {
      state.loading = true;
      const resp = await productFilesApi.createProductFile(data, params);

      state.loading = false;
      return resp;
    },

    async removeProductFile({ state }, { id, params }: { id: number; params?: Params }) {
      state.loading = true;
      const productFile = await productFilesApi.removeProductFile(id, params);
      return productFile;
    },

    async addTypeToProductFile(
      { commit, state },
      { productFileId, typeName }: { productFileId: number; typeName: string },
    ) {
      state.loading = true;

      const productFileType = await productFilesApi.addTypeToProductFile(productFileId, {
        name: typeName,
      });
      commit(SET_PRODUCT_FILE_TYPE, {
        productFileId,
        typeName,
      });

      state.loading = false;
      return productFileType;
    },

    async removeTypeFromProductFile(
      { commit, state },
      { productFileId, typeName }: { productFileId: number; typeName: string },
    ) {
      state.loading = true;

      const productFileType = await productFilesApi.removeTypeFromProductFile(
        productFileId,
        typeName,
      );
      commit(REMOVE_PRODUCT_FILE_TYPE, { productFileId, typeName });

      state.loading = false;
      return productFileType;
    },
  },
};

export default ProductFilesStore;
