











import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    innerClass: {
      type: [String, Array, Object],
      default: null,
    },
  },
});
