<template>
  <main>
    <DeviceObserver />
    <template v-if="!isLoading">
      <component :is="currentLayout" v-if="currentLayout">
        <transition
          name="fade"
          mode="out-in"
          appear
          @before-enter="beforeEnter"
        >
          <router-view />
        </transition>
      </component>
      <transition
        name="fade"
        mode="out-in"
        appear
        @before-enter="beforeEnter"
      >
        <router-view v-if="!currentLayout" />
      </transition>
    </template>
    <div
      v-if="!hideLoading"
      class="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-background transition duration-150 ease-in-out z-40"
      :class="{
        'opacity-0 z-0': !isLoading,
      }"
    >
      <LoadingCircle />
    </div>
  </main>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import { DeviceObserver } from '@/ui/components';
import { client } from './api/client';
// import { useServiceWorker } from '@/hooks';

export default defineComponent({
  name: 'App',

  components: {
    DeviceObserver,
  },

  // setup() {
  //   const { appNeedsRefresh, refreshApp } = useServiceWorker();

  //   watch(
  //     appNeedsRefresh,
  //     (newVal) => {
  //       if (newVal) refreshApp();
  //     },
  //     { immediate: true },
  //   );

  //   return {};
  // },

  data: () => ({
    hideLoading: false,
  }),

  computed: {
    ...mapGetters(['authIsReady', 'isLoading', 'isDrawerOpen', 'currentUser']),
    currentLayout() {
      if (this.$route?.meta?.layout) {
        return `${this.$route?.meta?.layout}Layout`;
      }
      return false;
    },
  },

  watch: {
    authIsReady: {
      immediate: true,
      handler(newVal) {
        this.hideLoading = false;
        if (newVal) {
          setTimeout(() => {
            this.hideLoading = true;
          }, 300);
        }
      },
    },
    $route: {
      immediate: true,
      handler(newVal) {
        this.setBodyOverflowClass(newVal?.meta?.bodyOverflowHidden);
      },
    },
    isDrawerOpen(newVal) {
      this.setBodyOverflowClass(newVal);
    },
  },

  created() {
    this.$store.dispatch('initCache');
    client.hooks({
      error: (context) => {
        if (context.error && context.error.code === 401 && this.currentUser) {
          this.$store.dispatch('logout').then(() => {
            this.$router.push('/login');
          });
        }
      },
    });
  },

  methods: {
    setBodyOverflowClass(hidden) {
      if (hidden) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    },
    beforeEnter() {
      this.$root.$emit('scrollBeforeEnter');
    },
  },
});
</script>
