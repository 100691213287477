<template>
  <transition name="fade">
    <div 
      v-if="show"
      class="
        fixed w-full h-full inset-0 bg-background 
        bg-opacity-75 z-50 flex items-center justify-center
      "
    >
      <div class="bg-interface m-3 p-6 relative">
        <div 
          v-if="closeIcon" 
          class="mx-auto mt-20 mb-4 cursor-pointer hover:opacity-75" 
          @click="updateValue(false)"
        >
          <Icon 
            name="close" 
            :size="24"
            class="mx-auto"
          />
          <div class="text-center">{{ $t('closeWindow') }}</div>
        </div>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
    closeIcon: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>
