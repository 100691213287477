<template>
  <v-card>
    <v-tabs right show-arrows>
      <v-card-title class="black--text">
        Beschreibungen
      </v-card-title>

      <v-spacer />

      <v-tab>Englisch</v-tab>
      <v-tab>Deutsch</v-tab>

        
      <v-tab-item>
        <v-card-text>
          <v-textarea
            v-model="englishTranslation.shortDescription"
            auto-grow
            rows="3"
            label="Kurzbeschreibung"
          />
          <v-textarea
            v-model="englishTranslation.longDescription"
            auto-grow
            rows="5"
            label="Beschreibung"
          />
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card-text>
          <v-textarea
            v-model="germanTranslation.shortDescription"
            auto-grow
            rows="3"
            label="Kurzbeschreibung"
          />
          <v-textarea
            v-model="germanTranslation.longDescription"
            auto-grow
            rows="5"
            label="Beschreibung"
          />
        </v-card-text>
      </v-tab-item>
    </v-tabs>
      
    <v-card-actions>
      <v-btn @click="updateProductTranslations">
        Speichern
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      englishTranslation: {
        shortDescription: '',
        longDescription: '',
      },
      germanTranslation: {
        shortDescription: '',
        longDescription: '',
      },
      translationTabs: null,
    };
  },

  computed: {
    ...mapGetters(['currentProduct']),
  },

  created() {
    this.englishTranslation = this.currentProduct.translations.find(
      (translation) => translation.language === 'en',
    );

    this.germanTranslation = this.currentProduct.translations.find(
      (translation) => translation.language === 'de',
    );
  },

  methods: {
    ...mapActions(['patchProductTranslation']),
    async updateProductTranslations() {
      await Promise.all([
        this.patchProductTranslation({
          productId: this.currentProduct.id,
          translationId: this.englishTranslation.id,
          data: {
            shortDescription: this.englishTranslation.shortDescription,
            longDescription: this.englishTranslation.longDescription,
          },
        }),
        this.patchProductTranslation({
          productId: this.currentProduct.id,
          translationId: this.germanTranslation.id,
          data: {
            shortDescription: this.germanTranslation.shortDescription,
            longDescription: this.germanTranslation.longDescription,
          },
        }),
      ]);
    },
  },
};
</script>
