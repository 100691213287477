<template>
  <div>
    <v-tabs ref="translationTabs" v-model="translationTabs">
      <v-tab>Englisch</v-tab>
      <v-tab>Deutsch</v-tab>
      <v-tab-item>
        <v-card-text>
          <v-textarea
            v-model="englishTranslationShort"
            auto-grow
            rows="3"
            label="Kurzbeschreibung"
          />
          <v-textarea
            v-model="englishTranslationLong" 
            auto-grow
            rows="5"
            label="Beschreibung"
          />
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card-text>
          <v-textarea
            v-model="germanTranslationShort" 
            auto-grow
            rows="3"
            label="Kurzbeschreibung"
          />
          <v-textarea
            v-model="germanTranslationLong" 
            auto-grow
            rows="5"
            label="Beschreibung"
          />
        </v-card-text>
      </v-tab-item>
    </v-tabs>
    <v-btn
      text
      @click="$emit('back')"
    >
      Zurück
    </v-btn>
    <v-btn
      color="primary"
      @click="$emit('next')"
    >
      Weiter
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import {
  SET_PRODUCT_CREATION_TRANSLATION_ENGLISH_SHORT,
  SET_PRODUCT_CREATION_TRANSLATION_ENGLISH_LONG,
  SET_PRODUCT_CREATION_TRANSLATION_GERMAN_SHORT,
  SET_PRODUCT_CREATION_TRANSLATION_GERMAN_LONG,
} from '../../../../store/storeTypes/mutationTypes';

export default {
  data() {
    return {
      translationTabs: 0,
    };
  },

  computed: {
    ...mapGetters(['currentProductCreation']),
    englishTranslationShort: {
      get() {
        return this.currentProductCreation.translations[0].shortDescription;
      },
      set(value) {
        this[SET_PRODUCT_CREATION_TRANSLATION_ENGLISH_SHORT](value);
      },
    },
    englishTranslationLong: {
      get() {
        return this.currentProductCreation.translations[0].longDescription;
      },
      set(value) {
        this[SET_PRODUCT_CREATION_TRANSLATION_ENGLISH_LONG](value);
      },
    },
    germanTranslationShort: {
      get() {
        return this.currentProductCreation.translations[1].shortDescription;
      },
      set(value) {
        this[SET_PRODUCT_CREATION_TRANSLATION_GERMAN_SHORT](value);
      },
    },
    germanTranslationLong: {
      get() {
        return this.currentProductCreation.translations[1].longDescription;
      },
      set(value) {
        this[SET_PRODUCT_CREATION_TRANSLATION_GERMAN_LONG](value);
      },
    },
  },

  methods: {
    ...mapMutations([
      SET_PRODUCT_CREATION_TRANSLATION_ENGLISH_SHORT,
      SET_PRODUCT_CREATION_TRANSLATION_ENGLISH_LONG,
      SET_PRODUCT_CREATION_TRANSLATION_GERMAN_SHORT,
      SET_PRODUCT_CREATION_TRANSLATION_GERMAN_LONG,
    ]),
    resetTabs() {
      this.translationTabs = 0;
    },
    resizeTabs() {
      this.$refs.translationTabs.onResize();
    },
  },
};
</script>
