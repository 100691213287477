<template>
  <div
    class="
    lg:text-text 
    lg:bg-interface
    flex 
    justify-between 
    items-center 
    px-3
    py-6
    lg:py-2 
    lg:mb-4 
    h-10"
    :class="{
      'bg-akzent text-background ': mobileStyle === 1,
      'border-t border-b lg:py-4 lg:border-0': 
        mobileStyle === 2,
    }"
    :style="{
      'border-color': 'rgba(223, 223, 225, 0.5)'
    }"
  >
    <div class="inline-flex flex-shrink-0 text-xs uppercase font-semibold tracking-widest">
      <slot />
    </div>
    <div
      class="inline-flex items-center"
      :class="{
        'invisible lg:visible': !showContext,
      }"
    >
      <slot name="right" />
    </div>
    <slot name="custom" />
  </div>
</template>

<script>
export default {
  props: {
    mobileStyle: {
      type: Number,
      default: 1,
    },
  },

  data: () => ({
    showContext: false,
  }),

  methods: {
    toggleContext() {
      this.showContext = !this.showContext;
    },
  },
};
</script>
