<template>
  <div>
    <form @submit.prevent="onRegister">
      <div :class="fieldStyles">
        <label for="email-input" :class="labelStyles">{{ $t('email') }}</label>
        <input
          id="email-input"
          v-model="registerForm.email"
          type="email"
          :class="inputStyles"
          :placeholder="$t('email')"
          required
        >
      </div>
      <div :class="fieldStyles">
        <label for="password-input" :class="labelStyles">{{ $t('password') }}</label>
        <input
          id="password-input"
          v-model="registerForm.password"
          type="password"
          :class="inputStyles"
          :placeholder="$t('password')"
          minlength="6"
          required
          @focus="passwordFieldIsFocused = true"
          @blur="passwordFieldIsFocused = false"
        >
      </div>
      <div :class="fieldStyles">
        <label 
          for="password-repeat-input" 
          :class="labelStyles"
        >
          {{ $t('repeatPassword') }}
        </label>
        <input
          id="password-repeat-input"
          v-model="registerForm.passwordRepeat"
          type="password"
          :class="inputStyles"
          :placeholder="$t('repeatPassword')"
          required
          minlength="6"
          @focus="passwordFieldIsFocused = true"
          @blur="passwordFieldIsFocused = false"
        >
      </div>
      <div :class="fieldStyles">
        <label for="first-name-input" :class="labelStyles">{{ $t('firstName') }}</label>
        <input
          id="first-name-input"
          v-model="registerForm.firstName"
          :class="inputStyles"
          :placeholder="$t('firstName')"
          required
        >
      </div>
      <div :class="fieldStyles">
        <label for="last-name-input" :class="labelStyles">{{ $t('lastName') }}</label>
        <input
          id="last-name-input"
          v-model="registerForm.lastName"
          :class="inputStyles"
          :placeholder="$t('lastName')"
          required
        >
      </div>
      <div class="mt-3 pb-5 flex items-center">
        <label class="custom-checkbox">
          <span>{{ $t('acceptThe') }} </span>
          <span 
            class="underline cursor-pointer" 
            @click="$event.preventDefault(); showAgbOverlay = true"
          >
            {{ $t('termsAndCondtions') }}
          </span>

          <input v-model="registerForm.acceptedAgb" type="checkbox" required>
          <span 
            class="checkmark"
            @click="registerForm.acceptedAgb = !!registerForm.acceptedAgb "
          />
        </label>
      </div>
      <div class="pb-3 flex items-center">
        <label class="custom-checkbox">
          <span>{{ $t('isAdult') }} </span>

          <input v-model="registerForm.isAdult" type="checkbox" required>
          <span class="checkmark" @click="registerForm.isAdult = !!registerForm.isAdult" />
        </label>
      </div>

      <transition name="fade">
        <div v-if="emailInUse" class="text-red-600 text-xs">{{ $t('emailInUse') }}</div>
        <div 
          v-if="
            registerForm.password !== registerForm.passwordRepeat && 
              registerForm.passwordRepeat && registerForm.password &&
              !passwordFieldIsFocused
          " 
          class="text-red-600 text-xs"
        >
          {{ $t('invalidPasswordRepeat') }}
        </div>
      </transition>
          
          
      <div class="flex items-center mt-2">
        <Button type="submit">
          {{ $t('register') }}
        </Button>
        <div
          class="text-xs inline-block ml-3 cursor-pointer line hover:opacity-75"
          @click="$emit('changeMode', 'login')"
        >
          {{ $t('alreadyRegistered') }}
        </div>
      </div>
    </form>
    <Overlay v-model="showAgbOverlay" close-icon>
      <div class="max-w-4xl cms-content h-screen overflow-y-auto">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="agbContent" />
      </div>
    </Overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    registerForm: {
      email: '',
      password: '',
      passwordRepeat: '',
      firstName: '',
      lastName: '',
      acceptedAgb: false,
      isAdult: false,
    },
    showAgbOverlay: false,
    agbContent: null,
    agbVersion: null,
    passwordFieldIsFocused: false,
    emailInUse: false,
    fieldStyles: 'pb-3',
    labelStyles: 'pl-2 block uppercase text-xxs tracking-wide',
    inputStyles: 'w-full bg-text py-1 pl-2 text-black text-base lg:text-xs',
  }),

  computed: {
    ...mapGetters(['hasLoginError', 'hasRegisterError']),
  },

  watch: {
    registerForm: {
      deep: true,
      handler() {
        this.emailInUse = false;
      },
    },
  },

  async created() {
    const resp = await this.$axios(
      `${process.env.VUE_APP_DIRECTUS_URL}/items/agb?fields=*,translations.*&filter[active]=true`,
    );
    this.agbContent = resp.data.data[0].translations.find((translation) =>
      translation.languages_code.startsWith(this.$i18n.locale),
    ).content;
    this.agbVersion = resp.data.data[0].version;
  },

  methods: {
    ...mapActions(['login', 'signup']),
    registerFormIsValid() {
      if (
        !this.registerForm.email ||
        !this.registerForm.password ||
        !this.registerForm.passwordRepeat ||
        !this.registerForm.firstName ||
        !this.registerForm.lastName ||
        !this.registerForm.acceptedAgb ||
        !this.registerForm.isAdult ||
        this.emailInUse
      ) {
        return false;
      }

      if (this.registerForm.password !== this.registerForm.passwordRepeat) {
        return false;
      }

      return true;
    },

    async onRegister() {
      if (this.registerFormIsValid()) {
        await this.signup({
          email: this.registerForm.email,
          password: this.registerForm.password,
          firstName: this.registerForm.firstName,
          lastName: this.registerForm.lastName,
          agbVersion: this.agbVersion,
          language: this.$i18n.locale ? this.$i18n.locale : 'en',
        });

        if (!this.hasRegisterError) {
          await this.login({
            email: this.registerForm.email,
            password: this.registerForm.password,
          });

          if (!this.hasLoginError) {
            this.$router.push('/confirm-email');
          }
        } else {
          this.emailInUse = true;
        }
      }
    },
  },
};
</script>
