import type { Params } from '@feathersjs/feathers';
import type { TFindResponse, TGetResponse } from '@/types/Api';
import type { IProductFile, IProductFileType } from '@/types/ProductFile';
import { client, isWsClient } from '../client';

const service = client.service('product-files');
const EVENTS = ['patched', 'removed', 'created'];

const findProductsFiles = (params?: Params): TFindResponse<IProductFile> => {
  return service.find(params || {});
};

const getProductsFile = (id: number, params?: Params): TGetResponse<IProductFile> => {
  return service.get(id, params);
};

const createProductFile = (
  data: IProductFile,
  params?: Params,
): TGetResponse<IProductFile | IProductFile[]> => {
  return service.create(data, params);
};

const removeProductFile = (id: number, params?: Params): TGetResponse<IProductFile> => {
  return service.remove(id, params);
};

const addTypeToProductFile = (
  productFileId: number,
  type: IProductFileType,
  params?: Params,
): TGetResponse<IProductFile> => {
  return client.service(`product-files/${productFileId}/types`).create(type, params);
};

const removeTypeFromProductFile = (
  productFileId: number,
  typeName: string,
  params?: Params,
): TGetResponse<IProductFile> => {
  return client.service(`product-files/${productFileId}/types`).remove(typeName, params);
};

const removeEventListener = () => {
  EVENTS.forEach((event) => {
    service.removeAllListeners(event);
  });
};

// eslint-disable-next-line no-unused-vars
const listenToEvents = (emit: (event: string, payload: any) => void) => {
  if (isWsClient) {
    EVENTS.forEach((event) => {
      service.on(event, (payload: any) => emit(event, payload));
    });
  }
};

export const productFilesApi = {
  findProductsFiles,
  getProductsFile,
  createProductFile,
  removeProductFile,
  addTypeToProductFile,
  removeTypeFromProductFile,
  removeEventListener,
  listenToEvents,
};
