<template>
  <div>
    <SeperationBox>
      <template>
        {{ $t('recentUpdates') }}
      </template>
    </SeperationBox>
    <div class="grid lg:grid-cols-2 lg:gap-3">
      <router-link 
        v-for="product in allProducts" 
        :key="`recent-products-${product.id}`"
        :to="`/product/${product.id}`"
        class="
          grid sm:grid-cols-2 gap-3 pb-3 lg:pb-0 hover:opacity-75 transition-opacity duration-150
        "
      >
        <ProductListItem
          :to="`/product/${product.id}`"
          :title="product.title"
          :type="product.type"
          :price="product.credits"
          :thumbnail="getThumbnail(product)"
          :availability="product.accessStatus"
          :pinned="product.pinned"
          hide-details
          disable-hover-effect
          :active-subscription="activeSubscription"
        />
        <div class="px-3 py-2 ">
          <div class="font-bold text-sm">{{ product.title }}</div>
          <div class="mt-4 grid grid-cols-2 gap-2 uppercase text-xxs font-semibold tracking-wide">
            <div>{{ $t('categories') }}:</div>
            <div>
              <span 
                v-for="(categorie, i) in product.categories" 
                :key="`recent-prodcut-categorie-${categorie.id}`"
              >
                {{ categorie.name }}{{ i !== product.categories.length - 1 ? ', ' : '' }}
              </span>
            </div>

            <div>{{ $t('models') }}:</div>
            <div>
              <span 
                v-for="(model, i) in product.models" 
                :key="`recent-prodcut-model-${model.id}`"
              >
                {{ model.name }}{{ i !== product.models.length - 1 ? ', ' : '' }}
              </span>
            </div>

            <div>{{ $t('price') }}:</div>
            <div>{{ product.credits }} {{ $t('credits') }} </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SeperationBox from '../SeperationBox/index.vue';
import ProductListItem from '../ProductListItem/index.vue';

export default {
  components: {
    SeperationBox,
    ProductListItem,
  },

  computed: {
    ...mapGetters(['allProducts', 'activeSubscription']),
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        this.findProducts({
          query: {
            $eager: {
              files: { types: true },
              categories: true,
              models: true,
            },
            type: {
              $ne: 'bundle',
            },
            editing: false,
            status: 'processed',
            visable: 1,
            $sort: {
              createdAt: -1,
            },
            $limit: 10,
            onlyThumbnails: true,
          },
          refresh: true,
        });
      },
    },
  },

  methods: {
    ...mapActions(['findProducts']),
    getThumbnail(product) {
      let { files } = product;
      if (product.type === 'bundle') {
        files = product.bundleProducts[0].files;
      }

      const thumbnailFile = files.find(
        (file) => file.types && file.types.some((type) => type.name === 'thumbnail'),
      );
      return thumbnailFile ? thumbnailFile.url : '';
    },
  },
};
</script>
