<template>
  <router-link
    v-slot="{ href, isExactActive, navigate }"
    :to="to"
    custom
  >
    <a :href="href" class="group focus:outline-none">
      <span
        class="relative flex h-10 items-center uppercase transition duration-150 ease-in-out
        font-bold text-xs tracking-wider px-2 border-b-3 group-focus:text-akzent"
        :class="{
          'border-transparent hover:text-akzent': !isExactActive,
          'border-akzent': isExactActive,
        }"
        @click="navigate"
      >
        <span class="block">
          <slot />
        </span>
      </span>
    </a>
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: true,
    },
  },
};
</script>
