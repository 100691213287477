<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          Konto-Übersicht
          <v-spacer />
          Credits insgesamt: {{ totalUserCredits }}
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="allUserCredits"
          :loading="userCreditsLoading"
          :options.sync="options"
          :server-items-length="userCreditsTotal"
          :footer-props="{
            'items-per-page-options': [5, 10, 15, 30, 50],
            'items-per-page-text': 'Zeilen pro Seite',
          }"
          :multi-sort="true"
        > 
          <template v-slot:[`item.amount`]="{ item }">
            <span>{{ item.amount }}</span>
          </template>
          <template v-slot:[`item.origin`]="{ item }">
            <span v-if="!(item.product && item.product.title)">{{ item.origin }}</span>
            <span v-else>Einkauf: {{ JSON.stringify(item.product.title) }}</span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <span>{{ item.createdAt | formatDate }}</span>
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            <span>{{ item.updatedAt | formatDate }}</span>
          </template>
          <template v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }">
            {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
          </template>
        </v-data-table>
        <v-card-actions>
          <v-btn @click="dialog = true"> +/- Credits</v-btn>
        </v-card-actions>
        <v-dialog v-model="dialog" width="300">
          <v-card>
            <v-card-title>Credits</v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="fromIsValid" lazy-validation>
                <v-text-field
                  v-model.number="newUserCredit.amount"
                  label="Credits"
                  type="number"
                  :rules="[
                    v => v !== '' || 'Credits ist erforderlich',
                    v => v !== 0 || 'Credits dürfen nicht 0 sein'
                  ]"
                  required
                />

                <v-text-field
                  v-model="newUserCredit.origin"
                  :counter="50"
                  :rules="[v => !!v || 'Aktion ist erforderlich']"
                  label="Aktion"
                  required
                />
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn @click="save">
                Speichern
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { userCreditsApi } from '../../../../api';

export default {
  data() {
    return {
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
      },
      headers: [
        {
          text: 'Bestell-Nr.',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Credits',
          sortable: true,
          value: 'amount',
        },
        {
          text: 'Aktion',
          sortable: true,
          value: 'origin',
        },
        {
          text: 'Zuletzt geändert am',
          sortable: true,
          value: 'updatedAt',
        },
        {
          text: 'Erstellt am',
          sortable: true,
          value: 'createdAt',
        },
      ],
      totalUserCredits: 0,
      dialog: false,
      newUserCredit: {
        amount: 0,
        origin: '',
      },
      fromIsValid: true,
    };
  },

  computed: {
    ...mapGetters(['selectedUser', 'allUserCredits', 'userCreditsTotal', 'userCreditsLoading']),
  },

  watch: {
    options: {
      handler() {
        this.findUsersCreditsForDataTable();
      },
      deep: true,
    },
    dialog: {
      handler() {
        if (!this.dialog) {
          this.$refs.form.reset();
          this.$nextTick(() => {
            this.newUserCredit = {
              amount: 0,
              origin: '',
            };
          });
        }
      },
    },
  },

  async created() {
    const [, resp] = await Promise.all([
      this.findUsersCreditsForDataTable({
        userId: this.selectedUser.id,
      }),
      userCreditsApi.getTotalUserCredits(this.selectedUser.id),
    ]);
    this.totalUserCredits = resp.totalCredits;
  },

  methods: {
    ...mapActions(['getUser', 'findUserCredits', 'createUserCredit']),

    findUsersCreditsForDataTable() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.options;

      const sortQuery = {};
      if (sortBy.length) {
        sortBy.forEach((key, i) => {
          if (sortDesc[i]) {
            sortQuery[key] = -1;
          } else {
            sortQuery[key] = +1;
          }
        });
      }

      return this.findUserCredits({
        query: {
          $limit: itemsPerPage,
          $skip: itemsPerPage * (page - 1),
          $sort: sortQuery,
          userId: this.selectedUser.id,
          $eager: '[product]',
        },
        refresh: true,
      });
    },

    async save() {
      const valid = this.$refs.form.validate();
      if (valid) {
        const data = {
          userId: this.selectedUser.id,
          amount: Math.ceil(this.newUserCredit.amount),
          origin: this.newUserCredit.origin,
        };

        await this.createUserCredit({
          data,
        });

        this.findUsersCreditsForDataTable();

        userCreditsApi.getTotalUserCredits(this.selectedUser.id).then((resp) => {
          this.totalUserCredits = resp.totalCredits;
        });

        this.dialog = false;
      }
    },
  },
};
</script>
