<template>
  <div>
    <div class="relative">
      <div 
        v-if="currentProduct.accessStatus > 0" 
        class="absolute w-full h-full flex items-center justify-center cursor-pointer"
        @click="showImageSlider = true"
      >
        <div class="bg-interface p-6 rounded-full">
          <Icon name="view" :size="32" custom-view-box="0 0 24 24" />
        </div>
      </div>
      <div
        :style="{
          backgroundImage: `url(&quot;${thumbnail.url}&quot;)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          'padding-bottom': '56.25%',
        }"
      />
    </div>

    <FullScreenImageSlider 
      v-model="showImageSlider" 
      :images="allFotosetImages" 
      :items-per-page="1" 
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      showImageSlider: false,
    };
  },

  computed: {
    ...mapGetters(['currentProduct']),
    thumbnail() {
      return this.currentProduct.files.find((file) =>
        file.types.some((type) => type.name === 'thumbnail'),
      );
    },
    allFotosetImages() {
      return this.currentProduct.files.filter((file) =>
        file.types.some((type) => type.name === 'fotosetImage'),
      );
    },
  },
};
</script>
