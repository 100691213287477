import { modelsApi } from '@/api';
import type { Module } from 'vuex';
import type { TRootStore } from '@/store';
import type { IModel } from '@/types/Model';
import type { IFindParams } from '@/types/Api';
import { SET_RECENT_MODELS, RESET_RECENT_MODELS } from './storeTypes/mutationTypes';

export interface IRecentModelsStoreState {
  models: IModel[];
  loading: boolean;
  page: number;
  total: number;
  endOfList: boolean;
  limit: number;
}

type TRecentModelsStore = Module<IRecentModelsStoreState, TRootStore>;

const RecentStore: TRecentModelsStore = {
  state: () => ({
    loading: false,
    models: [],
    page: 0,
    total: 0,
    endOfList: false,
    limit: 5,
  }),

  getters: {
    allRecentModels: (state) => state.models,
    allRecentModelsLoaded: (state) => state.endOfList,
    recentModelsLoading: (state) => state.loading,
    recentModelsTotal: (state) => state.total,
    recentModelLimit: (state) => state.limit,
  },

  mutations: {
    [SET_RECENT_MODELS](state, { data }: { data: IModel[] }) {
      state.models = [...state.models, ...data];
      if (state.total > state.page * state.limit) {
        state.endOfList = false;
      } else {
        state.endOfList = true;
      }
    },
    [RESET_RECENT_MODELS](state) {
      state.page = 0;
      state.models = [];
    },
  },

  actions: {
    async findRecentModels({ commit, state }, params: IFindParams) {
      state.loading = true;
      const { refresh, query, ...restParams } = params || {};
      if (refresh) {
        commit(RESET_RECENT_MODELS);
      }

      const limit: number = params?.query?.$limit || 5;
      if (state.total > limit * state.page || refresh) {
        const { data, total } = await modelsApi.findModels({
          query: {
            $limit: limit,
            $skip: !params?.refresh ? limit * state.page : 0,
            ...(query || {}),
          },
          ...(restParams || {}),
        });
        state.limit = limit;
        state.total = total;
        state.page += 1;
        commit(SET_RECENT_MODELS, { data, refresh: params?.refresh || false });
      }
      state.loading = false;
    },
  },
};

export default RecentStore;
