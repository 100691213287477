import { productsApi, bundlesApi } from '@/api';
import type { Module } from 'vuex';
import type { TRootStore } from '@/store';
import type { IProduct } from '@/types/Products';
import type { IFindParams } from '@/types/Api';
import {
  SET_BUNDLE_PRODUCTS,
  SET_BUNDLE_PRODUCT,
  RESET_BUNDLE_PRODUCTS,
  OPEN_BUNDLE_PRODUCT,
  REMOVE_BUNDLE_PRODUCT,
  REMOVE_PRODUCT_FROM_BUNDLE,
  ADD_PRODUCT_TO_BUNDLE,
} from './storeTypes/mutationTypes';

export interface IBundleProductsStoreState {
  products: IProduct[];
  loading: boolean;
  page: number;
  total: number;
  endOfList: boolean;
  selectedProduct: number | null;
  limit: number;
}

type TBunldeProductsStore = Module<IBundleProductsStoreState, TRootStore>;

const BundleProductsStore: TBunldeProductsStore = {
  state: () => ({
    loading: false,
    products: [],
    page: 0,
    total: 0,
    endOfList: false,
    selectedProduct: null,
    limit: 6,
  }),

  getters: {
    allBundleProducts: (state) => state.products,
    allBundleProductsLoaded: (state) => state.endOfList,
    currentBundleProduct: (state) =>
      state.products.find((product) => state.selectedProduct === product.id) || null,
    bundleProductsLoading: (state) => state.loading,
    bundleProductsTotal: (state) => state.total,
  },

  mutations: {
    [SET_BUNDLE_PRODUCTS](state, { data }: { data: IProduct[] }) {
      state.products = [...state.products, ...data];
      if (state.total > state.page * state.limit) {
        state.endOfList = false;
      } else {
        state.endOfList = true;
      }
    },
    [RESET_BUNDLE_PRODUCTS](state) {
      state.page = 0;
      state.products = [];
    },
    [OPEN_BUNDLE_PRODUCT](state, id: number) {
      state.selectedProduct = id;
    },
    [SET_BUNDLE_PRODUCT](state, productDetail: IProduct) {
      const index = state.products.findIndex((product) => productDetail.id === product.id);
      if (index !== -1) {
        state.products[index] = productDetail;
      } else {
        state.products.push(productDetail);
      }
      state.products = [...state.products];
    },
    [REMOVE_BUNDLE_PRODUCT](state, productDetail: IProduct) {
      const index = state.products.findIndex((product) => productDetail.id === product.id);
      if (index !== -1) {
        state.products.splice(index, 1);
      }
    },
    [REMOVE_PRODUCT_FROM_BUNDLE](
      state,
      { bundleId, productId }: { bundleId: number; productId: number },
    ) {
      const index = state.products.findIndex((product) => bundleId === product.id);
      if (index !== -1 && state.products[index].bundleProducts) {
        const i = state.products[index].bundleProducts.findIndex(
          (bundleProduct) => bundleProduct.id === productId,
        );
        if (i !== -1) {
          state.products[index].bundleProducts.splice(i, 1);
        }
      }
    },
    [ADD_PRODUCT_TO_BUNDLE](
      state,
      { bundleId, bundeProduct }: { bundleId: number; bundeProduct: IProduct },
    ) {
      const index = state.products.findIndex((product) => bundleId === product.id);
      if (index !== -1 && state.products[index].bundleProducts) {
        state.products[index].bundleProducts.push(bundeProduct);
      }
    },
  },

  actions: {
    async findBundleProducts({ commit, state }, params: IFindParams) {
      state.loading = true;
      const { refresh, ...restParams } = params || {};
      let { query } = params || {};
      if (refresh) {
        commit(RESET_BUNDLE_PRODUCTS);
      }
      // @ts-ignore
      const limit: number = params?.query?.$limit;
      if (!query) {
        query = {};
      }
      query.type = 'bundle';
      if (state.total > limit * state.page || refresh) {
        const { data, total } = await productsApi.findProducts({
          query: {
            $limit: limit,
            $skip: !params?.refresh ? limit * state.page : 0,
            ...(query || {}),
          },
          ...(restParams || {}),
        });
        state.limit = limit;
        state.total = total;
        state.page += 1;
        commit(SET_BUNDLE_PRODUCTS, { data, refresh: params?.refresh || false });
      }
      state.loading = false;
    },

    async addProductToBundle(
      { commit, state },
      { bundleId, productId }: { bundleId: number; productId: number },
    ) {
      state.loading = true;

      const params = {
        query: { $eager: '[product]' },
      };

      const bundleProduct = await bundlesApi.addProductToBundle(bundleId, productId, params);
      commit(ADD_PRODUCT_TO_BUNDLE, {
        bundleId,
        product: bundleProduct.product,
      });

      state.loading = false;
      return bundleProduct.product;
    },

    async removeProductFromBundle(
      { commit, state },
      { bundleId, productId }: { bundleId: number; productId: number },
    ) {
      state.loading = true;

      const params = {
        query: { $eager: '[product]' },
      };

      const bundleProduct = await bundlesApi.removeProductFromBundle(bundleId, productId, params);
      commit(REMOVE_BUNDLE_PRODUCT, {
        bundleId,
        productId,
      });

      state.loading = false;
      return bundleProduct.product;
    },
  },
};

export default BundleProductsStore;
