import type { Params } from '@feathersjs/feathers';
import type { TFindResponse, TGetResponse } from '@/types/Api';
import type { ICategory } from '@/types/Categories';
import { client } from '../client';

const service = client.service('categories');

const findCategories = (params?: Params): TFindResponse<ICategory> => {
  return service.find({
    ...(params || {}),
    query: {
      $limit: 30,
      ...(params?.query || {}),
    },
  });
};

const getCategorie = (id: number, params?: Params): TGetResponse<ICategory> => {
  return service.get(id, params);
};

const patchCategorie = (id: number, data: any, params?: Params): TGetResponse<ICategory> => {
  return service.patch(id, data, params);
};

const createCategorie = (data: ICategory, params?: Params): TGetResponse<ICategory> => {
  return service.create(data, params);
};

const removeCategorie = (id: number, params?: Params): TGetResponse<ICategory> => {
  return service.remove(id, params);
};

export const categoriesApi = {
  findCategories,
  getCategorie,
  patchCategorie,
  createCategorie,
  removeCategorie,
};
