import type { Module } from 'vuex';
import type { TRootStore } from '@/store';
import type { IUser, TUserLanguages } from '@/types/Auth';
import {
  usersApi,
  userCreditsApi,
  ageVerificationApi,
  subscriptionsApi,
  maxpaySubscriptionsApi,
} from '@/api';
import { ISubscription } from '@/types/Subscription';
import {
  SET_CREDITS,
  SET_SPENT_CREDITS,
  SET_LANGUAGE,
  SET_CURRENT_USER,
  SET_PENDING_AGE_VERIFICATION,
  SET_ACTIVE_SUBSCRIPTION,
  SET_EXPIRED_SUBSCRIPTION,
  SET_AGB_VERSION,
} from './storeTypes/mutationTypes';

export interface IUserStoreState {
  user: IUser | null;
  credits: number;
  spentCredits: number;
  pendingAgeVerificaition: boolean;
  activeSubscription: ISubscription | null;
  expiredSubscription: ISubscription | null;
}

type IUserStore = Module<IUserStoreState, TRootStore>;

// const tokenKey = 'pm-access-token';

const userStore: IUserStore = {
  state: () => ({
    user: null,
    credits: -1,
    spentCredits: 0,
    pendingAgeVerificaition: false,
    activeSubscription: null,
    expiredSubscription: null,
  }),

  getters: {
    currentUser: (state) => state.user,
    totalCredits: (state) => state.credits,
    spentCredits: (state) => state.spentCredits,
    hasPendingAgeVerification: (state) => state.pendingAgeVerificaition,
    activeSubscription: (state) => state.activeSubscription,
    expiredSubscription: (state) => state.expiredSubscription,
  },

  mutations: {
    [SET_CURRENT_USER](state, user: IUser | null) {
      state.user = user;
    },
    [SET_CREDITS](state, credits: number) {
      state.credits = credits;
    },
    [SET_SPENT_CREDITS](state, spentCredits: number) {
      state.spentCredits = spentCredits;
    },
    [SET_LANGUAGE](state, language: TUserLanguages) {
      if (state.user) state.user.language = language;
    },
    [SET_AGB_VERSION](state, version: string) {
      if (state.user) state.user.agbVersion = version;
    },
    [SET_PENDING_AGE_VERIFICATION](state, value: boolean) {
      state.pendingAgeVerificaition = value;
    },
    [SET_ACTIVE_SUBSCRIPTION](state, subscription: ISubscription) {
      state.activeSubscription = subscription;
    },
    [SET_EXPIRED_SUBSCRIPTION](state, subscription: ISubscription) {
      state.expiredSubscription = subscription;
    },
  },

  actions: {
    patchLanguage({ commit, state }, language: TUserLanguages) {
      if (state.user) {
        usersApi.patchUser(state.user.id, {
          language,
        });
        commit(SET_LANGUAGE, language);
      }
    },
    async getTotalAndSpentCredits({ commit, state }) {
      if (state.user) {
        const { totalCredits, spentCredits } = await userCreditsApi.getTotalUserCredits(
          state.user.id,
          {
            query: {
              spentCredits: true,
            },
          },
        );
        commit(SET_CREDITS, totalCredits);
        commit(SET_SPENT_CREDITS, spentCredits);
      }
    },
    async getPendingAgeVerification({ commit, state }) {
      if (state.user && state.user.role === 'verifiedUser') {
        const { total } = await ageVerificationApi.findAgeVerifications({
          query: {
            status: 'pending',
          },
        });
        if (total > 0) {
          commit(SET_PENDING_AGE_VERIFICATION, true);
        }
      }
    },
    async getActiveSubscription({ commit, state }) {
      if (state.user && state.user.role === 'subscriber') {
        const { total, data } = await maxpaySubscriptionsApi.findSubscriptions({
          query: {
            active: 1,
            $eager: '[subscriptionPlan]',
            $limit: 1,
          },
        });
        if (total > 0) {
          commit(SET_ACTIVE_SUBSCRIPTION, data[0]);
        }
      }
    },
    async cancelActiveSubscription({ commit, state }) {
      if (state.activeSubscription) {
        const subscription = await maxpaySubscriptionsApi.patchSubscription(
          state.activeSubscription.id,
          { canceled: true },
          {
            query: {
              $eager: '[subscriptionPlan]',
            },
          },
        );
        commit(SET_ACTIVE_SUBSCRIPTION, subscription);
      }
    },
    async getExpiredSubscription({ commit, state }) {
      if (state.user && state.user.role === 'adultConfirmed') {
        const { total, data } = await maxpaySubscriptionsApi.findSubscriptions({
          query: {
            expiryNoted: 0,
            $eager: '[subscriptionPlan]',
            $limit: 1,
            $sort: {
              createdAt: -1,
            },
          },
        });
        if (total > 0) {
          commit(SET_EXPIRED_SUBSCRIPTION, data[0]);
        }
      }
    },
    async noteExpiredSubscription({ commit, state }) {
      if (state.expiredSubscription) {
        await maxpaySubscriptionsApi.patchSubscription(state.expiredSubscription.id, {
          expiryNoted: true,
        });
        commit(SET_EXPIRED_SUBSCRIPTION, null);
      }
    },
    async renewExpiredSubscription({ commit, state }) {
      if (state.expiredSubscription) {
        let subscription = await subscriptionsApi.createSubscription({
          subscriptionPlanId: state.expiredSubscription.subscriptionPlanId,
          paymentTypeId: state.expiredSubscription.paymentTypeId,
        });
        subscription = await subscriptionsApi.patchSubscription(
          subscription.id,
          {
            confirmed: true,
          },
          {
            query: {
              $eager: '[subscriptionPlan]',
            },
          },
        );

        commit(SET_ACTIVE_SUBSCRIPTION, subscription);
        commit(SET_CURRENT_USER, { ...state.user, role: 'subscriber' });
      }
    },
    async acceptNewAgbs({ commit, state }, version: string) {
      if (state.user) {
        usersApi.patchUser(state.user.id, {
          agbVersion: version,
        });
        commit(SET_AGB_VERSION, version);
      }
    },

    initUser({ dispatch }) {
      dispatch('getTotalAndSpentCredits');
      dispatch('getPendingAgeVerification');
      dispatch('getActiveSubscription');
      dispatch('getExpiredSubscription');
    },
  },
};

export default userStore;
