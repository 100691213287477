<template>
  <Overlay v-model="ageVerficationReminderOverlay">
    <div class="max-w-xs text-sm text-center">
      <div>
        {{ $t('ageVerificationInstructions') }}
      </div>
      <Button class="mt-3" @click="goToAgeVerificationPage">
        {{ $t('goToAgeVerification') }}
      </Button>
    </div>
  </Overlay>
</template>

<script>
import { SET_SHOW_LOGIN_MESSAGE } from '@/store/storeTypes/mutationTypes';
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      ageVerficationReminderOverlay: true,
    };
  },
  methods: {
    ...mapMutations([SET_SHOW_LOGIN_MESSAGE]),
    goToAgeVerificationPage() {
      this.$router.push('/verify-age');
      this[SET_SHOW_LOGIN_MESSAGE](false);
    },
  },
};
</script>
