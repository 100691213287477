<template>
  <div>
    <v-container fluid>
      <div>Kategorien</div>
      <v-row>
        <v-col cols="12">
          <v-chip-group
            column
          >
            <v-chip
              v-for="(categorie, i) in currentProductCreation.categories"
              :key="`categorie-${i}`"
              close
              @click:close="removeCategorie(categorie.id)"
            > 
              {{ categorie.name }} 
            </v-chip>
            <v-chip @click="openCategoriesSelectionDialog = true">
              <v-icon>add</v-icon>
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
      <SelectionDialog
        v-model="openCategoriesSelectionDialog"
        :items="allCategories"
        :filter-items="currentProductCreation.categories"
        :item-text="'name'"
        :item-value="'id'"
        :loading="categoriesLoading"
        :all-items-loaded="allCategoriesLoaded"
        return-object
        @selectionCompleted="addCategories"
        @load-more="lazyLoadCategories"
        @search="updateCategoriesSearch"
      > 
        <template v-slot:submit-button>
          Hinzufügen
        </template>
        <template v-slot:no-options>
          Keine weiteren Kategorien verfügbar.
        </template>
      </SelectionDialog>

      <div>Models</div>
      <v-row>
        <v-col cols="12">
          <v-chip-group
            column
          >
            <v-chip
              v-for="(model, i) in currentProductCreation.models"
              :key="`model-${i}`"
              close
              @click:close="removeModel(model.id)"
            > 
              {{ model.name }} 
            </v-chip>
            <v-chip @click="openModelsSelectionDialog = true">
              <v-icon>add</v-icon>
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
      <SelectionDialog
        v-model="openModelsSelectionDialog"
        :items="allModels"
        :filter-items="currentProductCreation.models"
        :item-text="'name'"
        :item-value="'id'"
        :loading="modelsLoading"
        :all-items-loaded="allModelsLoaded"
        return-object
        @selectionCompleted="addModels"
        @load-more="lazyLoadModels"
        @search="updateModelsSearch"
      > 
        <template v-slot:submit-button>
          Hinzufügen
        </template>
        <template v-slot:no-options>
          Keine weiteren Models verfügbar.
        </template>
      </SelectionDialog>
    </v-container>
            
    <v-btn
      text
      @click="$emit('back')"
    >
      Zurück
    </v-btn>
    <v-btn
      color="primary"
      @click="$emit('next')"
    >
      Weiter
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import {
  ADD_PRODUCT_CREATION_CATEGORIES,
  REMOVE_PRODUCT_CREATION_CATEGORIE,
  ADD_PRODUCT_CREATION_MODELS,
  REMOVE_PRODUCT_CREATION_MODEL,
} from '../../../../store/storeTypes/mutationTypes';
import SelectionDialog from '../SelectionDialog/index.vue';

export default {
  components: {
    SelectionDialog,
  },

  data() {
    return {
      openCategoriesSelectionDialog: false,
      openModelsSelectionDialog: false,
      categoriesQuery: {},
      modelsQuery: {},
    };
  },

  computed: {
    ...mapGetters([
      'currentProductCreation',
      'allCategories',
      'allModels',
      'categoriesLoading',
      'allCategoriesLoaded',
      'modelsLoading',
      'allModelsLoaded',
    ]),
  },

  watch: {
    openCategoriesSelectionDialog(val) {
      if (val) {
        this.categoriesQuery = {
          $limit: 25,
          name: {
            $like: '%%',
          },
        };
        this.findCategories({
          query: this.categoriesQuery,
          refresh: true,
        });
      }
    },
    openModelsSelectionDialog(val) {
      if (val) {
        this.modelsQuery = {
          $limit: 25,
          name: {
            $like: '%%',
          },
        };
        this.findModels({
          query: this.modelsQuery,
          refresh: true,
        });
      }
    },
  },

  methods: {
    ...mapActions(['findCategories', 'findModels']),
    ...mapMutations([
      ADD_PRODUCT_CREATION_CATEGORIES,
      REMOVE_PRODUCT_CREATION_CATEGORIE,
      ADD_PRODUCT_CREATION_MODELS,
      REMOVE_PRODUCT_CREATION_MODEL,
    ]),
    lazyLoadCategories() {
      this.findCategories({
        query: this.categoriesQuery,
        refresh: false,
      });
    },
    lazyLoadModels() {
      this.findModels({
        query: this.modelsQuery,
        refresh: false,
      });
    },
    updateCategoriesSearch(value) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.categoriesQuery.name.$like = `%${value}%`;

        this.findCategories({
          query: this.categoriesQuery,
          refresh: true,
        });
      }, 275);
    },
    updateModelsSearch(value) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.modelsQuery.name.$like = `%${value}%`;

        this.findModels({
          query: this.modelsQuery,
          refresh: true,
        });
      }, 275);
    },
    addCategories(categories) {
      this[ADD_PRODUCT_CREATION_CATEGORIES](categories);
    },
    removeCategorie(categorieId) {
      this[REMOVE_PRODUCT_CREATION_CATEGORIE](categorieId);
    },
    addModels(models) {
      this[ADD_PRODUCT_CREATION_MODELS](models);
    },
    removeModel(modelId) {
      this[REMOVE_PRODUCT_CREATION_MODEL](modelId);
    },
  },
};
</script>
