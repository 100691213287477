<template>
  <v-card>
    <v-card-title>Produkteigentschaften</v-card-title>
        
    <v-card-text>
      <v-form ref="form" v-model="fromIsValid" lazy-validation>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="productProperties.orderId"
              :counter="256"
              label="Bestellnummber"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="productProperties.title"
              :counter="256"
              :rules="[v => !!v || 'Titel ist erforderlich']"
              label="Titel"
              required
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="productProperties.keywords"
              label="Keywords"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.number="productProperties.credits"
              label="Credits"
              type="number"
              :rules="[
                v => v !== '' || 'Credits ist erforderlich',
                v => v >= 0 || 'Credits können nicht negativ sein',
              ]"
              required
            />
          </v-col>
          <v-col v-if="currentProduct.type === 'movie'" cols="6">
            <v-text-field
              v-model="productProperties.length"
              :counter="256"
              label="Länge"
            />
          </v-col>
          <v-col v-if="currentProduct.type === 'fotoset'" cols="6">
            <v-text-field
              v-model="productProperties.imageCount"
              :counter="256"
              label="Anzahl Bilder"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="productProperties.producer"
              :counter="256"
              label="Produzent"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="productProperties.resolution"
              :counter="256"
              label="Auflösung"
            />
          </v-col>
          <v-col cols="6" md="3">
            <v-checkbox v-model="productProperties.visable" label="Sichtbar" />
          </v-col>
          <v-col cols="6" md="3">
            <v-checkbox v-model="productProperties.pinned" label="Pinned" />
          </v-col>
        </v-row>
        <div>Subscriber-Optionen</div>
        <v-row>
          <v-col cols="6" md="4">
            <v-checkbox
              v-model="productProperties.directlyStreamableForSubscribers"
              label="Direkt sichtbar"
              :disabled="productProperties.exclusiveForSubscribers"
            />
          </v-col>
          <v-col cols="6" md="4">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-checkbox
                    v-model="productProperties.exclusiveForSubscribers"
                    label="Exklusiv"
                    :disabled="!!currentProduct.bundles.length"
                    @change="forceDirectlyStreamable"
                  />
                </span>
              </template>
              <span>Nur setzbar, wenn das Produkt in keinem Bundle enthalten ist.</span>
            </v-tooltip>
          </v-col>
          <v-col cols="6" md="4">
            <v-checkbox
              v-model="productProperties.downloadableForSubscibers"
              label="Herunterladbar"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="updateProductProperties">
        Speichern
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';

export default {
  data() {
    return {
      productProperties: {},
      fromIsValid: true,
    };
  },

  computed: {
    ...mapGetters(['currentProduct']),
  },

  watch: {
    productProperties: {
      handler() {
        this.$nextTick(() => {
          if (this.productProperties && this.productProperties.credits) {
            this.productProperties.credits = parseInt(this.productProperties.credits, 10);
          }
        });
      },
      deep: true,
    },
  },

  created() {
    this.productProperties = _.pick(this.currentProduct, [
      'orderId',
      'title',
      'keywords',
      'visable',
      'pinned',
      'credits',
      'length',
      'imageCount',
      'producer',
      'resolution',
      'directlyStreamableForSubscribers',
      'exclusiveForSubscribers',
      'downloadableForSubscibers',
    ]);
  },

  methods: {
    ...mapActions(['patchProduct']),
    async updateProductProperties() {
      const valid = this.$refs.form.validate();
      if (valid) {
        await this.patchProduct({
          id: this.currentProduct.id,
          data: this.productProperties,
        });
      }
    },
    forceDirectlyStreamable(value) {
      if (value) this.productProperties.directlyStreamableForSubscribers = true;
    },
  },
};
</script>
