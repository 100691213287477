import { paymentsApi } from '@/api';
import type { Module } from 'vuex';
import type { TRootStore } from '@/store';
import type { IPayment } from '@/types/Payments';
import type { IFindParams } from '@/types/Api';
import { SET_PAYMENTS } from './storeTypes/mutationTypes';

export interface IPaymentsStoreState {
  payments: IPayment[];
  loading: boolean;
  page: number;
  total: number;
  limit: number;
}

type TPaymentsStore = Module<IPaymentsStoreState, TRootStore>;

const PaymentsStore: TPaymentsStore = {
  state: () => ({
    loading: false,
    payments: [],
    page: 0,
    total: 0,
    limit: 10,
  }),

  getters: {
    allPayments: (state) => state.payments,
    paymentsLoading: (state) => state.loading,
    paymentsTotal: (state) => state.total,
  },

  mutations: {
    [SET_PAYMENTS](state, { data, refresh }: { data: IPayment[]; refresh: boolean }) {
      state.payments = [...(refresh ? [] : state.payments), ...data];
    },
  },

  actions: {
    async findPayments({ commit, state }, params: IFindParams) {
      state.loading = true;
      const { data, total } = await paymentsApi.findPayments(params);
      commit(SET_PAYMENTS, { data, refresh: params.refresh });
      state.total = total;
      state.loading = false;
    },
  },
};

export default PaymentsStore;
