<template>
  <section>
    <div :class="listClass">
      <ProductListItem
        v-for="product in products"
        :key="product.id"
        :to="`/product/${product.id}`"
        :title="product.title"
        :type="product.type"
        :price="getPrice(product)"
        :thumbnail="getThumbnail(product)"
        :availability="product.accessStatus"
        :pinned="product.pinned"
        :active-subscription="activeSubscription"
      />
    </div>
    <div v-if="!loading" ref="loadMoreTarget" />
    <div class="flex w-full justify-center py-4">
      <r-loading-circle v-show="loading" class="w-8 h-8" />
    </div>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api';
import { useIntersectionObserver } from '@vueuse/core';
import ProductListItem from '../ProductListItem/index.vue';

export default {
  components: {
    ProductListItem,
  },

  props: {
    products: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    listClass: {
      type: String,
      default: 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3',
    },
    activeSubscription: {
      type: Object,
      required: false,
    },
    currentUser: {
      type: Object,
      required: false,
    },
  },

  setup(props, ctx) {
    const loadMoreTarget = ref(null);
    const targetIsVisible = ref(false);

    useIntersectionObserver(loadMoreTarget, ([{ isIntersecting }]) => {
      targetIsVisible.value = isIntersecting;
      if (targetIsVisible.value) {
        ctx.emit('load-more');
      }
    });

    return {
      loadMoreTarget,
    };
  },

  methods: {
    getThumbnail(product) {
      let { files } = product;
      if (product.type === 'bundle') {
        files = product.bundleProducts[0].files;
      }

      const thumbnailFile = files.find(
        (file) => file.types && file.types.some((type) => type.name === 'thumbnail'),
      );
      return thumbnailFile ? thumbnailFile.url : '';
    },
    purchasedProduct(product) {
      return product.purchases.some((purchases) => purchases.userId === this.currentUser.id);
    },
    getPrice(product) {
      let { credits } = product;
      if (product.type === 'bundle') {
        let totalPriceWithoutDiscount = 0;
        product.bundleProducts.forEach((product) => {
          if (!this.purchasedProduct(product)) {
            totalPriceWithoutDiscount += product.credits;
          }
        });

        credits =
          totalPriceWithoutDiscount -
          Math.ceil(totalPriceWithoutDiscount * (product.discount / 100));
      }

      return credits;
    },
  },
};
</script>
