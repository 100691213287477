<template>
  <Overlay v-model="show">
    <div class="max-w-xs text-sm text-center">
      <div v-if="!error">
        <div>
          {{ $t('renewSubscriptionDescription') }}
        </div>
        <Button class="mt-3" @click="renewSubscription">
          {{ $t('renewSubscription') }}
        </Button>
        <Button class="mt-3" @click="notRenewSubscription">
          {{ $t('noThanks') }}
        </Button>
      </div>
      <div v-else>
        {{ $t(errorMsg) }}

        <Button @click="goToSubscriptionsPage"> 
          {{ $t('goToSubscription') }}
        </Button>
        <Button @click="notRenewSubscription">
          {{ $t('close') }}
        </Button>
      </div>
    </div>
  </Overlay>
</template>

<script>
import { SET_SHOW_LOGIN_MESSAGE } from '@/store/storeTypes/mutationTypes';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { subscriptionsPlansApi } from '@/api';

export default {
  data() {
    return {
      show: true,
      error: false,
      errorMsg: '',
    };
  },

  computed: {
    ...mapGetters(['expiredSubscription']),
  },

  methods: {
    ...mapMutations([SET_SHOW_LOGIN_MESSAGE]),
    ...mapActions(['noteExpiredSubscription', 'renewExpiredSubscription']),
    async renewSubscription() {
      const subscriptionPlan = await subscriptionsPlansApi.getSubscriptionPlan(
        this.expiredSubscription.subscriptionPlanId,
      );

      if (subscriptionPlan.archived) {
        this.error = true;
        this.errorMsg = 'subscriptionPlanIsArchived';
        return;
      }

      try {
        await this.renewExpiredSubscription();
      } catch (err) {
        this.error = true;
        this.errorMsg = 'failedSubscriptionRenewal';
        return;
      }

      await this.noteExpiredSubscription();
      this[SET_SHOW_LOGIN_MESSAGE](false);
      this.$router.push('/subscriptions');
    },

    async notRenewSubscription() {
      await this.noteExpiredSubscription();
      this[SET_SHOW_LOGIN_MESSAGE](false);
    },

    async goToSubscriptionsPage() {
      await this.notRenewSubscription();
      this.$router.push('/subscriptions');
    },
  },
};
</script>
