<template>
  <div>
    <SeperationBox>
      <template>
        {{ $t('imagePreviews') }}
      </template>
      <template v-slot:custom>
        <div 
          class="hover:opacity-75 text-xs cursor-pointer flex items-center gap-1"
          @click="showFullscreenSlider = true"
        >
          <span>{{ $t('fullscreenGallery') }}</span>
          <Icon
            name="view"
            :size="24"
            custom-view-box="0 0 24 24"
            class="lg:text-akzent"
          />
        </div>
      </template>
    </SeperationBox>

    <div class="relative -mx-2">
      <div
        class="
              top-0
              left-0
              absolute
              z-10 
              h-full 
              flex 
              items-center 
              pl-4 
              w-16
              cursor-pointer
              dark-gradient-right-to-left
              opacity-0
              transition-opacity
              opacity
              duration-200
            "
        :class="{
          'opacity-100': hasPrevSlide,
        }"
        @click="prevSliderImage"
      >
        <Icon
          name="arrowLeft"
          outline
          class="w-6 h-12 lg:w-8 lg:h-16"
          :custom-view-box="'0 0 21 35'"
        />
      </div>

      <div
        class="
              absolute 
              top-0
              right-0
              z-10 
              h-full 
              flex 
              items-center
              justify-end
              pr-4 
              w-16
              cursor-pointer
              dark-gradient-left-to-right
              opacity-0
              transition-opacity
              opacity
              duration-200
            "
        :class="{
          'opacity-100': hasNextSlide,
        }"
        @click="nextSliderImage"
      >
        <Icon
          name="arrowRight"
          outline
          class="w-6 h-12 lg:w-8 lg:h-16"
          :custom-view-box="'0 0 21 35'"
        />
      </div>


      <carousel
        ref="previewSlider"
        v-model="previewImageSlider.position"
        :per-page="previewImageSlider.itemsPagePage"
        :pagination-enabled="false"
      >
        <slide 
          v-for="(previewImage, i) in previewImages" 
          :key="`preview-image-${i}`"
        >
          <CustomImage class="mx-2" :url="previewImage.url" aspect-ratio="16/9" />
        </slide>
      </carousel>
    </div>

    <FullScreenImageSlider 
      v-model="showFullscreenSlider" 
      :images="previewImages" 
      :items-per-page="1" 
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SeperationBox from '../SeperationBox/index.vue';

export default {
  components: {
    SeperationBox,
  },
  props: {
    isMobile: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      previewImageSlider: {
        position: 0,
        itemsPagePage: 3,
      },
      showFullscreenSlider: false,
    };
  },

  computed: {
    ...mapGetters(['currentProduct']),
    previewImages() {
      return this.currentProduct.files.filter((file) =>
        file.types.some((type) => type.name === 'previewImage'),
      );
    },
    hasPrevSlide() {
      return this.previewImageSlider.position !== 0;
    },
    hasNextSlide() {
      if (
        this.previewImages.length >
        (this.previewImageSlider.position + 1) * this.previewImageSlider.itemsPagePage
      ) {
        return true;
      }
      return false;
    },
  },

  watch: {
    isMobile: {
      immediate: true,
      handler(val) {
        if (val) {
          this.previewImageSlider.itemsPagePage = 1;
        } else {
          this.previewImageSlider.itemsPagePage = 3;
        }
      },
    },
  },

  methods: {
    nextSliderImage() {
      this.$refs.previewSlider.advancePage('forward');
    },
    prevSliderImage() {
      this.$refs.previewSlider.advancePage('backward');
    },
  },
};
</script>
