<template>
  <div>
    <v-card>
      <v-card-title>Kategorien</v-card-title>
      <v-card-text>
        <v-chip-group
          column
        >
          <v-chip
            v-for="categorie in currentProduct.categories"
            :key="categorie.id"
            close
            @click:close="removeCategorie(categorie.id)"
          > 
            {{ categorie.name }} 
          </v-chip>
          <v-chip @click="openSelectionDialog = true">
            <v-icon>add</v-icon>
          </v-chip>
        </v-chip-group>
      </v-card-text>
    </v-card>
    <SelectionDialog
      v-model="openSelectionDialog"
      :items="allCategories"
      :filter-items="currentProduct.categories"
      :item-text="'name'"
      :item-value="'id'"
      :loading="categoriesLoading"
      :all-items-loaded="allCategoriesLoaded"
      @selectionCompleted="addCategories"
      @load-more="lazyLoadCategories"
      @search="updateCategoriesSearch"
    > 
      <template v-slot:submit-button>
        Hinzufügen
      </template>
      <template v-slot:no-options>
        Keine weiteren Kategorien verfügbar.
      </template>
    </SelectionDialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SelectionDialog from '../SelectionDialog/index.vue';

export default {
  components: {
    SelectionDialog,
  },
  data() {
    return {
      openSelectionDialog: false,
      categoriesQuery: {},
    };
  },
  computed: {
    ...mapGetters(['currentProduct', 'allCategories', 'categoriesLoading', 'allCategoriesLoaded']),
  },
  watch: {
    openSelectionDialog(val) {
      if (val) {
        this.categoriesQuery = {
          $limit: 25,
          name: {
            $like: '%%',
          },
        };
        this.findCategories({
          query: this.categoriesQuery,
          refresh: true,
        });
      }
    },
  },
  methods: {
    ...mapActions(['addCategorieToProduct', 'removeCategorieFromProduct', 'findCategories']),
    removeCategorie(id) {
      this.removeCategorieFromProduct({
        productId: this.currentProduct.id,
        categorieId: id,
      });
    },
    lazyLoadCategories() {
      this.findCategories({
        query: this.categoriesQuery,
        refresh: false,
      });
    },
    updateCategoriesSearch(value) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.categoriesQuery.name.$like = `%${value}%`;

        this.findCategories({
          query: this.categoriesQuery,
          refresh: true,
        });
      }, 275);
    },
    async addCategories(categorieIds) {
      await Promise.all(
        categorieIds.map((categorieId) =>
          this.addCategorieToProduct({
            productId: this.currentProduct.id,
            categorieId,
          }),
        ),
      );
    },
  },
};
</script>
