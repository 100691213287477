<template>
  <v-dialog 
    v-if="currentSubscriptionPlan" 
    v-model="show"
    max-width="350"
  >
    <v-card>
      <v-card-title>Wirklich?</v-card-title> 
 
      <v-card-text>
        Soll der Aboplan {{ 
          currentSubscriptionPlan.archived ? 'unarchiviert' : 'archiviert'
        }} werden?
      </v-card-text>

      <v-card-actions>
        <v-btn
          text
          :color="currentSubscriptionPlan.archived ? 'primary' : 'error'"
          @click="setArchived"
        >
          {{ currentSubscriptionPlan.archived ? 'Unarchivieren' : 'Archivieren' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
    },
  },

  computed: {
    ...mapGetters(['currentSubscriptionPlan']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    ...mapActions(['patchSubscriptionPlan']),
    async setArchived() {
      await this.patchSubscriptionPlan({
        id: this.currentSubscriptionPlan.id,
        data: { archived: !this.currentSubscriptionPlan.archived },
      });

      this.$emit('input', false);
    },
  },
};
</script>
