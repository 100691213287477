export const SET_TOKEN = 'setToken';
export const SET_NEXT_ROUTE = 'setNextRoute';
export const SET_LOADING = 'setLoading';
export const SET_LOGIN_LOADING = 'setLoginLoading';
export const SET_LOGIN_ERROR = 'setLoginError';
export const SET_READY = 'setReady';
export const SET_REGISTER_ERROR = 'setRegisterError';
export const SET_REGISTER_LOADING = 'setRegisterLoading';
export const SET_PASSWORD_RESET_ERROR = 'setPasswordResetError';
export const SET_RESET_PASSWORD_LOADING = 'setPasswordResetLoading';

export const SET_CATEGORIES = 'setCategories';
export const OPEN_CATEGORY = 'openCategory';
export const SET_CATEGORIE = 'setCategorie';
export const REMOVE_CATEGORIE = 'removeCategorie';
export const RESET_CATEGORIES = 'resetCategories';

export const TOGGLE_DRAWER = 'toggleDrawer';
export const TOGGLE_MOBILE_SEARCH = 'toggleMobileSearch';
export const CLEAR_SEARCH = 'clearSearch';
export const SET_DEVICE = 'setDevice';
export const SET_PRODUCTS_VIEW_INFO = 'setProductsViewInfo';
export const SET_MODELS_VIEW_INFO = 'setModelsViewInfo';
export const SET_SHOW_LOGIN_MESSAGE = 'setShowLoginMessage';
export const SET_LAST_VISITED_PAGE = 'setLastVisitedPage';

export const SET_USER = 'setUser';
export const SET_CREDITS = 'setCredits';
export const SET_SPENT_CREDITS = 'setSpentCredits';
export const SET_LANGUAGE = 'setLanguage';
export const SET_USERS = 'setUsers';
export const OPEN_USER = 'openUser';
export const RESET_USERS = 'resetUsers';
export const REMOVE_USER = 'removeUser';
export const SET_PENDING_AGE_VERIFICATION = 'setPendingAgeVerification';
export const SET_ACTIVE_SUBSCRIPTION = 'setActiveSubscription';
export const SET_EXPIRED_SUBSCRIPTION = 'setExpiredSubscription';
export const SET_AGB_VERSION = 'setAGBVersion';

export const SET_PRODUCTS = 'setProducts';
export const SET_PRODUCT = 'setProduct';
export const RESET_PRODUCTS = 'resetProducts';
export const OPEN_PRODUCT = 'openProduct';
export const SET_PRODUCT_TRANSLATION = 'setProductTranslation';
export const SET_PRODUCT_CATEGORIE = 'setProductCategorie';
export const REMOVE_PRODUCT_CATEGORIE = 'removeProductCategorie';
export const SET_PRODUCT_MODEL = 'setProductModel';
export const REMOVE_PRODUCT_MODEL = 'removeProductModel';
export const SET_PRODUCT_FAVORITE = 'setProductFavorite';
export const REMOVE_PRODUCT_FAVORITE = 'removeProductFavorite';
export const SET_PRODUCT_PURCHASE = 'setProductPurchase';

export const SET_BUNDLE_PRODUCT = 'setBundleProduct';
export const SET_BUNDLE_PRODUCTS = 'setBundleProducts';
export const RESET_BUNDLE_PRODUCTS = 'resetBundleProducts';
export const OPEN_BUNDLE_PRODUCT = 'openBundleProduct';
export const REMOVE_BUNDLE_PRODUCT = 'removeBundleProduct';
export const REMOVE_PRODUCT_FROM_BUNDLE = 'removeProductFromBundle';
export const ADD_PRODUCT_TO_BUNDLE = 'addProductToBundle';

export const SET_PRODUCT_FILES = 'setProductFiles';
export const SET_PRODUCT_FILE = 'setProductFile';
export const OPEN_PRODUCT_FILE = 'openProductFile';
export const RESET_PRODUCT_FILES = 'resetProductFiles';
export const REMOVE_PRODUCT_FILE = 'removeProdcutFiles';
export const SET_PRODUCT_FILE_TYPE = 'setProductFileType';
export const REMOVE_PRODUCT_FILE_TYPE = 'removeProductFileType';

export const SET_AGE_VERIFICATIONS = 'setAgeVerifications';
export const RESET_AGE_VERIFICATIONS = 'setAgeVerification';
export const OPEN_AGE_VERIFICATION = 'openAgeVerification';
export const SET_AGE_VERIFICATION = 'setAgeVerification';

export const SET_MODELS = 'setModels';
export const OPEN_MODEL = 'openModel';
export const SET_MODEL = 'setModel';
export const RESET_MODELS = 'resetModels';
export const REMOVE_MODEL = 'removeModel';

export const SET_CURRENT_USER = 'setCurrentUser';
export const SET_USER_CREDITS = 'setUserCredits';
export const SET_USER_CREDIT = 'setUserCredit';

export const SET_PAYMENTS = 'setPayments';

export const SET_SUBSCRIPTION_PLANS = 'setSubscriptionPlans';
export const OPEN_SUBSCRIPTION_PLAN = 'openSubscriptionPlan';
export const SET_SUBSCRIPTION_PLAN = 'setSubscriptionPlan';
export const RESET_SUBSCRIPTION_PLANS = 'resetSubscriptionPlans';

export const RESET_PRODUCT_CREATION = 'resetProductCreation';
export const UPDATE_PRODUCT_CREATION = 'updateProductCreation';
export const SET_PRODUCT_CREATION_TYPE = 'setProductCreationType';
export const SET_PRODUCT_CREATION_ORDER_ID = 'setProductCreationOrderId';
export const SET_PRODUCT_CREATION_TITLE = 'setProductCreationTitle';
export const SET_PRODUCT_CREATION_KEYWORDS = 'setProductCreationKeywords';
export const SET_PRODUCT_CREATION_CREDITS = 'setProductCreationCredits';
export const SET_PRODUCT_CREATION_LENGTH = 'setProductCreationLength';
export const SET_PRODUCT_CREATION_VISABLE = 'setProductCreationVisable';
export const SET_PRODUCT_CREATION_PINNED = 'setProductCreationPinned';
export const SET_PRODUCT_CREATION_IMAGE_COUNT = 'setProductCreationImageCount';
export const SET_PRODUCT_CREATION_PRODUCER = 'setProductCreationProducer';
export const SET_PRODUCT_CREATION_RESOLUTION = 'setProductCreationResolution';
export const SET_PRODUCT_CREATION_DIRECTLY_STREAMABLE_FOR_SUBSCRIBERS =
  'setProductCreationDirectlyStreamableForSubscribers';
export const SET_PRODUCT_CREATION_EXCLUSIVE_FOR_SUBSCRIBERS =
  'setProductCreationExclusiveForSubscribers';
export const SET_PRODUCT_CREATION_DOWNLOADABLE_FOR_SUBSCRIBERS =
  'setProductCreationDownloadableForSubscibers';
export const SET_PRODUCT_CREATION_TRANSLATION_ENGLISH_SHORT =
  'setProductCreationTranslationEnglishShort';
export const SET_PRODUCT_CREATION_TRANSLATION_ENGLISH_LONG =
  'setProductCreationTranslationEnglishLong';
export const SET_PRODUCT_CREATION_TRANSLATION_GERMAN_SHORT =
  'setProductCreationTranslationGermanShort';
export const SET_PRODUCT_CREATION_TRANSLATION_GERMAN_LONG =
  'setProductCreationTranslationGermanLong';
export const ADD_PRODUCT_CREATION_CATEGORIES = 'addProductCreationCategories';
export const REMOVE_PRODUCT_CREATION_CATEGORIE = 'removeProductCreationCategories';
export const ADD_PRODUCT_CREATION_MODELS = 'addProductCreationModels';
export const REMOVE_PRODUCT_CREATION_MODEL = 'removeProductCreationModels';
export const ADD_PRODUCT_CREATION_FILES = 'addProductCreationFiles';
export const REMOVE_PRODUCT_CREATION_FILE = 'removeProductCreationFile';
export const ADD_PRODUCT_CREATION_FILE_TYPE = 'addProductCreationFileType';
export const REMOVE_PRODUCT_CREATION_FILE_TYPE = 'removeProductCreationFileType';
export const PRODUCT_CREATION_REMOVE_ALL_FILES = 'productCreationRemoveAllFiles';
export const UPDATE_PRODUCT_CREATION_FILE = 'updateProductCreationFile';

export const SET_MODEL_PRODUCTS = 'setModelProducts';
export const RESET_MODEL_PRODUCTS = 'resetModelProducts';

export const SET_RECENT_MODELS = 'setRecentModels';
export const RESET_RECENT_MODELS = 'resetRecentModels';

export const SET_SPECIAL_OFFER = 'setSpecialOffer';
export const SET_TOTAL_SPECIAL_OFFERS = 'setTotalSpecialOffer';
export const SET_LAST_SPECIAL_OFFER_POSITION = 'setLastSpecialOfferPosition';

export const SET_USERS_TABLE_OPTIONS = 'setUsersTableOptions';
export const SET_USERS_FILTER_OPTIONS = 'setUsersFilterOptions';
export const SET_AGE_VERIFICATIONS_TABLE_OPTIONS = 'setAgeVerificationsTableOptions';
export const SET_MODELS_TABLE_OPTIONS = 'setModelsTableOptions';
export const SET_CATEGORIES_DATA_TABLE_OPTIONS = 'setCategoriesTableOptions';
export const SET_PRODUCTS_DATA_TABLE_OPTIONS = 'setProductsTableOptions';
export const SET_BUNDLES_DATA_TABLE_OPTIONS = 'setBundlesTableOptions';
export const SET_SUBSCRIPTIONS_PLANS_DATA_TABLE_OPTIONS = 'setSubscriptionsPlansTableOptions';
export const SET_PRODUCTS_FILTER_OPTIONS = 'setProductsFilterOptions';
