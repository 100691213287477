<template>
  <div v-if="!allProductsPurchased && bundle.visable" class="lg:px-6 lg:pt-4 lg:pb-6">
    <div class="grid grid-cols-10 lg:gap-3">
      <div
        class="col-span-10 grid grid-cols-3 lg:gap-1"
        :class="{ 'lg:col-span-5': full, 'lg:col-span-4': !full }"
      >
        <router-link
          v-for="bundleProduct in bundle.bundleProducts"
          :key="`product-detail-bundle-product-thumbnails-${bundleProduct.id}`"
          :to="`/product/${bundleProduct.id}`"
        >
          <CustomImage :url="getThumbnail(bundleProduct.files)" aspect-ratio="16/9" />
        </router-link>
      </div>
      <div
        class="col-span-10 p-3 lg:p-0"
        :class="{ 'lg:col-span-5': full, 'lg:col-span-6': !full }"
      >
        <div class="lg:pl-6 lg:h-full text-sm flex flex-wrap">
          <div class="font-semibold leading-6 pb-1 w-full">
            {{ $t('title') }}: {{ bundle.title }}
          </div>
          <div>
            <router-link
              v-for="(bundleProduct, i) in bundle.bundleProducts"
              :key="`product-detail-bundle-product-list-${bundleProduct.id}`"
              :to="`/product/${bundleProduct.id}`"
              :class="{ 'line-through': purchasedProduct(bundleProduct) }"
              class="hover:underline"
            >
              {{ bundleProduct.title }}{{ i !== bundle.bundleProducts.length - 1 ? ', ' : '' }}
            </router-link>
          </div>

          <div class="flex flex-wrap items-center lg:self-end w-full mt-2 lg:mt-0">
            <div class="flex items-center w-full lg:w-auto">
              {{ $t('price') }}:
              <span class="ml-1 line-through">
                {{ totalPriceWithoutDiscount }}
              </span>
              <span class="ml-2 text-akzent"> {{ totalPriceWithDiscount }} Credits </span>
            </div>
            <div class="w-full lg:w-auto mt-2 lg:ml-4 lg:mt-0">
              {{ $t('save') }}: {{ bundle.discount }}%
            </div>
            <div class="w-full lg:w-auto mt-2 lg:ml-6 lg:mt-0">
              <Button @click="$emit('purchaseBundle', { totalPriceWithDiscount, bundle })"> 
                {{ $t('buyNow') }}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    bundle: {
      type: Object,
      required: true,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters(['currentUser', 'activeSubscription']),
    allProductsPurchased() {
      return this.bundle.bundleProducts.every((product) =>
        product.purchases.some((purchase) => purchase.userId === this.currentUser.id),
      );
    },
    totalPriceWithoutDiscount() {
      let price = 0;
      this.bundle.bundleProducts.forEach((product) => {
        if (!this.purchasedProduct(product)) {
          price += product.credits;
        }
      });

      if (
        this.activeSubscription &&
        this.activeSubscription.subscriptionPlan &&
        this.activeSubscription.subscriptionPlan.productDiscount
      ) {
        price -= Math.ceil(
          (price * this.activeSubscription.subscriptionPlan.productDiscount) / 100,
        );
      }

      return price;
    },
    totalPriceWithDiscount() {
      return (
        this.totalPriceWithoutDiscount -
        Math.ceil(this.totalPriceWithoutDiscount * (this.bundle.discount / 100))
      );
    },
  },

  methods: {
    getThumbnail(files) {
      const thumbnailFile = files.find(
        (file) => file.types && file.types.some((type) => type.name === 'thumbnail'),
      );
      return thumbnailFile ? thumbnailFile.url : '';
    },
    purchasedProduct(product) {
      return product.purchases.some((purchases) => purchases.userId === this.currentUser.id);
    },
  },
};
</script>
