<template>
  <div>
    <v-card>
      <v-card-title>Models</v-card-title>
      <v-card-text>
        <v-chip-group
          column
        >
          <v-chip
            v-for="model in currentProduct.models"
            :key="model.id"
            close
            @click:close="removeModel(model.id)"
          > 
            {{ model.name }} 
          </v-chip>
          <v-chip @click="openSelectionDialog = true">
            <v-icon>add</v-icon>
          </v-chip>
        </v-chip-group>
      </v-card-text>
    </v-card>
    <SelectionDialog
      v-model="openSelectionDialog"
      :items="allModels"
      :filter-items="currentProduct.models"
      :item-text="'name'"
      :item-value="'id'"
      :loading="modelsLoading"
      :all-items-loaded="allModelsLoaded"
      @selectionCompleted="addModels"
      @load-more="lazyLoadModels"
      @search="updateModelsSearch"
    > 
      <template v-slot:submit-button>
        Hinzufügen
      </template>
      <template v-slot:no-options>
        Keine weiteren Models verfügbar.
      </template>
    </SelectionDialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SelectionDialog from '../SelectionDialog/index.vue';

export default {
  components: {
    SelectionDialog,
  },

  data() {
    return {
      openSelectionDialog: false,
      modelsQuery: {},
    };
  },

  computed: {
    ...mapGetters(['currentProduct', 'allModels', 'modelsLoading', 'allModelsLoaded']),
  },

  watch: {
    openSelectionDialog(val) {
      if (val) {
        this.modelsQuery = {
          $limit: 25,
          name: {
            $like: '%%',
          },
        };
        this.findModels({
          query: this.modelsQuery,
          refresh: true,
        });
      }
    },
  },

  methods: {
    ...mapActions(['findModels', 'addModelToProduct', 'removeModelFromProduct']),
    removeModel(id) {
      this.removeModelFromProduct({
        productId: this.currentProduct.id,
        modelId: id,
      });
    },
    lazyLoadModels() {
      this.findModels({
        query: this.modelsQuery,
        refresh: false,
      });
    },
    updateModelsSearch(value) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.modelsQuery.name.$like = `%${value}%`;

        this.findModels({
          query: this.modelsQuery,
          refresh: true,
        });
      }, 275);
    },
    async addModels(modelIds) {
      await Promise.all(
        modelIds.map((modelId) =>
          this.addModelToProduct({
            productId: this.currentProduct.id,
            modelId,
          }),
        ),
      );
    },
  },
};
</script>
