import type { Params } from '@feathersjs/feathers';
import type { TFindResponse, TGetResponse } from '@/types/Api';
import type { ISubscriptionPlan } from '@/types/SubscriptionPlans';
import { client } from '../client';

const service = client.service('subscription-plans');

const findSubscriptionPlans = (params?: Params): TFindResponse<ISubscriptionPlan> => {
  return service.find({
    ...(params || {}),
    query: {
      $limit: 30,
      ...(params?.query || {}),
    },
  });
};

const getSubscriptionPlan = (id: number, params?: Params): TGetResponse<ISubscriptionPlan> => {
  return service.get(id, params);
};

const createSubscriptionPlan = (
  data: ISubscriptionPlan,
  params?: Params,
): TGetResponse<ISubscriptionPlan> => {
  return service.create(data, params || {});
};

const patchSubscriptionPla = (
  id: number,
  data: any,
  params?: Params,
): TGetResponse<ISubscriptionPlan> => {
  return service.patch(id, data, params);
};

export const subscriptionsPlansApi = {
  findSubscriptionPlans,
  getSubscriptionPlan,
  createSubscriptionPlan,
  patchSubscriptionPla,
};
