<template>
  <section>
    <SeperationBox>
      <template>
        {{ $t('activeSubscription') }}
      </template>
    </SeperationBox>
    <div 
      class="grid lg:grid-cols-3 gap-3"
    >
      <div class="bg-interface p-6 leading-5">
        <div class="pb-5 text-xs uppercase font-semibold tracking-widest">
          {{ $t('subscriptionPlan') }}
        </div>
        <div class="pb-3">
          <div 
            class="text-inactive text-xxs uppercase tracking-semi-wide font-semibold"
          >
            {{ $t('name') }}
          </div>
          <div class="text-xs">
            {{ activeSubscription.subscriptionPlan.name }}
          </div>
        </div>
        <div class="pb-3">
          <div 
            class="text-inactive text-xxs uppercase tracking-semi-wide font-semibold"
          >
            {{ $t('term') }}
          </div>
          <div class="text-xs">
            {{ $tc('daysWithCount', activeSubscription.subscriptionPlan.term * 30) }}
          </div>
        </div>
        <div v-if="!activeSubscription.subscriptionPlan.oneTime" class="pb-3">
          <div 
            class="text-inactive text-xxs uppercase tracking-semi-wide font-semibold"
          >
            {{ $t('30DaysPeriodPrice') }}
          </div>
          <div class="text-xs">
            {{ 
              activeSubscription.subscriptionPlan.monthlyPrice | formatMoney 
            }}
          </div>
        </div>
        <div v-if="activeSubscription.subscriptionPlan.oneTime" class="pb-3">
          <div 
            class="text-inactive text-xxs uppercase tracking-semi-wide font-semibold"
          >
            {{ $t('totalPrice') }}
          </div>
          <div class="text-xs">
            {{ 
              activeSubscription.subscriptionPlan.monthlyPrice 
                * activeSubscription.subscriptionPlan.term | formatMoney 
            }}
          </div>
        </div>
        <div class="pb-3">
          <div 
            class="text-inactive text-xxs uppercase tracking-semi-wide font-semibold"
          >
            {{ $t('cancellation') }}
          </div>
          <div class="text-xs">
            <div 
              v-if="
                activeSubscription.subscriptionPlan.minimumTerm !== 
                  activeSubscription.subscriptionPlan.term
              "
            >
              <span v-if="activeSubscription.subscriptionPlan.minimumTerm > 1">
                <!-- eslint-disable-next-line max-len -->
                {{ $tc('daysWithCount', activeSubscription.subscriptionPlan.minimumTerm * 30) }} {{ $t('subscriptionCancelEndOf30DaysPeriod') }}
              </span>
              <span v-else>
                {{ $t('cancelToEndOfEvery30DaysPeriod') }}
              </span>
            </div>
            <div v-else>{{ $t('notRequiredEndsAutomatically') }}</div>
          </div>
        </div>
        <div class="pb-3">
          <div 
            class="text-inactive text-xxs uppercase tracking-semi-wide font-semibold"
          >
            {{ $t('renewal') }}
          </div>
          <div class="text-xs">
            {{ 
              activeSubscription.subscriptionPlan.recurring 
                ? $t('automatic') : $t('automaticallyEnds') 
            }}
          </div>
        </div>
        <div class="pb-3">
          <div 
            class="text-inactive text-xxs uppercase tracking-semi-wide font-semibold"
          >
            {{ $t('discountAndBonus') }}
          </div>
          <div class="text-xs">
            <div 
              v-if="activeSubscription.subscriptionPlan.productDiscount"
            >
              {{ `${activeSubscription.subscriptionPlan.productDiscount}% 
                  ${$t('discountOnPurchases')}` }}}
            </div>
            <div v-if="activeSubscription.subscriptionPlan.bonus">
              {{ 
                $t('creditBonusDaily', { 
                  bonus: activeSubscription.subscriptionPlan.bonus, 
                  days: activeSubscription.subscriptionPlan.term * 30
                }) 
              }}
            </div>
            <div 
              v-if="
                !activeSubscription.subscriptionPlan.productDiscount && 
                  !activeSubscription.subscriptionPlan.bonus
              "
            >
              {{ $t('none') }}
            </div>
          </div>
        </div>
      </div>
      <div class="bg-interface p-6 leading-5">
        <div class="pb-5 text-xs uppercase font-semibold tracking-widest">
          {{ $t('subscriptionDetails') }}
        </div>
        <div class="pb-3">
          <div 
            class="text-inactive text-xxs uppercase tracking-semi-wide font-semibold"
          >
            {{ $t('start') }}
          </div>
          <div class="text-xs">
            {{ activeSubscription.start | formatDate }}
          </div>
        </div>
        <div class="pb-3">
          <div 
            class="text-inactive text-xxs uppercase tracking-semi-wide font-semibold"
          >
            {{ $t('end') }}
          </div>
          <div class="text-xs">
            {{ activeSubscription.end | formatDate }}
          </div>
        </div>
        <!-- <div v-if="activeSubscription.subscriptionPlan.oneTime" class="pb-3">
          <div 
            class="text-inactive text-xxs uppercase tracking-semi-wide font-semibold"
          >
            {{ $t('minimumTermEnd') }}
          </div>
          <div class="text-xs">
            {{ 
              $moment(activeSubscription.start)
                .add(activeSubscription.subscriptionPlan.minimumTerm, 'months')
                .format('HH:mm - DD.MM.YYYY')
            }}
          </div>
        </div> -->
        <div class="pb-3">
          <div 
            class="text-inactive text-xxs uppercase tracking-semi-wide font-semibold"
          >
            {{ $t('cancellation') }}
          </div>
          <div class="text-xs">
            <div 
              v-if="
                activeSubscription.subscriptionPlan.minimumTerm !== 
                  activeSubscription.subscriptionPlan.term
              "
            >
              <span v-if="activeSubscription.subscriptionPlan.minimumTerm > 1">
                <!-- eslint-disable-next-line max-len -->
                {{ $tc('daysWithCount', activeSubscription.subscriptionPlan.minimumTerm) }} {{ $t('subscriptionCancelEndOf30DaysPeriod') }}
              </span>
              <span v-else>
                {{ $t('cancelToEndOfEvery30DaysPeriod') }}
              </span>
            </div>
            <div v-else>{{ $t('notRequiredEndsAutomatically') }}</div>
          </div>
        </div>
        <div class="pb-3">
          <div 
            class="text-inactive text-xxs uppercase tracking-semi-wide font-semibold"
          >
            {{ $t('paymentMethod') }}
          </div>
          <div class="text-xs">
            {{ $t('creditCard') }}
          </div>
        </div>
        <div class="pb-3">
          <div 
            class="text-inactive text-xxs uppercase tracking-semi-wide font-semibold"
          >
            {{ $t('canceled') }}
          </div>
          <div class="text-xs">
            {{ 
              activeSubscription.canceled || 
                activeSubscription.subscriptionPlan.oneTime ? $t('yes') : $t('no') 
            }}
          </div>
        </div>
        <div v-if="activeSubscription.canceled" class="pb-3">
          <div 
            class="text-inactive text-xxs uppercase tracking-semi-wide font-semibold"
          >
            {{ $t('canceledTo') }}
          </div>
          <div class="text-xs">
            {{ activeSubscription.canceledAt | formatDate }}
          </div>
        </div>
      </div>
      <div 
        v-if="!activeSubscription.canceled && !activeSubscription.subscriptionPlan.oneTime" 
        class="bg-interface p-6 leading-5"
      >
        <div class="pb-5 text-xs uppercase font-semibold tracking-widest">
          {{ $t('actions') }}
        </div>
        <!-- <div class="pb-3">
          <router-link
            to="/subscription/change-payment-method"
          >
            <Button>
              {{ $t('changePaymentMethod') }}
            </Button>
          </router-link>
        </div> -->
        <div class="pb-3">
          <Button theme="alert" @click="cancelSubscriptionOverlay = true">
            {{ $t('cancel') }}
          </Button>
        </div>
      </div>
    </div>
    <Overlay v-model="cancelSubscriptionOverlay">
      <div class="text-center">
        <div>
          {{ $t('confirmSubscriptionCancel') }}
        </div>

        <div class="mt-6">
          <Button theme="light" @click="cancelSubscriptionOverlay = false">
            {{ $t('abort') }}
          </Button>

          <Button theme="alert" @click="confirmSubscriptionCancel">
            {{ $t('cancel') }}
          </Button>
        </div>
      </div>
    </Overlay>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SeperationBox from '../SeperationBox/index.vue';

export default {
  components: {
    SeperationBox,
  },

  data() {
    return {
      cancelSubscriptionOverlay: false,
    };
  },

  computed: {
    ...mapGetters(['activeSubscription']),
  },

  methods: {
    ...mapActions(['cancelActiveSubscription']),
    async confirmSubscriptionCancel() {
      await this.cancelActiveSubscription();
      this.cancelSubscriptionOverlay = false;
    },
  },
};
</script>
