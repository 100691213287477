var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative w-full h-full",style:(("padding-bottom: " + _vm.paddingBottom))},_vm._l((_vm.images),function(image,i){return _c('div',{key:("background-images-" + i),staticClass:"absolute w-full h-full bg-cover bg-center transition-opacity duration-1000",class:{ 
      'transition-opacity duration-1000': _vm.activeImage === i,
      'opacity-0': _vm.activeImage + 1 === _vm.images.length ? i === 0 : _vm.activeImage + 1 === i,
      'z-20': _vm.activeImage + 1 === _vm.images.length ? i === 0 : _vm.activeImage + 1 === i,
      'opacity-0': _vm.images.length > 1 && _vm.activeImage + 1 === _vm.images.length ?
        i === 0 : _vm.activeImage + 1 === i,
      'z-20': _vm.images.length > 1 && _vm.activeImage + 1 === _vm.images.length ?
        i === 0 : _vm.activeImage + 1 === i,
      'z-10': _vm.activeImage === i
    },style:(("background-image: url(" + image + ")"))})}),0)}
var staticRenderFns = []

export { render, staticRenderFns }