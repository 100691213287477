import { productsApi } from '@/api';
import type { Module } from 'vuex';
import type { TRootStore } from '@/store';
import type { IProduct } from '@/types/Products';
import type { IFindParams } from '@/types/Api';
import { SET_MODEL_PRODUCTS, RESET_MODEL_PRODUCTS } from './storeTypes/mutationTypes';

export interface IModelProductsStoreState {
  products: IProduct[];
  loading: boolean;
  page: number;
  total: number;
  endOfList: boolean;
  limit: number;
}

type TModelProductsStore = Module<IModelProductsStoreState, TRootStore>;

const LIMIT = {
  xs: 6,
  sm: 6,
  md: 11,
  lg: 16,
  xl: 16,
  '2xl': 16,
};

const ModelProductsStore: TModelProductsStore = {
  state: () => ({
    loading: false,
    products: [],
    page: 0,
    total: 0,
    endOfList: false,
    selectedProduct: null,
    limit: 6,
  }),

  getters: {
    allModelProducts: (state) => state.products,
    allModelProductsLoaded: (state) => state.endOfList,
    modelProductsLoading: (state) => state.loading,
    modelProductsTotal: (state) => state.total,
  },

  mutations: {
    [SET_MODEL_PRODUCTS](state, { data }: { data: IProduct[] }) {
      state.products = [...state.products, ...data];
      if (state.total > state.page * state.limit) {
        state.endOfList = false;
      } else {
        state.endOfList = true;
      }
    },
    [RESET_MODEL_PRODUCTS](state) {
      state.page = 0;
      state.products = [];
    },
  },

  actions: {
    async findModelProducts({ commit, state, getters }, params: IFindParams) {
      state.loading = true;
      const { refresh, query, ...restParams } = params || {};
      if (refresh) {
        commit(RESET_MODEL_PRODUCTS);
      }
      // @ts-ignore
      const limit: number = params?.query?.$limit || LIMIT[getters.currentBreakpoint];
      if (state.total > limit * state.page || refresh) {
        const { data, total } = await productsApi.findProducts({
          query: {
            $limit: limit,
            $skip: !params?.refresh ? limit * state.page : 0,
            ...(query || {}),
          },
          ...(restParams || {}),
        });
        state.limit = limit;
        state.total = total;
        state.page += 1;
        commit(SET_MODEL_PRODUCTS, { data, refresh: params?.refresh || false });
      }
      state.loading = false;
    },
  },
};

export default ModelProductsStore;
