<template>
  <section>
    <SeperationBox>
      <template>
        {{ $t('subscription') }}
      </template>
    </SeperationBox>
    <Stepper v-model="currentStepperStep" :stepper-steps="stepperSteps">
      <div 
        :class="{ 'hidden': currentStepperStep !== 0 }" 
        class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1 lg:gap-3"
      >
        <div 
          v-for="(subscriptionPlan, i) in allSubscriptionPlans" 
          :key="`credit-package${i}`"
          class="h-full bg-interface"
        >
          <div class="bg-interface p-6 text-center">
            <div class="text-sm opacity-75 my-3">
              {{ $tc('daysWithCount', subscriptionPlan.term * 30) }}
            </div>
            <div class="uppercase font-extrabold tracking-widest text-lg">
              {{ subscriptionPlan.name }}
            </div>
            <div class="text-sm opacity-75 my-3">
              <span v-if="!subscriptionPlan.oneTime">
                {{ subscriptionPlan.monthlyPrice | formatMoney }} / {{ $tc('daysWithCount', 30) }}
              </span>
              <span v-else>
                {{ subscriptionPlan.monthlyPrice * subscriptionPlan.term | formatMoney }}
              </span>
            </div>
            <Button class="my-3" @click="selectSubscriptionPlan(i)"> 
              {{ $t('nextStep') }}
            </Button>
          </div>
          <div class="w-full h-1 lg:h-3 bg-background" />
          <div class="bg-interface p-6 leading-5">
            <div class="pb-3">
              <div 
                class="text-inactive text-xxs uppercase tracking-semi-wide font-semibold"
              >
                {{ $t('description') }}
              </div>
              <div class="text-xs">
                {{ $t('subscriptionPlanDesc') }}
              </div>
            </div>
            <div class="pb-3">
              <div 
                class="text-inactive text-xxs uppercase tracking-semi-wide font-semibold"
              >
                {{ $t('debit') }}
              </div>
              <div class="text-xs">
                {{ subscriptionPlan.oneTime ? $t('oneTime') : $t('every30days') }}
              </div>
            </div>
            <div class="pb-3">
              <div 
                class="text-inactive text-xxs uppercase tracking-semi-wide font-semibold"
              >
                {{ $t('cancellation') }}
              </div>
              <div class="text-xs">
                <div v-if="subscriptionPlan.minimumTerm !== subscriptionPlan.term">
                  <span v-if="subscriptionPlan.minimumTerm > 1">
                    <!-- eslint-disable-next-line max-len -->
                    {{ $tc('daysWithCount', subscriptionPlan.minimumTerm * 30) }} {{ $t('subscriptionCancelEndOf30DaysPeriod') }}
                  </span>
                  <span v-else>
                    {{ $t('cancelToEndOfEvery30DaysPeriod') }}
                  </span>
                </div>
                <div v-else>{{ $t('notRequiredEndsAutomatically') }}</div>
              </div>
            </div>
            <div class="pb-3">
              <div 
                class="text-inactive text-xxs uppercase tracking-semi-wide font-semibold"
              >
                {{ $t('renewal') }}
              </div>
              <div class="text-xs">
                {{ subscriptionPlan.recurring ? $t('automatic') : $t('automaticallyEnds') }}
              </div>
            </div>
            <div class="pb-3">
              <div 
                class="text-inactive text-xxs uppercase tracking-semi-wide font-semibold"
              >
                {{ $t('discountAndBonus') }}
              </div>
              <div class="text-xs">
                <div v-if="subscriptionPlan.productDiscount">
                  {{ `${subscriptionPlan.productDiscount}% 
                    ${$t('discountOnPurchases')}` }}
                </div>
                <div v-if="subscriptionPlan.bonus">
                  {{ 
                    $t('creditBonusDaily', { 
                      bonus: subscriptionPlan.bonus, 
                      days: subscriptionPlan.term * 30 
                    }) 
                  }}
                </div>
                <div v-if="!subscriptionPlan.productDiscount && !subscriptionPlan.bonus">
                  {{ $t('none') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div 
        :class="{ 'hidden': currentStepperStep !== 1 }" 
        class="grid md:grid-cols-2 gap-3"
      >
        <div class="md:col-span-2 text-sm p-3 lg:py-0">{{ $t('paymentNote') }}</div>
        <div 
          class="bg-interface p-6 text-center"
        >
          <Button @click="initSubscriptionPayment"> 
            {{ $t('completeSubscription') }}
          </Button>
          <div class="hidden">
            <form
              ref="maxpay-form"
              action="https://hpp.maxpay.com/hpp"
              class="redirect_form"
              method="post"
            >
              <div v-for="(value, name) in paymentForm" :key="name">
                <input type="hidden" :name="name" :value="value">
              </div>
              <input type="hidden" name="signature" :value="paymentFormSignature">
            </form>
          </div>
        </div>
      </div>
      <div 
        :class="{ 'hidden': currentStepperStep !== 2 }"  
        class="grid md:grid-cols-2 lg:grid-cols-4 gap-3"
      >
        <div 
          class="text-center p-6 bg-interface"
        >
          <div v-if="lastSubscription && lastSubscription.error">{{ $t('failedSubscriptionConfirmation') }}</div>
          <div v-else>{{ $t('successfulSubscriptionCompletion') }}</div>
        </div>
      </div>
    </Stepper>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { maxpaySubscriptionsApi } from '@/api';
import { SET_ACTIVE_SUBSCRIPTION, SET_CURRENT_USER } from '@/store/storeTypes/mutationTypes';
import SeperationBox from '../SeperationBox/index.vue';

export default {
  components: {
    SeperationBox,
  },

  data() {
    return {
      currentStepperStep: 0,
      stepperSteps: [
        {
          title: 'choosePlan',
          clickable: true,
        },
        {
          title: 'payment',
        },
        {
          title: 'done',
          spanCol: true,
        },
      ],
      selectedSubscriptionPlan: null,
      paymentForm: {},
      paymentFormSignature: '',
      lastSubscription: null,
    };
  },

  computed: {
    ...mapGetters(['allSubscriptionPlans', 'currentUser', 'activeSubscription']),
  },

  watch: {
    $route: {
      immediate: true,
      async handler() {
        if (this.$route.params.slug === 'redirect') {
          if (!this.activeSubscription) {
            const subscriptions = await maxpaySubscriptionsApi.findSubscriptions({
              query: {
                $sort: {
                  createdAt: -1,
                },
                $limit: 1,
              },
            });
            [this.lastSubscription] = subscriptions.data;
          }

          if (this.activeSubscription || this.lastSubscription) {
            this.currentStepperStep = 2;
          }
        }
      },
      deep: true,
    },
  },

  created() {
    this.findSubscriptionPlans({
      query: {
        archived: 0,
        $limit: 150,
        $sort: {
          monthlyPrice: -1,
        },
      },
      refresh: true,
    });
  },

  methods: {
    ...mapActions(['findSubscriptionPlans']),
    ...mapMutations([SET_CURRENT_USER, SET_ACTIVE_SUBSCRIPTION]),
    selectSubscriptionPlan(index) {
      this.selectedSubscriptionPlan = this.allSubscriptionPlans[index];
      this.currentStepperStep = 1;
    },
    async initSubscriptionPayment() {
      const resp = await maxpaySubscriptionsApi.createSubscription({
        subscriptionPlanId: this.selectedSubscriptionPlan.id,
      });

      this.paymentForm = resp.paymentForm;
      this.paymentFormSignature = resp.paymentFormSignature;

      this.$nextTick(() => {
        this.$refs['maxpay-form'].submit();
      });
    },
  },
};
</script>

<style >
.heidelpayUI.form .field > label {
  color: #dfdfe1;
}
</style>
