import type { Module } from 'vuex';
import type { TRootStore } from '@/store';
import {
  SET_USERS_TABLE_OPTIONS,
  SET_USERS_FILTER_OPTIONS,
  SET_AGE_VERIFICATIONS_TABLE_OPTIONS,
  SET_MODELS_TABLE_OPTIONS,
  SET_CATEGORIES_DATA_TABLE_OPTIONS,
  SET_PRODUCTS_DATA_TABLE_OPTIONS,
  SET_BUNDLES_DATA_TABLE_OPTIONS,
  SET_SUBSCRIPTIONS_PLANS_DATA_TABLE_OPTIONS,
  SET_PRODUCTS_FILTER_OPTIONS,
} from './storeTypes/mutationTypes';

export interface IAdminUiStoreState {
  usersTableOptions?: any;
  usersFilterOptions?: any;
  ageVerificationsTableOptions?: any;
  modelsTableOptions?: any;
  categoriesTableOptions?: any;
  productsTableOptions?: any;
  productsFilterOptions?: any;
  bundlesTableOptions?: any;
  subscriptionPlansTableOptions?: any;
}

type TAdminUiStore = Module<IAdminUiStoreState, TRootStore>;

const adminUiStore: TAdminUiStore = {
  state: () => ({
    usersTableOptions: {
      sortBy: ['createdAt'],
      sortDesc: [false],
      search: '',
    },
    usersFilterOptions: {
      search: '',
    },
    ageVerificationsTableOptions: {
      sortBy: ['createdAt'],
      sortDesc: [false],
    },
    modelsTableOptions: {
      sortBy: ['createdAt'],
      sortDesc: [false],
    },
    categoriesTableOptions: {
      sortBy: ['createdAt'],
      sortDesc: [false],
    },
    productsTableOptions: {
      sortBy: ['createdAt'],
      sortDesc: [false],
    },
    productsFilterOptions: {
      search: '',
      selectedProductType: null,
      selectedCategories: [],
    },
    bundlesTableOptions: {
      sortBy: ['createdAt'],
      sortDesc: [false],
    },
    subscriptionPlansTableOptions: {
      sortBy: ['createdAt'],
      sortDesc: [false],
    },
  }),

  getters: {
    currentUsersTableOptions: (state) => state.usersTableOptions,
    currentUsersFilterOptions: (state) => state.usersFilterOptions,
    currentAgeVerificationsTableOptions: (state) => state.ageVerificationsTableOptions,
    currentModlesTableOptions: (state) => state.modelsTableOptions,
    currentCategoriesTableOptions: (state) => state.categoriesTableOptions,
    currentProductsTableOptions: (state) => state.productsTableOptions,
    currentProductsFilterOptions: (state) => state.productsFilterOptions,
    currentBundleTableOptions: (state) => state.bundlesTableOptions,
    currentSubscriptionPlanTableOptions: (state) => state.subscriptionPlansTableOptions,
  },

  mutations: {
    [SET_USERS_TABLE_OPTIONS](state, options: any) {
      localStorage.setItem('admin-panel-users-table-options', JSON.stringify(options));
      state.usersTableOptions = options;
    },
    [SET_USERS_FILTER_OPTIONS](state, options: any) {
      localStorage.setItem('admin-panel-users-filter-options', JSON.stringify(options));
      state.usersFilterOptions = options;
    },
    [SET_AGE_VERIFICATIONS_TABLE_OPTIONS](state, options: any) {
      localStorage.setItem('admin-panel-age-verification-table-options', JSON.stringify(options));
      state.ageVerificationsTableOptions = options;
    },
    [SET_MODELS_TABLE_OPTIONS](state, options: any) {
      localStorage.setItem('admin-panel-models-table-options', JSON.stringify(options));
      state.modelsTableOptions = options;
    },
    [SET_CATEGORIES_DATA_TABLE_OPTIONS](state, options: any) {
      localStorage.setItem('admin-panel-categories-table-options', JSON.stringify(options));
      state.categoriesTableOptions = options;
    },
    [SET_PRODUCTS_DATA_TABLE_OPTIONS](state, options: any) {
      localStorage.setItem('admin-panel-products-table-options', JSON.stringify(options));
      state.productsTableOptions = options;
    },
    [SET_PRODUCTS_FILTER_OPTIONS](state, options: any) {
      localStorage.setItem('admin-panel-products-filter-options', JSON.stringify(options));
      state.productsFilterOptions = options;
    },
    [SET_BUNDLES_DATA_TABLE_OPTIONS](state, options: any) {
      localStorage.setItem('admin-panel-bundles-table-options', JSON.stringify(options));
      state.bundlesTableOptions = options;
    },
    [SET_SUBSCRIPTIONS_PLANS_DATA_TABLE_OPTIONS](state, options: any) {
      localStorage.setItem('admin-panel-subscription-plans-table-options', JSON.stringify(options));
      state.subscriptionPlansTableOptions = options;
    },
  },

  actions: {
    initAdminUserInterface({ commit }) {
      const savedUsersTableSettings = localStorage.getItem('admin-panel-users-table-options');
      if (savedUsersTableSettings) {
        commit(SET_USERS_TABLE_OPTIONS, JSON.parse(savedUsersTableSettings));
      }

      const savedUsersFilterSettings = localStorage.getItem('admin-panel-users-filter-options');
      if (savedUsersFilterSettings) {
        commit(SET_USERS_FILTER_OPTIONS, JSON.parse(savedUsersFilterSettings));
      }

      const savedAgeVerificationsTableSettings = localStorage.getItem(
        'admin-panel-age-verification-table-options',
      );
      if (savedAgeVerificationsTableSettings) {
        commit(SET_AGE_VERIFICATIONS_TABLE_OPTIONS, JSON.parse(savedAgeVerificationsTableSettings));
      }

      const savedModelsTableSettings = localStorage.getItem('admin-panel-models-table-options');
      if (savedModelsTableSettings) {
        commit(SET_MODELS_TABLE_OPTIONS, JSON.parse(savedModelsTableSettings));
      }

      const savedCategoriesTableSettings = localStorage.getItem(
        'admin-panel-categories-table-options',
      );
      if (savedCategoriesTableSettings) {
        commit(SET_CATEGORIES_DATA_TABLE_OPTIONS, JSON.parse(savedCategoriesTableSettings));
      }

      const savedProductsTableSettings = localStorage.getItem('admin-panel-products-table-options');
      if (savedProductsTableSettings) {
        commit(SET_PRODUCTS_DATA_TABLE_OPTIONS, JSON.parse(savedProductsTableSettings));
      }

      const savedProductsFilterSettings = localStorage.getItem(
        'admin-panel-products-filter-options',
      );
      if (savedProductsFilterSettings) {
        commit(SET_PRODUCTS_FILTER_OPTIONS, JSON.parse(savedProductsFilterSettings));
      }

      const savedBundlesTableSettings = localStorage.getItem('admin-panel-bundles-table-options');
      if (savedBundlesTableSettings) {
        commit(SET_BUNDLES_DATA_TABLE_OPTIONS, JSON.parse(savedBundlesTableSettings));
      }

      const savedSubscriptionPlansTableSettings = localStorage.getItem(
        'admin-panel-subscription-plans-table-options',
      );
      if (savedSubscriptionPlansTableSettings) {
        commit(
          SET_SUBSCRIPTIONS_PLANS_DATA_TABLE_OPTIONS,
          JSON.parse(savedSubscriptionPlansTableSettings),
        );
      }
    },
  },
};

export default adminUiStore;
