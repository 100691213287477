/* eslint-disable */
import reusableuiVue2 from 'reusableui/src/vue2';
import type { IPluginInstallOptions } from 'reusableui/src/types/plugin';

const config: IPluginInstallOptions = {
  config: {
    propClasses: {
      active: false,
      booleanPropsOnly: true,
    },
    attrClasses: {
      active: false,
      booleanAttrsOnly: true,
    },
    stateClasses: false,
  },
  components: {
    Field: {
      variants: ['default', 'floating-label'],
      class: ({ variant }) => 'relative mb-4',
      innerClasses: {
        fieldWrapper: 'relative',
      },
    },
    FieldLabel: {
      variants: ['default', 'floating-label'],
      class: ({ variant, props }) => ([
        'text-sm font-medium transition duration-150 ease-in-out select-none cursor-text',
        {
          'text-akzent': props.fieldFocused && variant === 'floating-label' && !props.fieldDisabled && !props.fieldInvalid,
          'text-text': (!props.fieldFocused || variant !== 'floating-label') && !props.fieldDisabled && !props.fieldInvalid,
          'text-gray-500': props.fieldDisabled,
          'text-red-400': props.fieldInvalid,
        },
      ]),
      innerClasses: {
        wrapper: ({ variant, props }) => (['flex justify-between items-center', {
          'mb-1 px-1': variant !== 'floating-label',
          'ml-3': variant === 'floating-label',
          'as-floating-label': variant === 'floating-label',
          'is-focused text-xs': variant === 'floating-label' && (props.fieldWithValue || props.fieldFocused),
        }]),
        requiredStar: 'text-gray-500',
        right: ({ variant }) => (['text-xs text-gray-700 text-right flex-shrink truncate', {
          'h-0 hidden': variant === 'floating-label',
        }]),
      },
    },
    FieldMessage: {
      variants: ['default'],
      class: ({ props }) => (['text-xs mt-1 px-1', {
        'text-inactive': !props.fieldInvalid,
        'text-red-500': props.fieldInvalid,
      }]),
    },
    Input: {
      variants: ['default'],
      class: ({ state, props }) => ([
        'px-3 transition duration-150 ease-in-out border-b-2 focus:outline-none block w-full sm:text-sm border-transparent rounded-none',
        {
          'bg-interface': !props.disabled,
          'bg-gray-100 border-gray-200 text-gray-400 cursor-not-allowed': props.disabled,
          'py-1': state.fieldVariant !== 'floating-label',
          'pt-5 pb-2': state.fieldVariant === 'floating-label',
          'border-inactive focus:border-akzent': !state.invalid,
          'text-red-500 border-red-500': state.invalid,
        },
      ]),
    },
  },
};

const ReuseableUi = reusableuiVue2(config);

export default ReuseableUi;
/* eslint-enable */
