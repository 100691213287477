<template>
  <v-dialog
    v-model="show"
    width="600"
    scrollable
  >
    <v-card :loading="loading">
      <v-card-title>Model erstellen</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="model.name"
            :counter="10"
            :rules="[v => !!v || 'Name ist erforderlich']"
            label="Name"
            required
          />

          <v-text-field
            v-model="model.preferredWeapon"
            :counter="100"
            label="Bevorzugte Waffe"
          />

          <v-text-field
            v-model="model.activityStatus"
            :counter="100"
            label="Aktivität"
          />

          <v-select
            v-model="model.toplessActing"
            :items="selectItems"
            label="Topless Acting"
          />

          <v-select
            v-model="model.availableForCustoms"
            :items="selectItems"
            label="Verfübar für Customs"
          />

          <v-checkbox v-model="model.active" label="Model aktiv" />

          

          <v-file-input 
            label="Foto" 
            accept="image/png, image/jpeg, image/gif" 
            :rules="fileRules" 
            @change="fileInput"
          />
          <v-img 
            v-if="selectedFileURL" 
            :src="selectedFileURL" 
            width="200"
            height="303"
            class="mx-auto"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          color="primary"
          :disabled="!valid"
          @click="validate"
        >
          Erstellen
        </v-btn>
      </v-card-actions>
      <template slot="progress">
        <v-progress-linear
          absolute
          indeterminate 
          bottom
        />
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
    },
  },

  data() {
    return {
      loading: false,
      model: {
        name: '',
        preferredWeapon: '',
        activityStatus: '',
        toplessActing: 0,
        availableForCustoms: 0,
        active: true,
        pictureMimeType: '',
      },
      valid: true,
      selectItems: [
        { text: 'Nein', value: 0 },
        { text: 'Auf Anfrage', value: 1 },
        { text: 'Ja', value: 2 },
      ],
      fileRules: [
        (v) => !v || v.size < 5000000 || 'Datei muss kleiner als 5 MB sein',
        (v) => !!v || 'Datei ist erforderlich',
      ],
      selectedFileURL: '',
      selectedFile: {},
      selectFileType: '',
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value: {
      handler() {
        if (!this.value) {
          this.$refs.form.reset();
        }
      },
    },
  },

  methods: {
    ...mapActions(['createModel', 'patchModel', 'getModel']),

    async validate() {
      try {
        const valid = this.$refs.form.validate();
        if (valid) {
          this.loading = true;
          const model = await this.createModel({ data: this.model });

          const formData = new FormData();
          Object.keys(model.uploadFrom.fields).forEach((key) => {
            formData.append(key, model.uploadFrom.fields[key]);
          });

          formData.append('file', this.selectedFile);

          await this.$axios.post(model.uploadFrom.url, formData);
          await this.getModel({ id: model.id });

          this.loading = false;
          this.$emit('created');
          this.$emit('input', false);
          this.$refs.form.reset();
        }
      } catch (err) {
        console.log(err);
      }
    },
    fileInput(file) {
      if (file === null) {
        this.selectedFileURL = '';
        this.selectedFile = {};
        this.model.pictureMimeType = '';
      } else {
        this.selectedFileURL = '';
        this.$nextTick(() => {
          this.selectedFileURL = URL.createObjectURL(file);
          this.model.pictureMimeType = file.type;
          this.selectedFile = file;
        });
      }
    },
  },
};
</script>
