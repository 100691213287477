import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { SET_NEXT_ROUTE } from '@/store/storeTypes/mutationTypes';
import store from '../store';
import adminRoutes from './admin';
import appRoutes from './app';

interface CustomRoute extends Route {
  meta: Record<string, any>;
}

const originalPush = VueRouter.prototype.push;
// @ts-ignore
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  // @ts-ignore
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err;
    }
    return Promise.reject(err);
  });
};

Vue.use(VueRouter);

const canUserAccess = async (to: CustomRoute) => {
  const setNextRoute = (nextRoute: string) => store.commit(SET_NEXT_ROUTE, nextRoute);
  const initAuth = async () => store.dispatch('initAuth');
  const { authIsReady } = store.getters;

  const run = () => {
    const { accessToken, currentUser } = store.getters;
    if (!to.meta.public && !accessToken) {
      setNextRoute(to.fullPath);
      return false;
    }

    if (to.meta.public && !to.meta.accessibleWithAuth && accessToken) {
      return false;
    }

    if (
      to.meta.allowedUserRoles &&
      !to.meta.allowedUserRoles.some(
        (allowedUserRole: string) => allowedUserRole === currentUser.role,
      )
    ) {
      return false;
    }

    return true;
  };

  if (!authIsReady) {
    await initAuth();
    return run();
  }
  return run();
};

const defaultHomeRoute = process.env.VUE_APP_MODE === 'admin' ? '/users' : '/products';

const routes =
  process.env.VUE_APP_MODE === 'admin'
    ? adminRoutes(defaultHomeRoute)
    : appRoutes(defaultHomeRoute);

const router = new VueRouter({
  mode: process.env.NODE_ENV === 'production' ? 'history' : 'hash',
  base: process.env.BASE_URL || '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    const position = {
      x: 0,
      y: 0,
    };

    if (savedPosition) {
      position.x = savedPosition.x;
      position.y = savedPosition.y;
    }
    return new Promise((resolve) => {
      router.app.$root.$once('scrollBeforeEnter', () => {
        resolve(position);
      });
    });
  },
});

router.beforeEach(async (to, from, next) => {
  // canUserAccess() returns `true` or `false`
  const canAccess = await canUserAccess(to as CustomRoute);
  if (!canAccess) {
    const { currentUser } = store.getters;
    let dynamicHomeRoute = defaultHomeRoute;
    if (currentUser) {
      if (currentUser.role === 'registeredUser') {
        dynamicHomeRoute = '/confirm-email';
      } else if (currentUser.role === 'verifiedUser') {
        dynamicHomeRoute = '/verify-age';
      }
      next(dynamicHomeRoute);
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

export default router;
