











import { defineComponent } from '@vue/composition-api';
import { SideNavigation } from '@/ui/components/Admin/index';

export default defineComponent({
  components: {
    SideNavigation,
  },
  methods: {
    onLogout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login');
      });
    },
  },
});
