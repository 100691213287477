var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Konto-Übersicht "),_c('v-spacer'),_vm._v(" Credits insgesamt: "+_vm._s(_vm.totalUserCredits)+" ")],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.allUserCredits,"loading":_vm.userCreditsLoading,"options":_vm.options,"server-items-length":_vm.userCreditsTotal,"footer-props":{
          'items-per-page-options': [5, 10, 15, 30, 50],
          'items-per-page-text': 'Zeilen pro Seite',
        },"multi-sort":true},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.amount))])]}},{key:"item.origin",fn:function(ref){
        var item = ref.item;
return [(!(item.product && item.product.title))?_c('span',[_vm._v(_vm._s(item.origin))]):_c('span',[_vm._v("Einkauf: "+_vm._s(JSON.stringify(item.product.title)))])]}},{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.createdAt)))])]}},{key:"item.updatedAt",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.updatedAt)))])]}},{key:"footer.page-text",fn:function(ref){
        var pageStart = ref.pageStart;
        var pageStop = ref.pageStop;
        var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" von "+_vm._s(itemsLength)+" ")]}}],null,true)}),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" +/- Credits")])],1),_c('v-dialog',{attrs:{"width":"300"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Credits")]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.fromIsValid),callback:function ($$v) {_vm.fromIsValid=$$v},expression:"fromIsValid"}},[_c('v-text-field',{attrs:{"label":"Credits","type":"number","rules":[
                  function (v) { return v !== '' || 'Credits ist erforderlich'; },
                  function (v) { return v !== 0 || 'Credits dürfen nicht 0 sein'; }
                ],"required":""},model:{value:(_vm.newUserCredit.amount),callback:function ($$v) {_vm.$set(_vm.newUserCredit, "amount", _vm._n($$v))},expression:"newUserCredit.amount"}}),_c('v-text-field',{attrs:{"counter":50,"rules":[function (v) { return !!v || 'Aktion ist erforderlich'; }],"label":"Aktion","required":""},model:{value:(_vm.newUserCredit.origin),callback:function ($$v) {_vm.$set(_vm.newUserCredit, "origin", $$v)},expression:"newUserCredit.origin"}})],1)],1),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.save}},[_vm._v(" Speichern ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }