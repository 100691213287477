<template>
  <div class="inline-flex">
    <Icon
      :name="icon"
      :class="[iconClass, {
        'text-akzent': typeToString === 'available',
        'text-red-700': typeToString === 'trailer-only',
        'text-yellow-600': typeToString === 'stream-only',
      }]"
    />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: Number,
      required: true,
      validator: (value) => [0, 1, 2].includes(value),
    },
    iconClass: {
      type: String,
      default: 'w-5 h-5',
    },
  },

  computed: {
    icon() {
      if (this.typeToString === 'stream-only') return 'film';
      if (this.typeToString === 'available') return 'badge-check';
      return 'video-camera';
    },
    typeToString() {
      if (this.type === 1) return 'stream-only';
      if (this.type === 2) return 'available';
      return 'trailer-only';
    },
  },
};
</script>
